import classNames from 'classnames';
import css from 'client/css/Button.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onClick: () => void;
  className?: string;
  size?: 'medium' | 'large';
  disabled?: boolean;
  title?: string;
};

export default function Button({
  children,
  className,
  onClick,
  size = 'medium',
  disabled = false,
  title,
}: Props) {
  useStyles(css);
  return (
    <button
      title={title}
      className={classNames('Button', className, {
        'Button--medium': size === 'medium',
        'Button--large': size === 'large',
        'Button--disabled': disabled,
      })}
      onMouseDown={disabled ? () => {} : onClick}
    >
      <div className="Button-content">{children}</div>
    </button>
  );
}
