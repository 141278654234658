import {
  DynamicContextProvider,
  useDynamicContext,
} from '@dynamic-labs/sdk-react';
import { DAppProvider, Mainnet, useEthers } from '@usedapp/core';
import Article from 'client/components/Article';
import Atelier from 'client/components/Atelier';
import AtelierClaimNft from 'client/components/AtelierClaimNft';
import AtelierNft from 'client/components/AtelierNft';
import Directory from 'client/components/Directory';
import Enterprise from 'client/components/Enterprise';
import Essays from 'client/components/Essays';
import Feed from 'client/components/Feed';
import Landing from 'client/components/Landing';
import Privacy from 'client/components/Privacy';
import Profile from 'client/components/Profile';
import Support from 'client/components/Support';
import Terms from 'client/components/Terms';
import css from 'client/css/global.scss';
import { getAuthCookie } from 'client/helpers/getAuthCookie';
import useStyles from 'isomorphic-style-loader/useStyles';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppContext } from 'shared/AppContext';

TimeAgo.addDefaultLocale(en);

type Props = {
  appContext: AppContext;
};

export default function App({ appContext }: Props): JSX.Element {
  useStyles(css);

  const dAppConfig = {
    readOnlyChainId: Mainnet.chainId,
    readOnlyUrls: {
      [Mainnet.chainId]:
        'https://eth-mainnet.alchemyapi.io/v2/_E3yQSqF5Jy4cwWbANU9I6pkkrr7hyBo',
    },
    autoConnect: false,
    pollingInterval: 15000,
  };

  return (
    <DAppProvider config={dAppConfig}>
      <DynamicContextProvider
        settings={{
          environmentId: 'aad39ba2-03f8-4867-8b8c-193acab7270b',
          privacyPolicyUrl: 'https://eth.id/privacy',
          termsOfServiceUrl: 'https://eth.id/terms',
          appLogoUrl: 'https://eth.id/favicon.png',
          appName: 'Eth.id',
          eventsCallbacks: {
            onAuthSuccess: async () => {
              //  console.log('on auth success');
            },
            onLogout: () => {
              //  console.log('on dynamic logout');
            },
          },
        }}
      >
        <AppContents appContext={appContext} />
      </DynamicContextProvider>
    </DAppProvider>
  );
}

function AppContents({ appContext }: Props): JSX.Element {
  const { isProfileSubdomain, page } = appContext;
  const { activate, deactivate, account: ethersAccount } = useEthers();
  const { primaryWallet, authToken, walletConnector } = useDynamicContext();
  const isLoggingIn = useRef(false);
  const isConnectorLoggingIn = useRef(false);

  useEffect(() => {
    const { walletAddress: authCookieWalletAddress } = getAuthCookie();
    const isDynamicAuthMatching =
      authCookieWalletAddress === primaryWallet?.address.toLowerCase();

    if (
      !isLoggingIn.current &&
      primaryWallet &&
      !isDynamicAuthMatching &&
      authToken
    ) {
      isLoggingIn.current = true;

      fetch('/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          authToken,
          walletAddress: primaryWallet.address,
        }),
      }).finally(() => {
        isLoggingIn.current = false;
      });
    }

    if (!ethersAccount && walletConnector) {
      if (!isConnectorLoggingIn.current) {
        isConnectorLoggingIn.current = true;
        const activateFunction = async () => {
          const provider = ((await walletConnector.getWeb3Provider()) as any)
            .provider;
          activate(provider);
          isConnectorLoggingIn.current = false;
        };
        activateFunction();
      }
    }

    if (ethersAccount && !walletConnector) {
      deactivate();
      isConnectorLoggingIn.current = false;
      isLoggingIn.current = false;
    }
  }, [
    primaryWallet,
    authToken,
    walletConnector,
    activate,
    ethersAccount,
    deactivate,
  ]);

  return (
    <div className="App">
      {isProfileSubdomain ? (
        <Routes>
          <Route path="/" element={<Profile appContext={appContext} />} />
          <Route path="/*" element={<Profile appContext={appContext} />} />
          <Route
            path="/directory"
            element={<Directory appContext={appContext} />}
          />
          <Route path="/terms" element={<Terms appContext={appContext} />} />
          <Route
            path="/privacy"
            element={<Privacy appContext={appContext} />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              page === 'enterprise' ? (
                <Enterprise appContext={appContext} />
              ) : page === 'essays' ? (
                <Essays appContext={appContext} />
              ) : page === 'atelier' ? (
                <Atelier appContext={appContext} />
              ) : page === 'support' ? (
                <Support appContext={appContext} />
              ) : (
                <Landing appContext={appContext} />
              )
            }
          />
          <Route
            path="/discord"
            element={<Landing appContext={appContext} />}
          />
          <Route path="/w" element={<Landing appContext={appContext} />} />
          <Route path="/x" element={<Landing appContext={appContext} />} />
          <Route path="/me" element={<Landing appContext={appContext} />} />
          <Route path="/qr" element={<Landing appContext={appContext} />} />
          <Route
            path="/season1"
            element={<Landing appContext={appContext} />}
          />
          <Route
            path="/season0"
            element={<Landing appContext={appContext} />}
          />
          <Route
            path="/atelier"
            element={<Atelier appContext={appContext} />}
          />
          <Route path="/fren" element={<Landing appContext={appContext} />} />
          <Route path="/la" element={<Landing appContext={appContext} />} />
          <Route path="/nyc" element={<Landing appContext={appContext} />} />
          <Route path="/sf" element={<Landing appContext={appContext} />} />
          <Route path="/ens" element={<Landing appContext={appContext} />} />
          <Route path="/mit" element={<Landing appContext={appContext} />} />
          <Route
            path="/university-verify/*"
            element={<Landing appContext={appContext} />}
          />
          <Route
            path="/transfer"
            element={<Landing appContext={appContext} />}
          />
          <Route path="/feed" element={<Feed appContext={appContext} />} />
          <Route path="/whale" element={<Landing appContext={appContext} />} />
          <Route
            path="/enterprise"
            element={<Enterprise appContext={appContext} />}
          />
          <Route
            path="/support"
            element={<Support appContext={appContext} />}
          />
          <Route
            path="/transfer"
            element={<Landing appContext={appContext} />}
          />
          <Route
            path="/profile"
            element={<Profile appContext={appContext} />}
          />
          <Route
            path="/profile/*"
            element={<Profile appContext={appContext} />}
          />
          <Route
            path="/directory"
            element={<Directory appContext={appContext} />}
          />
          <Route path="/terms" element={<Terms appContext={appContext} />} />
          <Route
            path="/privacy"
            element={<Privacy appContext={appContext} />}
          />
          <Route
            path="/article/*"
            element={<Article appContext={appContext} />}
          />
          <Route
            path="/essays/*"
            element={<Essays appContext={appContext} />}
          />
          {page === 'essays' && (
            <Route path="/*" element={<Essays appContext={appContext} />} />
          )}
          <Route
            path="/nft/atelier/*"
            element={<AtelierNft appContext={appContext} />}
          />
          <Route
            path="/nft/claim/*"
            element={<AtelierClaimNft appContext={appContext} />}
          />
        </Routes>
      )}
    </div>
  );
}
