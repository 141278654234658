import { Module } from '@prisma/client';
import ImageEthDenver from 'assets/images/module-asset-eth-denver.png';
import ModuleCard from 'client/components/ModuleCard';

type Props = {
  module: Partial<Module>;
  isOwner: boolean;
  dragHandleProps?: any;
  onModuleDelete?: (moduleId: number) => void;
};

export default function EthDenverCard({
  module,
  isOwner,
  dragHandleProps,
  onModuleDelete,
}: Props): JSX.Element {
  return (
    <ModuleCard
      title="I will be at EthDenver 2023!"
      moduleId={module.id}
      isOwner={isOwner}
      dragHandleProps={dragHandleProps}
      onModuleDelete={onModuleDelete}
      hideEdit={true}
    >
      <img src={ImageEthDenver} />
    </ModuleCard>
  );
}
