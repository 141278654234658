import classNames from 'classnames';
import css from 'client/css/BaseModal.scss';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ReactNode, useEffect } from 'react';

type Props = {
  onClose?: () => void;
  title: string;
  children: ReactNode;
  footer?: ReactNode;
  isBackgroundTransparent?: boolean;
};

export default function BaseModal({
  onClose,
  title,
  children,
  footer,
  isBackgroundTransparent,
}: Props): JSX.Element {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useStyles(css);
  return (
    <div
      className={classNames('BaseModal', {
        'BaseModal--transparent': isBackgroundTransparent,
      })}
      onClick={onClose}
    >
      <LazyMotion features={domAnimation}>
        <m.div
          className="BaseModal-frame"
          onClick={(event) => event.stopPropagation()}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          <div className="BaseModal-title">
            {title}
            <div className="BaseModal-titleClose" onClick={onClose}>
              {'✖️'}
            </div>
          </div>
          <div className="BaseModal-content">{children}</div>
          <div className="BaseModal-footer">{footer}</div>
        </m.div>
      </LazyMotion>
    </div>
  );
}
