import classNames from 'classnames';
import css from 'client/css/CardButton.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onClick: () => void;
  title: string;
};

export default function CardButton({ children, onClick, title }: Props) {
  useStyles(css);
  return (
    <div title={title}>
      <button className={classNames('CardButton')} onMouseDown={onClick}>
        {children}
      </button>
    </div>
  );
}
