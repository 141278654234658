import BaseModal from 'client/components/BaseModal';
import Spinner from 'client/components/Spinner';
import { ReactNode, useState } from 'react';

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: ReactNode;
};

export default function ConfirmModal({
  onClose,
  onConfirm,
  content,
  title,
}: Props): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  return (
    <BaseModal
      title={isLoading ? 'Processing...' : title}
      onClose={onClose}
      footer={
        <>
          <div className="BaseModal-footerButton" onClick={onClose}>
            Cancel
          </div>
          <div className="BaseModal-footerDivider" />
          <div
            className="BaseModal-footerButton"
            onClick={() => {
              onConfirm();
              setLoading(true);
            }}
          >
            Confirm
          </div>
        </>
      }
    >
      {isLoading && <Spinner isCenter={true} />}
      {!isLoading && <div className="BaseModal-headline">{content}</div>}
    </BaseModal>
  );
}
