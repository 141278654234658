import classNames from 'classnames';
import BaseModal from 'client/components/BaseModal';
import Link from 'client/components/Link';
import Spinner from 'client/components/Spinner';
import css from 'client/css/FollowModal.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useState } from 'react';

type Props = {
  onClose?: () => void;
  title: string;
  entityId: number;
  followMode: string;
};

export default function FollowModal({
  onClose,
  title,
  entityId,
  followMode,
}: Props): JSX.Element {
  useStyles(css);
  const [entities, setEntities] = useState<any[]>([]);

  useEffect(() => {
    fetch(
      followMode === 'following' ? '/api/getFollowings' : '/api/getFollowers',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entityId,
        }),
      }
    )
      .then((result) => result.json())
      .then((result) => {
        setEntities(result.entities);
      });
  }, [entityId, followMode]);

  return (
    <BaseModal title={title} onClose={onClose}>
      <div className="FollowModal">
        <div className="BaseModal-list">
          {entities.length === 0 && <Spinner isCenter={true} />}
          {entities
            .filter((entity) => entity && entity.domain[0] && !entity.isHidden)
            .map((entity, i) => {
              // todo: Current assumption is one entity has one domain
              const fullDomainName = entity.domain[0].fullDomainName;
              const tagline = entity.tagline;
              return (
                <Link
                  key={i}
                  external={true}
                  to={`https://${fullDomainName}.eth.id`}
                >
                  <div className="BaseModal-item" title={`${fullDomainName}`}>
                    <div
                      className={classNames(
                        'BaseModal-itemImage',
                        'BaseModal-itemImageNft'
                      )}
                      style={{
                        backgroundImage: `url(${entity.profileImageUrl})`,
                      }}
                    />
                    <div className="BaseModal-itemColumn">
                      <div className="BaseModal-itemTitle">
                        {`${fullDomainName}`}
                      </div>
                      <div className="BaseModal-itemDescription">{tagline}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </BaseModal>
  );
}
