//import { parseEther } from '@ethersproject/units';
import { Entity, User } from '@prisma/client';
//import { useSendTransaction } from '@usedapp/core';
import SampleBackgroundImage from 'assets/images/background.png';
import SampleProfile from 'assets/images/j.png';
//import ImageIconEmail from 'assets/images/profile-action-icon-email.png';
//import ImageIconEth from 'assets/images/profile-action-icon-eth.png';
import ImageIconFollow from 'assets/images/profile-action-icon-follow.png';
import ImageIconGm from 'assets/images/profile-action-icon-gm.png';
import ImageIconUnfollow from 'assets/images/profile-action-icon-unfollow.png';
import classNames from 'classnames';
//import ImageIconTrade from 'assets/images/profile-action-icon-trade.png';
import Button from 'client/components/Button';
import FollowModal from 'client/components/FollowModal';
import ImageSelectModal from 'client/components/ImageSelectModal';
import { domAnimation, LazyMotion, m } from 'framer-motion';
//import Link from 'client/components/Link';
import { useEffect, useState } from 'react';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
  entity:
    | (Partial<Entity> & { user: Partial<User> } & { isFollowing?: boolean })
    | undefined;
  isOwner: boolean;
};

//const LoginButton = lazy(() => import('client/components/LoginButton'));

export default function ProfileHeader({
  appContext,
  entity,
  isOwner,
}: Props): JSX.Element {
  const {
    id: visitEntityId,
    profileImageUrl: profileImageUrlInitial,
    coverImageUrl: coverImageUrlInitial,
    isFollowing: isFollowingInitial = false,
    viewCount = 0,
    followingCount = 0,
    followerCount: followerCountInitial = 0,
    tagline,
  } = entity || {};

  const {
    visitSubDomainName: subDomainName,
    visitDomainName: domainName,
    entityId,
  } = appContext;
  const myEntityId = entityId;

  const [imageSelectMode, setImageSelectMode] = useState<
    'profile' | 'cover' | undefined
  >('profile');
  const [isImageSelectModalVisible, setImageSelectModalVisible] =
    useState(false);

  const [followMode, setFollowMode] = useState('following');
  const [isFollowModalVisible, setFollowModalVisible] = useState(false);
  const [isFollowing, setFollowing] = useState(isFollowingInitial);
  useEffect(() => {
    setFollowing(isFollowingInitial);
  }, [isFollowingInitial]);

  const [followerCount, setFollowerCount] = useState(followerCountInitial);
  useEffect(() => {
    setFollowerCount(followerCountInitial);
  }, [followerCountInitial]);

  const [profileImageUrl, setProfileImageUrl] = useState(
    profileImageUrlInitial
  );
  useEffect(() => {
    setProfileImageUrl(profileImageUrlInitial);
  }, [profileImageUrlInitial]);

  const [coverImageUrl, setCoverImageUrl] = useState(coverImageUrlInitial);
  useEffect(() => {
    setCoverImageUrl(coverImageUrlInitial);
  }, [coverImageUrlInitial]);

  const handleImageSelectModalClose = () => {
    setImageSelectModalVisible(false);
  };

  const handleFollowModalClose = () => {
    setFollowModalVisible(false);
  };

  const [isGm, setGm] = useState(false);

  //todo: wrap send eth in login so that it is activated
  // const { sendTransaction } = useSendTransaction({
  //   transactionName: 'Send Ethereum',
  // });

  const handleFollow = () => {
    fetch('/api/followEntity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entityId: myEntityId,
        followingEntityId: visitEntityId,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.isSuccess) {
          setFollowing(true);
          setFollowerCount(followerCount + 1);
        }
      });
  };

  const handleUnfollow = () => {
    fetch('/api/unfollowEntity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entityId: myEntityId,
        followingEntityId: visitEntityId,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.isSuccess) {
          setFollowing(false);
          setFollowerCount(followerCount - 1);
        }
      });
  };

  const handleNftSelect = (nft: any) => {
    if (imageSelectMode === 'profile' || imageSelectMode === 'cover') {
      const imageUrl = nft?.media[0].gateway;
      handleImageSelect(imageUrl, nft);
    }
  };

  const handleImageSelect = (imageUrl: string, nft: any = undefined) => {
    fetch('/api/setImage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        imageUrl,
        nft,
        field: imageSelectMode,
        entityId: visitEntityId,
      }),
    })
      .then((result) => result.json())
      .then(() => {
        if (imageSelectMode === 'cover') {
          setCoverImageUrl(imageUrl);
        } else {
          setProfileImageUrl(imageUrl);
        }
        handleImageSelectModalClose();
      });
  };

  // const sendButtonContents = (
  //   <div className="ProfileHeader-sendButton">
  //     <div
  //       className="ProfileHeader-actionIcon"
  //       style={{ backgroundImage: `url(${ImageIconEth})` }}
  //     ></div>
  //     <div className="ProfileHeader-actionCaption">Send Eth</div>
  //   </div>
  // );

  // const handleSendEth = () => {
  //   sendTransaction({
  //     to: `${fullDomainName}.eth.id`,
  //     value: parseEther(String(0.001)),
  //   });
  // };

  const handleSayGm = () => {
    setGm(true);
    fetch('/api/sayGm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entityId: myEntityId,
        targetEntityId: visitEntityId,
      }),
    }).then((result) => result.json());
  };

  return (
    <div className="ProfileHeader">
      <div
        className="ProfileHeader-background"
        style={{
          backgroundImage: `url(${coverImageUrl || SampleBackgroundImage})`,
        }}
      >
        {isOwner && (
          <Button
            size="medium"
            onClick={() => {
              setImageSelectMode('cover');
              setImageSelectModalVisible(true);
            }}
            className="ProfileHeader-editButton ProfileHeader-coverEditButton"
          >
            Edit
          </Button>
        )}
      </div>
      <div className="ProfileHeader-content">
        <div className="ProfileHeader-avatarWrapper">
          <div className="ProfileHeader-avatarContainer">
            <div
              className="ProfileHeader-avatar"
              style={{
                backgroundImage: `url(${profileImageUrl || SampleProfile})`,
              }}
            />
          </div>
          {isOwner && (
            <div className="ProfileHeader-profileEditButtonContainer">
              <Button
                size="medium"
                onClick={() => {
                  setImageSelectMode('profile');
                  setImageSelectModalVisible(true);
                }}
                className="ProfileHeader-editButton ProfileHeader-profileEditButton"
              >
                Edit
              </Button>
            </div>
          )}
        </div>
        <div className="ProfileHeader-leftContent">
          <div className="ProfileHeader-name">
            <span className="ProfileHeader-nameCaption">{domainName}</span>
            <span className="ProfileHeader-namePostfix">
              {subDomainName && `.${subDomainName}`}
            </span>
          </div>
          <div className="ProfileHeader-description">{tagline}</div>
        </div>
        <div className="ProfileHeader-rightContent">
          <div className="ProfileHeader-countContainer">
            <div className="ProfileHeader-count">
              <div className="ProfileHeader-countNumber">{viewCount}</div>
              <div className="ProfileHeader-countCaption">Views</div>
            </div>
            <div
              className={classNames(
                'ProfileHeader-count',
                'ProfileHeader-countButton'
              )}
              onClick={() => {
                setFollowMode('following');
                setFollowModalVisible(true);
              }}
            >
              <div className="ProfileHeader-countNumber">{followingCount}</div>
              <div className="ProfileHeader-countCaption">Following</div>
            </div>
            <div
              className={classNames(
                'ProfileHeader-count',
                'ProfileHeader-countButton'
              )}
              onClick={() => {
                setFollowMode('follower');
                setFollowModalVisible(true);
              }}
            >
              <div className="ProfileHeader-countNumber">{followerCount}</div>
              <div className="ProfileHeader-countCaption">Followers</div>
            </div>
          </div>
          <div className="ProfileHeader-actionContainer">
            {myEntityId !== visitEntityId && (
              <div
                className="ProfileHeader-actionButton"
                onClick={isFollowing ? handleUnfollow : handleFollow}
              >
                <div
                  className="ProfileHeader-actionIcon"
                  style={{
                    backgroundImage: `url(${
                      isFollowing ? ImageIconUnfollow : ImageIconFollow
                    })`,
                  }}
                />
                <div className="ProfileHeader-actionCaption">
                  {isFollowing ? 'Unfollow' : 'Follow'}
                </div>
              </div>
            )}
            {/*<div className="ProfileHeader-actionButton">
              <Suspense fallback={sendButtonContents}>
                <LoginButton
                  onLogin={handleSendEth}
                  onError={() => {
                    console.log('error');
                  }}
                >
                  {sendButtonContents}
                </LoginButton>
              </Suspense>
                </div>*/}
            <div className="ProfileHeader-actionButton" onClick={handleSayGm}>
              <div
                className="ProfileHeader-actionIcon"
                style={{ backgroundImage: `url(${ImageIconGm})` }}
              />
              <div className="ProfileHeader-actionCaption">Say GM</div>
            </div>
          </div>
        </div>
      </div>
      {isFollowModalVisible && visitEntityId && (
        <FollowModal
          onClose={handleFollowModalClose}
          title={
            followMode === 'following'
              ? `${followingCount} Following`
              : `${followerCount} Followers`
          }
          followMode={followMode}
          entityId={visitEntityId}
        />
      )}
      {isImageSelectModalVisible && (
        <ImageSelectModal
          onClose={handleImageSelectModalClose}
          title={
            imageSelectMode === 'profile'
              ? 'Select your profile picture'
              : 'Select your cover image'
          }
          onImageSelect={handleImageSelect}
          onNftSelect={handleNftSelect}
          entityId={visitEntityId}
          imageSelectMode={imageSelectMode}
        />
      )}
      {isGm && (
        <LazyMotion features={domAnimation}>
          <m.div
            className="Gm"
            initial={{ transform: 'scale(0.1)', opacity: 1 }}
            animate={{ transform: 'scale(1)', opacity: 0 }}
            transition={{ delay: 0, duration: 1 }}
          >
            👋
          </m.div>
        </LazyMotion>
      )}
    </div>
  );
}
