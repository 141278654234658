import IconNftAbout from 'assets/images/module-type-about.png';
import IconEns from 'assets/images/module-type-ens.png';
import IconEthDenver from 'assets/images/module-type-eth-denver.png';
import IconLink from 'assets/images/module-type-link.png';
import IconNftGroup from 'assets/images/module-type-nft-group.png';
import IconNftSingle from 'assets/images/module-type-nft-single.png';
import IconPayMe from 'assets/images/module-type-pay-me.png';
import IconYouTube from 'assets/images/module-type-youtube.png';
import BaseModal from 'client/components/BaseModal';
import Spinner from 'client/components/Spinner';
import css from 'client/css/ModuleModal.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useState } from 'react';

const MODULE_TYPE_TO_ICON: any = {
  1: IconLink,
  2: IconNftGroup,
  3: IconNftSingle,
  4: IconNftAbout,
  5: IconEns,
  6: IconEthDenver,
  7: IconYouTube,
  8: IconPayMe,
};

type Props = {
  onClose?: () => void;
  onModuleSelect: (moduleTypeId: number) => void;
};

export default function ModuleModal({
  onClose,
  onModuleSelect,
}: Props): JSX.Element {
  useStyles(css);
  const [moduleTypes, setModuleTypes] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/api/getModuleTypes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
      .then((result) => result.json())
      .then((result) => {
        setModuleTypes(result.moduleTypes);
        setLoading(false);
      });
  }, []);

  return (
    <BaseModal
      title={isLoading ? 'Processing...' : 'Select app to add'}
      onClose={onClose}
    >
      <div className="ModuleModal">
        <div className="BaseModal-list">
          {isLoading && <Spinner isCenter={true} />}
          {!isLoading &&
            moduleTypes.map((moduleType, i) => {
              const { name, description, id } = moduleType;
              const iconUrl = MODULE_TYPE_TO_ICON[id];
              return (
                <div
                  key={i}
                  className="BaseModal-item"
                  title={name}
                  onClick={() => {
                    setLoading(true);
                    onModuleSelect(id);
                  }}
                >
                  <div
                    className="BaseModal-itemImage"
                    style={{
                      backgroundImage: `url(${iconUrl})`,
                    }}
                  />
                  <div className="BaseModal-itemColumn">
                    <div className="BaseModal-itemTitle">{name}</div>
                    <div className="BaseModal-itemDescription">
                      {description}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </BaseModal>
  );
}
