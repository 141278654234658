import classNames from 'classnames';
import BaseModal from 'client/components/BaseModal';
import Button from 'client/components/Button';
import Link from 'client/components/Link';
import Spinner from 'client/components/Spinner';
import css from 'client/css/ImageSelectModal.scss';
import { BigNumber } from 'ethers';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useState } from 'react';

type Props = {
  onClose?: () => void;
  initialDomainName?: string;
  title: string;
  onImageSelect?: (imageUrl: string) => void;
  onNftSelect?: (nft: any) => void;
  entityId: number | undefined;
  readOnly?: boolean;
  isEns?: boolean;
  imageSelectMode?: 'profile' | 'cover' | undefined;
};

export default function ImageSelectModal({
  onClose,
  title,
  onImageSelect,
  onNftSelect,
  entityId,
  readOnly,
  isEns,
  imageSelectMode,
}: Props): JSX.Element {
  useStyles(css);
  const [nfts, setNfts] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isEns) {
      fetch('/api/getEnsDomains', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isIncludeEthId: true,
        }),
      })
        .then((result) => result.json())
        .then((result) => {
          setNfts(result.ensDomains);
          setLoading(false);
        });
    } else {
      if (entityId) {
        fetch('/api/getNfts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            entityId,
          }),
        })
          .then((result) => result.json())
          .then((result) => {
            setNfts(result.nfts);
            setLoading(false);
          });
      }
    }
  }, [entityId, isEns]);

  const handleFileUpload = (event: any) => {
    setLoading(true);
    const formData = new FormData();
    const file = event.target.files[0];
    formData.append('image', file, file.name);
    formData.append('entityId', String(entityId));
    fetch(
      imageSelectMode === 'profile'
        ? '/api/uploadProfileImage'
        : '/api/uploadCoverImage',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((result) => result.json())
      .then((result) => {
        setLoading(false);
        if (result.isSuccess && onImageSelect && onClose) {
          onImageSelect(result.imageUrl);
          onClose();
        }
      });
  };

  return (
    <BaseModal title={title} onClose={onClose}>
      <div className="BaseModal-list">
        {!isLoading &&
          (imageSelectMode === 'profile' || imageSelectMode === 'cover') && (
            <div className="ImageSelectModal-upload">
              <label
                className="ImageSelectModal-uploadLabel"
                htmlFor="upload-image"
              >
                <input
                  className="ImageSelectModal-uploadText"
                  type="text"
                  placeholder={
                    imageSelectMode === 'profile'
                      ? 'Select image (10 MB max)'
                      : 'Select image (25 MB max)'
                  }
                />
                <Button
                  className="ImageSelectModal-uploadButton"
                  onClick={() => {}}
                >
                  Upload image
                </Button>
              </label>
              <input
                className="ImageSelectModal-uploadInput"
                type="file"
                id="upload-image"
                onChange={handleFileUpload}
              />
              <div className="ImageSelectModal-instruction">
                Or select from one of your NFT&apos;s
              </div>
            </div>
          )}
        {isLoading && <Spinner isCenter={true} />}
        {!isLoading &&
          nfts
            .filter((nft) => nft.media[0] && nft.media[0].gateway)
            .map((nft, i) => {
              const contractAddress = nft.contract.address;
              const tokenId = BigNumber.from(nft.id.tokenId).toString();
              const itemTitle = nft.title || nft.metadata?.name;
              const description = nft.description || nft.metadata?.description;

              const item = (
                <div className="BaseModal-item" title={itemTitle}>
                  <div
                    className={classNames('BaseModal-itemImage')}
                    style={{
                      backgroundImage: `url(${nft.media[0].gateway})`,
                    }}
                  />
                  <div className="BaseModal-itemColumn">
                    <div className="BaseModal-itemTitle">{itemTitle}</div>
                    <div className="BaseModal-itemDescription">
                      {description}
                    </div>
                  </div>
                </div>
              );

              if (readOnly) {
                return (
                  <Link
                    external
                    newTab
                    key={i}
                    to={`https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`}
                  >
                    {item}
                  </Link>
                );
              } else {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      if (onImageSelect) {
                        onImageSelect(nft.media[0].gateway);
                      }

                      if (onNftSelect) {
                        onNftSelect({
                          url: nft.media[0].gateway,
                          media: nft.media.map((media: any) => {
                            delete media.raw;
                            return media;
                          }),
                          title: nft.title,
                          description: nft.description,
                          contract: nft.contract,
                          contractMetadata: nft.contractMetadata,
                          metadata: {
                            attributes: nft.metadata.attributes,
                            description: nft.metadata.description,
                            name: nft.metadata.name,
                          },
                          timeLastUpdated: nft.timeLastUpdated,
                          tokenUri: {
                            gateway: nft.tokenUri.gateway,
                          },
                          id: nft.id,
                        });
                      }
                    }}
                  >
                    {item}
                  </div>
                );
              }
            })}
      </div>
    </BaseModal>
  );
}
