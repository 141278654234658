export function getAuthUser(setUser: any, setEntity: any) {
  fetch('/api/getAuthUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  })
    .then((result) => result.json())
    .then((result) => {
      setUser(result.user);
      setEntity(result.entity);
    });
}
