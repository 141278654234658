import jwt from 'jsonwebtoken';

export function getAuthCookie() {
  let walletAddress = '';
  if (typeof window !== 'undefined') {
    window.document.cookie.split(';').forEach((item) => {
      const [key, val] = item.trim().split('=').map(decodeURIComponent);
      if (key === 'access_token' && typeof val === 'string') {
        const accessToken = jwt.decode(val) as {
          walletAddress: string;
        };
        walletAddress = accessToken.walletAddress;
      }
    });
  }
  return { walletAddress };

  // let fullDomainName = '';
  // let subDomainName = '';
  // let domainName = '';
  // let entityId = -1;
  // window.document.cookie.split(';').forEach((item) => {
  //   const [key, val] = item.trim().split('=').map(decodeURIComponent);
  //   if (key === 'access_token' && typeof val === 'string') {
  //     const accessToken = jwt.decode(val) as {
  //       walletAddress: string;
  //       fullDomainName: string;
  //       subDomainName: string;
  //       domainName: string;
  //       entityId: number;
  //     };
  //     walletAddress = accessToken.walletAddress;
  //     fullDomainName = accessToken.fullDomainName;
  //     subDomainName = accessToken.subDomainName;
  //     domainName = accessToken.domainName;
  //     entityId = accessToken.entityId;
  //   }
  // });
  //return { walletAddress, fullDomainName, subDomainName, domainName, entityId };
}
