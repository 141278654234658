import { Domain, Entity, Module, Post as PostType } from '@prisma/client';
import SampleProfile from 'assets/images/j.png';
//import Button from 'client/components/Button';
//import Facepile from 'client/components/Facepile';
//import Comments from 'client/components/Comments';
import Link from 'client/components/Link';
//import WagmiButton from 'client/components/WagmiButton';
import css from 'client/css/Post.scss';
import { generateCard } from 'client/helpers/generateCard';
import useStyles from 'isomorphic-style-loader/useStyles';
import ReactTimeAgo from 'react-time-ago';

type Props = {
  post: Partial<PostType> & {
    module: Partial<Module>;
    entityId: number;
    entity: Partial<Entity> & { domain: Partial<Domain>[] };
  };
};

export default function Post({ post }: Props): JSX.Element {
  useStyles(css);

  const { module, entityId, entity, postTypeId, moduleId, createdAt } = post;
  const { domain, profileImageUrl, coverImageUrl } = entity;

  const { fullDomainName } = domain[0] || {};
  let caption;
  if (postTypeId === 1) {
    caption = 'Added an app';
  } else if (postTypeId === 2) {
    caption = 'Updated an app';
  } else if (postTypeId === 3) {
    caption = 'Updated their profile';
  } else if (postTypeId === 4) {
    caption = 'Updated their cover';
  } else if (postTypeId === 5) {
    caption = 'Joined Eth.id';
  } else if (postTypeId === 6) {
    caption = (
      <>
        {`Said GM to `}
        <Link
          to={`https://${post.b}.eth.id`}
          external={true}
        >{`${post.b}`}</Link>
      </>
    );
  } else {
    return <></>;
  }

  return (
    <div className="Post">
      <div className="Post-header">
        <div className="Post-headerLeft">
          <div className="Post-byline" title={`${fullDomainName}`}>
            <Link to={`https://${fullDomainName}.eth.id`} external={true}>
              <div
                className="Post-bylineImage"
                style={{
                  backgroundImage: `url("${profileImageUrl || SampleProfile}")`,
                }}
              />
            </Link>
            <div className="Post-bylineColumn">
              <Link to={`https://${fullDomainName}.eth.id`} external={true}>
                <div className="Post-bylineTitle">{`${fullDomainName}`}</div>
              </Link>
              <div className="Post-bylineDescription">
                {caption}{' '}
                <ReactTimeAgo
                  date={new Date(createdAt as any)}
                  locale="en-US"
                  /*timeStyle="twitter"*/
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Post-headerRight">
          {postTypeId === 6 ? (
            <Link to={`https://${post.b}.eth.id`} external={true}>
              <div
                className="Post-bylineImage"
                style={{
                  backgroundImage: `url("${post.c || SampleProfile}")`,
                }}
              />
            </Link>
          ) : (
            <div />
            /*<Button
              onClick={() => {}}
              size="medium"
              className="Post-followButton"
            >
              Follow
            </Button>*/
          )}
        </div>
      </div>
      <div className="Post-content">
        {moduleId &&
          generateCard({
            entity,
            entityId,
            module,
            isOwner: false,
            dragHandleProps: {},
            handleModuleUpdate: () => {},
            handleModuleDeleteClick: () => {},
          })}
        {postTypeId === 3 && profileImageUrl && (
          <div className="Post-contentAvatar">
            <div
              className="Post-contentAvatarImage"
              style={{ backgroundImage: `url('${profileImageUrl}')` }}
            />
          </div>
        )}
        {postTypeId === 4 && coverImageUrl && (
          <img className="Post-contentCover" src={coverImageUrl} />
        )}
      </div>
      {/*postTypeId !== 6 && (
        <div className="Post-wagmi">
          <div className="Post-wagmiLeft">
            {moduleId && (
              <Button
                onClick={() => {}}
                size="medium"
                className="Post-addAppButton"
              >
                Add app to my profile
              </Button>
            )}
          </div>
          <div className="Post-wagmiRight">
            <Facepile />
            <WagmiButton />
          </div>
        </div>
            )*/}
      {/*<Comments />*/}
    </div>
  );
}
