import classNames from 'classnames';
import css from 'client/css/Spinner.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

type Props = {
  isCenter?: boolean;
};

export default function Spinner({ isCenter }: Props): JSX.Element {
  useStyles(css);
  return (
    <div
      className={classNames({
        'SpinnerContainer--center': isCenter,
      })}
    >
      <svg className="Spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
    </div>
  );
}
