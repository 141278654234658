import { useDebounce, useInterval } from '@usedapp/core';
import ImageA16Z from 'assets/images/a16z-css-clear.png';
import ImageBearCenter from 'assets/images/landing-bear-center.png';
import ImageBearLeftBase from 'assets/images/landing-bear-left-base.png';
import ImageBearLeftPoint from 'assets/images/landing-bear-left-point.png';
import ImageBearRightBase from 'assets/images/landing-bear-right-base.png';
import ImageBearRightPoint from 'assets/images/landing-bear-right-point.png';
import ImageEns from 'assets/images/landing-ens.png';
import ImageEnsLogo from 'assets/images/landing-ens-logo.png';
import ImageEnsTicket from 'assets/images/landing-ens-ticket.png';
import ImageGmailLogin from 'assets/images/landing-gmail-login.png';
import ImageGmailMobile from 'assets/images/landing-gmail-mobile.png';
import ImageLogos from 'assets/images/landing-logos.png';
import ImageProfile from 'assets/images/landing-profile.png';
import ImageSplash184 from 'assets/images/landing-splash-profile-184.png';
import ImageSplashAmazon from 'assets/images/landing-splash-profile-amazon.png';
import ImageSplashChris from 'assets/images/landing-splash-profile-chris.png';
import ImageSplashDennis from 'assets/images/landing-splash-profile-dennis.png';
import ImageSplashJ from 'assets/images/landing-splash-profile-j.png';
import ImageSplashJoe from 'assets/images/landing-splash-profile-joe.png';
import ImageSplashRobin from 'assets/images/landing-splash-profile-robin.png';
import ImageSplashW from 'assets/images/landing-splash-w.png';
import ImageTeamLogos from 'assets/images/landing-team-logos.png';
import ImageX from 'assets/images/landing-x.png';
import classNames from 'classnames';
import Button from 'client/components/Button';
import Card from 'client/components/Card';
import Footer from 'client/components/Footer';
import Link from 'client/components/Link';
import SignupModal from 'client/components/SignupModal';
import Topbar from 'client/components/Topbar';
import css from 'client/css/Landing.scss';
//import { getAuthCookie } from 'client/helpers/getAuthCookie';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import useStyles from 'isomorphic-style-loader/useStyles';
import { lazy, Suspense, useEffect, useState } from 'react';
import { AppContext } from 'shared/AppContext';
import { checkValidName } from 'shared/checkValidName';
import {
  ENS_PROMO_DISCOUNT_PRICE,
  MINT_PASS_COUNT_LIMIT,
} from 'shared/mintPassConstants';

const LoginButton = lazy(() => import('client/components/LoginButton'));
const DomainNftCard = lazy(() => import('client/components/DomainNftCard'));
const FancyNftCard = lazy(() => import('client/components/FancyNftCard'));

const DISCORD_INVITE_LINK = 'https://discord.gg/pDc6Q5Jwjx';
const DEFAULT_PLACEHOLDER = 'joe';

type Props = {
  appContext: AppContext;
};

export default function Landing({ appContext }: Props): JSX.Element {
  useStyles(css);

  const [domainName, setDomainName] = useState(DEFAULT_PLACEHOLDER);
  const [isModalVisible, setModalVisible] = useState(false);
  const [mintPassCount, setMintPassCount] = useState(-1);
  const [mintTimeLeft, setMintTimeLeft] = useState(1);

  const { path, page } = appContext;
  // todo: remove /ens
  const isEns = path.toLowerCase() === '/enss';
  const isWhale = path.toLowerCase() === '/whale' || page === 'whale';
  const isWLink = path.toLowerCase() === '/w' || page === 'w';

  const isUniversityCheck = path.toLowerCase() === '/mit' || page === 'mit';
  const isUniversityVerify =
    path.toLowerCase().indexOf('/university-verify/') > -1;
  const universityNanoId = path.substring(
    path.indexOf('/university-verify/') + '/university-verify/'.length
  );

  const isX = path.toLowerCase() === '/x' || page === 'x';
  const isFren = path.toLowerCase() === '/fren' || page === 'fren';
  const isSeason0 = path.toLowerCase() === '/season0' || page === 'season0';
  const isSf = path.toLowerCase() === '/sf' || page === 'sf';
  const isLa = path.toLowerCase() === '/la' || page === 'la';
  const isNyc = path.toLowerCase() === '/nyc' || page === 'nyc';
  const isSeason1 =
    path.toLowerCase() === '/season1' ||
    page === 'season1' ||
    isSf ||
    isLa ||
    isNyc;
  const isBase =
    !isUniversityCheck &&
    !isUniversityVerify &&
    !isEns &&
    !isWhale &&
    !isWLink &&
    !isX &&
    !isFren &&
    !isSeason0 &&
    !isSeason1;
  const isMe = path.toLowerCase() === '/me' || page === 'me' || isBase;

  //||
  //path.toLocaleLowerCase() === '/'; // This last conditional makes it always ENS.  Remove to restore previous landing

  const initialSubDomainName = isUniversityVerify
    ? 'mit'
    : isX
    ? 'x'
    : isWhale
    ? null
    : isWLink
    ? 'w'
    : isMe
    ? 'me'
    : isSeason0
    ? 'me'
    : isSf
    ? 'sf'
    : isLa
    ? 'la'
    : isNyc
    ? 'nyc'
    : isSeason1
    ? 'la'
    : 'me';

  const [subDomainName, setSubDomainName] = useState(initialSubDomainName);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isEnsSelect, setEnsSelect] = useState(false);

  useEffect(() => {
    if (path.toLowerCase() === '/discord' || page === 'discord') {
      window.location.assign(DISCORD_INVITE_LINK);
    }
  }, [path, page]);

  useEffect(() => {
    fetch('/api/getMintPassCount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
      .then((result) => result.json())
      .then((result) => {
        const mintPassCount = MINT_PASS_COUNT_LIMIT - result.count;
        if (result.count !== -1 && mintPassCount > -1) {
          setMintPassCount(mintPassCount);
        } else {
          setMintPassCount(0);
        }
      });
  }, []);

  useInterval(
    () => {
      if (mintTimeLeft > 0) {
        const now = new Date();
        const nowUTC = new Date(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds()
        );
        const endUTC = new Date(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate() + 1
        );

        const timeLeft = endUTC.getTime() - nowUTC.getTime();
        setMintTimeLeft(timeLeft);

        if (timeLeft <= 1000) {
          window.location.reload();
        }
      }
    },
    isEns ? 1000 : null
  );

  /** Domain checking logic start **/
  const debouncedDomainName = useDebounce(domainName, 500);
  const [isFetching, setFetching] = useState(false);
  const [fetchingName, setFetchingName] = useState<string>();
  const [isAvailable, setAvailable] = useState(false);
  const [isAvailableSeason, setAvailableSeason] = useState(
    isSeason0
      ? {
          me: true,
          w: true,
          x: true,
        }
      : {
          la: true,
          nyc: true,
          sf: true,
        }
  );

  const isNotFetchingAndAvailable =
    !isFetching &&
    debouncedDomainName === domainName &&
    debouncedDomainName === fetchingName &&
    isAvailable;

  useEffect(() => {
    if (
      debouncedDomainName &&
      domainName.length > 0 &&
      debouncedDomainName !== fetchingName &&
      debouncedDomainName !== DEFAULT_PLACEHOLDER
    ) {
      setFetching(true);
      setFetchingName(debouncedDomainName);
      fetch('/api/isDomainAvailable', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fullDomainName: subDomainName
            ? `${debouncedDomainName}.${subDomainName}`
            : debouncedDomainName,
          subDomainName,
          domainName: debouncedDomainName,
          season: isSeason0 ? '0' : '1',
        }),
      })
        .then((result) => result.json())
        .then((result) => {
          const {
            domainName: resultDomainName,
            isAvailable,
            isAvailableSeason,
          } = result;
          if (domainName.toLowerCase() === resultDomainName.toLowerCase()) {
            setFetching(false);
            setAvailable(isAvailable);

            if (isSeason0 || isSeason1) {
              setAvailableSeason(isAvailableSeason);

              if (isSeason0) {
                if (
                  (subDomainName === 'w' ||
                    subDomainName === 'me' ||
                    subDomainName === 'x') &&
                  !isAvailableSeason[subDomainName]
                ) {
                  if (isAvailableSeason.me) {
                    setSubDomainName('me');
                  } else if (isAvailableSeason.w) {
                    setSubDomainName('w');
                  } else if (isAvailableSeason.x) {
                    setSubDomainName('x');
                  }
                }
              } else {
                if (
                  (subDomainName === 'la' ||
                    subDomainName === 'nyc' ||
                    subDomainName === 'sf') &&
                  !isAvailableSeason[subDomainName]
                ) {
                  if (isAvailableSeason.nyc) {
                    setSubDomainName('nyc');
                  } else if (isAvailableSeason.la) {
                    setSubDomainName('la');
                  } else if (isAvailableSeason.sf) {
                    setSubDomainName('sf');
                  }
                }
              }
            }
          }
        });
    }
  }, [
    debouncedDomainName,
    domainName,
    fetchingName,
    subDomainName,
    isSeason0,
    isSeason1,
  ]);
  /** Domain checking logic end **/

  const handleClose = () => {
    setEnsSelect(false);
    if (domainName === '') {
      setDomainName(DEFAULT_PLACEHOLDER);
    }
    setModalVisible(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLogin = (domains: any[] = []) => {
    /*

    // todo: Temporary logic that ensures only one domain under an existing subdomain.  Should be subdomain specific logic later
    const existingDomain = domains.find(
      (domain) => domain.subDomain.subDomainName === subDomainName
    );

    // todo: Temporary hardcoding to prevent test account from redirecting
    const isTestAccount = !!domains.find(
      (domain) => domain.fullDomainName === 'test.me'
    );
    if (existingDomain && !isTestAccount) {
      window.location.assign(`https://${existingDomain.fullDomainName}.eth.id`);
    } else {
      if (isEnsSelect) {
        setDomainName('');
      }
      setModalVisible(true);
    }*/

    setModalVisible(true);
  };

  // const topLoginButtonContents = (
  //   <Button className="LandingCta-button" onClick={() => {}} size="large">
  //     Mint my name
  //   </Button>
  // );

  const isDomainNameEmpty =
    domainName.length === 0 || domainName === DEFAULT_PLACEHOLDER;
  const isReserveDisabled =
    isDomainNameEmpty ||
    isFetching ||
    (!isAvailable && !(isSeason0 || isSeason1)) ||
    (isSeason0 &&
      !isAvailableSeason.me &&
      !isAvailableSeason.w &&
      !isAvailableSeason.x) ||
    (isSeason1 &&
      !isAvailableSeason.la &&
      !isAvailableSeason.nyc &&
      !isAvailableSeason.sf) ||
    debouncedDomainName !== domainName;
  const isMintPassDisabled = !isEns || mintPassCount <= 0;

  const fullDomainNameLength = subDomainName
    ? `${domainName}.${subDomainName}`.length
    : domainName.length;

  const searchLoginButtonContents = (
    <Button
      className="LandingSimulate-button"
      onClick={() => {
        setEnsSelect(false);
      }}
      disabled={isReserveDisabled}
    >
      Reserve
    </Button>
  );

  const ensLoginButtonContents = (
    <Button
      className="LandingEns-button"
      onClick={() => {
        setEnsSelect(true);
      }}
      disabled={isMintPassDisabled}
      size="large"
    >
      Claim a Mint Pass
    </Button>
  );

  const bottomLoginButtonContents = (
    <Button
      className="LandingCta-button"
      onClick={() => {
        setEnsSelect(false);
      }}
      size="large"
    >
      Reserve my name
    </Button>
  );

  const isNotFetchingNorEmpty =
    !isFetching &&
    !isDomainNameEmpty &&
    debouncedDomainName === domainName &&
    debouncedDomainName === fetchingName;

  const [universityCheckEmail, setUniversityCheckEmail] = useState('');
  const [isUniversityCheckSubmitted, setUniversityCheckSubmitted] =
    useState(false);
  const isUniversityCheckEmailValid =
    !!universityCheckEmail.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) &&
    (universityCheckEmail.indexOf('@mit.edu') > -1 ||
      universityCheckEmail.indexOf('@sloan.mit.edu') > -1 ||
      universityCheckEmail.indexOf('@alum.mit.edu') > -1);
  const onUniversityCheckClick = () => {
    fetch('/api/universityCheck', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: universityCheckEmail,
      }),
    })
      .then((result) => result.json())
      .then(() => setUniversityCheckSubmitted(true));
  };

  return (
    <div
      className={classNames('Landing', {
        'Landing--whale': isWhale,
        'Landing--x': isX,
        'Landing--base':
          isSeason0 || isSeason1 || isUniversityCheck || isUniversityVerify,
      })}
    >
      <LazyMotion features={domAnimation}>
        <Topbar
          appContext={appContext}
          isLanding={true}
          subDomainName={subDomainName}
        />
        <div className="LandingCta">
          {isX && (
            <div
              className="LandingCta-xBackground"
              style={{ backgroundImage: `url("${ImageX}")` }}
            ></div>
          )}
          {isWhale && (
            <video className="LandingCta-whaleVideo" autoPlay muted loop>
              <source
                src="https://s3.us-east-2.amazonaws.com/static.eth.id/assets/videos/landing-champagne.mp4"
                type="video/mp4"
              ></source>
            </video>
          )}
          s
          {isWLink && (
            <div className="LandingCta-splash">
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -300 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 20 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -350 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 30 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -400 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 25 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -200 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 45 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -500 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 20 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -320 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 30 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -500 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 40 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                  <img className="LandingCta-splashItem" src={ImageSplashW} />
                </m.div>
              </div>
            </div>
          )}
          {(isMe ||
            isSeason0 ||
            isSeason1 ||
            isUniversityCheck ||
            isUniversityVerify) && (
            <m.div
              className="LandingCta-splash"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -100 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 20 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplash184} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -50 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 30 }}
                >
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -200 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 25 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashAmazon}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplash184} />
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -100 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 45 }}
                >
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashAmazon}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashAmazon}
                  />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -150 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 20 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashAmazon}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img className="LandingCta-splashItem" src={ImageSplash184} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -120 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 30 }}
                >
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplash184} />
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -100 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 40 }}
                >
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                </m.div>
              </div>
            </m.div>
          )}
          <div className="LandingCta-splashMask" />
          <div className="LandingCta-content">
            {!isEns &&
              (isSeason0 ||
              isSeason1 ||
              isUniversityCheck ||
              isUniversityVerify ? (
                <>
                  <div className="LandingCta-baseLogo">Eth.id</div>
                  <div className="LandingCta-baseSeason">
                    {isSeason0
                      ? 'SEASON 0'
                      : isSeason1
                      ? 'SEASON 1'
                      : 'UNIVERSITY'}
                  </div>
                  <div className="LandingCta-headlineLarge">
                    Your Web 3 profile
                  </div>
                  <div className="LandingCta-subHeadlineLarge">
                    {isUniversityCheck || isUniversityVerify
                      ? 'Claim your MIT Ethereum username'
                      : 'Includes an ENS domain with no renewal fees'}
                  </div>
                  <Suspense fallback={<div className="LandingCta-baseNft" />}>
                    {isSeason0 ? (
                      <div className="LandingCta-baseNft">
                        <div className="LandingCta-baseNftItem">
                          <div className="LandingCta-baseNftItemCard">
                            <FancyNftCard
                              isAvailable={isAvailableSeason.w}
                              isNotFetchingNorEmpty={isNotFetchingNorEmpty}
                              subDomainName="w"
                              domainName={domainName}
                            />
                          </div>
                        </div>
                        <div className="LandingCta-baseNftItem">
                          <div className="LandingCta-baseNftItemCenterCard">
                            <FancyNftCard
                              isAvailable={isAvailableSeason.me}
                              isNotFetchingNorEmpty={isNotFetchingNorEmpty}
                              subDomainName="me"
                              domainName={domainName}
                            />
                          </div>
                        </div>
                        <div className="LandingCta-baseNftItem">
                          <div className="LandingCta-baseNftItemCard">
                            <FancyNftCard
                              isAvailable={isAvailableSeason.x}
                              isNotFetchingNorEmpty={isNotFetchingNorEmpty}
                              subDomainName="x"
                              domainName={domainName}
                            />
                          </div>
                        </div>
                      </div>
                    ) : isSeason1 ? (
                      <div className="LandingCta-baseNft">
                        <div className="LandingCta-baseNftItem">
                          <div className="LandingCta-baseNftItemCard">
                            <FancyNftCard
                              isAvailable={isAvailableSeason.sf}
                              isNotFetchingNorEmpty={isNotFetchingNorEmpty}
                              subDomainName="sf"
                              domainName={domainName}
                            />
                          </div>
                        </div>
                        <div className="LandingCta-baseNftItem">
                          <div className="LandingCta-baseNftItemCenterCard">
                            <FancyNftCard
                              isAvailable={isAvailableSeason.la}
                              isNotFetchingNorEmpty={isNotFetchingNorEmpty}
                              subDomainName="la"
                              domainName={domainName}
                            />
                          </div>
                        </div>
                        <div className="LandingCta-baseNftItem">
                          <div className="LandingCta-baseNftItemCard">
                            <FancyNftCard
                              isAvailable={isAvailableSeason.nyc}
                              isNotFetchingNorEmpty={isNotFetchingNorEmpty}
                              subDomainName="nyc"
                              domainName={domainName}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="LandingCta-baseNft">
                        <div className="LandingCta-baseNftItem">
                          <div className="LandingCta-baseNftItemCenterCard">
                            <FancyNftCard
                              isAvailable
                              isNotFetchingNorEmpty={false}
                              subDomainName="mit"
                              domainName={domainName}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Suspense>
                </>
              ) : isX ? (
                <>
                  <div className="LandingCta-xLogo">Eth.id</div>
                  <div className="LandingCta-xSeason">SEASON 0</div>
                  <div className="LandingCta-headlineLarge">
                    Collectible ENS domains
                  </div>
                  <div className="LandingCta-subHeadlineLarge">
                    Mintable for limited time. No Renewal Fees. Flat low price.
                  </div>
                </>
              ) : isWhale ? (
                <>
                  <div className="LandingCta-headlineLarge">
                    What is your name worth?
                  </div>
                  <div className="LandingCta-subHeadlineLarge">
                    Get your forever luxury ENS name
                  </div>
                </>
              ) : isMe ? (
                <>
                  <div className="LandingCta-headlineLarge">
                    Your Web 3 Profile
                  </div>

                  <div className="LandingCta-subHeadlineLarge">
                    It&apos;s quick and easy.
                  </div>
                </>
              ) : (
                isWLink && (
                  <>
                    <div className="LandingCta-headlineLarge">
                      Label your wallet
                    </div>
                    <div className="LandingCta-subHeadlineLarge">
                      No renewal fee ENS domains
                    </div>
                  </>
                )
              ))}
            {isEns && (
              <div className="LandingEns">
                <div className="LandingEns-ticket">
                  <img className="LandingEns-logo" src={ImageEnsLogo} />
                  <img
                    className="LandingEns-ticketImage"
                    src={ImageEnsTicket}
                  />
                </div>
                <div className="LandingEns-headline">
                  Mint Pass for ENS owners
                </div>
                <div className="LandingEns-count">
                  {mintPassCount >= 0 ? mintPassCount : ''} left today
                </div>
                <div className="LandingEns-headline">
                  {`Resets in ${Math.floor(
                    mintTimeLeft / (60 * 60 * 1000)
                  )} hrs ${Math.floor(
                    (mintTimeLeft % (60 * 60 * 1000)) / (60 * 1000)
                  )} mins ${Math.floor(
                    (mintTimeLeft % (60 * 1000)) / 1000
                  )} secs`}
                </div>
                <div className="LandingEns-cta">
                  <Suspense fallback={ensLoginButtonContents}>
                    <LoginButton
                      onLogin={handleLogin}
                      onError={handleClose}
                      isDisabled={isMintPassDisabled}
                    >
                      {ensLoginButtonContents}
                    </LoginButton>
                  </Suspense>
                </div>
                <div className="LandingEns-explain">
                  <div className="LandingEns-explainBox">
                    <div className="LandingEns-explainPrompt">
                      How does this work?
                    </div>
                    <div className="LandingEns-explainAnswer">
                      {`If you own the ENS domain, you can mint the Eth.id domain
                      for ${ENS_PROMO_DISCOUNT_PRICE} ETH.`}
                    </div>
                  </div>
                  <div className="LandingEns-explainBox">
                    <div className="LandingEns-explainPrompt">
                      When does it reset?
                    </div>
                    <div className="LandingEns-explainAnswer">
                      Mint passes reset daily at 00:00 GMT. Price is subject to
                      change.
                    </div>
                  </div>
                  <div className="LandingEns-explainBox">
                    <div className="LandingEns-explainPrompt">Any catches?</div>
                    <div className="LandingEns-explainAnswer">
                      Email service is not included for mint pass domains.
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="LandingSimulate">
              <div className="LandingSimulate-column">
                {isEns && (
                  <div className="LandingSimulate-reg">
                    Regular registration
                  </div>
                )}
                <div className="LandingSimulate-formContainer">
                  {(isMe || isX || isSeason0 || isSeason1) && (
                    <img
                      className="LandingSimulate-bear"
                      src={
                        isNotFetchingAndAvailable
                          ? ImageBearCenter
                          : domainName.length > 0 &&
                            domainName !== DEFAULT_PLACEHOLDER
                          ? ImageBearLeftPoint
                          : ImageBearLeftBase
                      }
                      title="GROWWWWWWWWWWWL!"
                    />
                  )}
                  {isUniversityCheck ? (
                    isUniversityCheckSubmitted ? (
                      <>Please check your email at {universityCheckEmail}</>
                    ) : (
                      <div className="LandingSimulate-form">
                        <input
                          type="text"
                          className={classNames('LandingSimulate-input', {
                            'LandingSimulate-input--blank':
                              universityCheckEmail.length === 0,
                          })}
                          placeholder="Type your @mit.edu"
                          value={universityCheckEmail}
                          onChange={(event) => {
                            const rawEmail = event.target.value.toLowerCase();
                            rawEmail.replace(/[^a-zA-Z0-9@.]/g, '');
                            setUniversityCheckEmail(
                              rawEmail.replace(/[^a-zA-Z0-9@.]/g, '')
                            );
                          }}
                        ></input>
                        <Button
                          className="LandingSimulate-button"
                          onClick={onUniversityCheckClick}
                          disabled={!isUniversityCheckEmailValid}
                        >
                          Send email
                        </Button>
                      </div>
                    )
                  ) : (
                    <div className="LandingSimulate-form">
                      <input
                        type="text"
                        className={classNames('LandingSimulate-input', {
                          'LandingSimulate-input--blank':
                            domainName.length === 0 ||
                            domainName === DEFAULT_PLACEHOLDER ||
                            (!isFetching &&
                              debouncedDomainName === domainName &&
                              debouncedDomainName === fetchingName &&
                              !isAvailable),
                        })}
                        placeholder="Type your name"
                        value={
                          domainName !== DEFAULT_PLACEHOLDER ? domainName : ''
                        }
                        onChange={(event) =>
                          setDomainName(
                            checkValidName(event.target.value.toLowerCase())
                          )
                        }
                        maxLength={15}
                      ></input>
                      <Suspense fallback={searchLoginButtonContents}>
                        <LoginButton
                          onLogin={handleLogin}
                          onError={handleClose}
                          isDisabled={isReserveDisabled}
                        >
                          {searchLoginButtonContents}
                        </LoginButton>
                      </Suspense>
                    </div>
                  )}
                  {(isMe || isX || isSeason0 || isSeason1) && (
                    <img
                      className="LandingSimulate-bear"
                      src={
                        isNotFetchingAndAvailable
                          ? ImageBearCenter
                          : domainName.length > 0 &&
                            domainName !== DEFAULT_PLACEHOLDER
                          ? ImageBearRightPoint
                          : ImageBearRightBase
                      }
                      title="Don't mind him. He's just hungry."
                    />
                  )}
                </div>
                {!(isSeason0 || isSeason1) && (
                  <div
                    className={classNames('LandingSimulate-availability', {
                      'LandingSimulate-availability--available': isAvailable,
                    })}
                  >
                    {!isFetching &&
                      !isDomainNameEmpty &&
                      debouncedDomainName === domainName &&
                      debouncedDomainName === fetchingName && (
                        <>
                          {isAvailable ? (
                            <>
                              {isWhale
                                ? 'Domain is available.'
                                : 'Domain is available!'}
                            </>
                          ) : (
                            <>Sorry, this domain is not available</>
                          )}
                        </>
                      )}
                  </div>
                )}
              </div>
            </div>
            {(isMe || isUniversityCheck || isUniversityVerify) && (
              <div className="LandingSimulate-features">
                {isUniversityCheck || isUniversityVerify ? (
                  <div className="LandingSimulate-featuresItem">
                    <div className="LandingSimulate-featuresItemIcon">🐘</div>
                    <div className="LandingSimulate-featuresItemCaption">
                      Mint domain once, yours forever
                    </div>
                  </div>
                ) : (
                  <div className="LandingSimulate-featuresItem">
                    <div className="LandingSimulate-featuresItemIcon">💵</div>
                    <div className="LandingSimulate-featuresItemCaption">
                      Buy domain once, yours forever
                    </div>
                  </div>
                )}
                <div className="LandingSimulate-featuresItem">
                  <div className="LandingSimulate-featuresItemIcon">🖼️</div>
                  <div className="LandingSimulate-featuresItemCaption">
                    Publish and sell your art as NFT&apos;s
                  </div>
                </div>
                {isUniversityCheck || isUniversityVerify ? (
                  <div className="LandingSimulate-featuresItem">
                    <div className="LandingSimulate-featuresItemIcon">🥧</div>
                    <div className="LandingSimulate-featuresItemCaption">
                      Easy as pi. IHTFP-proof
                    </div>
                  </div>
                ) : (
                  <div className="LandingSimulate-featuresItem">
                    <div className="LandingSimulate-featuresItemIcon">💳</div>
                    <div className="LandingSimulate-featuresItemCaption">
                      Use credit card instead of crypto
                    </div>
                  </div>
                )}
              </div>
            )}
            {(isSeason0 || isSeason1) && (
              <div className="LandingSimulate-a16z">
                <img src={ImageA16Z} className="LandingSimulate-a16zImage" />
                Proudly backed by a16z Crypto Startup School
                <img src={ImageA16Z} className="LandingSimulate-a16zImage" />
              </div>
            )}
          </div>
          {/* 
          <div className="LandingCta-subheadline">
            Don&lsquo;t miss your chance to get your name.
          </div>
           <Suspense fallback={topLoginButtonContents}>
            <LoginButton appContext={appContext} onLogin={handleLogin} onError={handleClose}>
              {topLoginButtonContents}
            </LoginButton>
          </Suspense> */}
        </div>
        <div className="LandingUpsell">
          {isWhale ? (
            <div className="LandingUpsell-caption">
              Not a whale yet and want a&nbsp;
              <Link to="https://eth.id" external={true}>
                cheap Web 3 domain
              </Link>
              ? Visit the&nbsp;
              <Link to="https://eth.id" external={true}>
                classic experience.
              </Link>
            </div>
          ) : (
            <div className="LandingUpsell-caption">
              Are you a whale looking for&nbsp;
              <Link to="https://whale.eth.id" external={true}>
                premium domains
              </Link>
              ? Visit the&nbsp;
              <Link to="https://whale.eth.id" external={true}>
                vip experience.
              </Link>
            </div>
          )}
        </div>
        {isX && (
          <div className="LandingSection">
            <div className="LandingCta-splash">
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -100 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 20 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplash184} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -50 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 30 }}
                >
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -200 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 25 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashAmazon}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplash184} />
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -100 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 45 }}
                >
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashAmazon}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashAmazon}
                  />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -150 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 20 }}
                >
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashAmazon}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img className="LandingCta-splashItem" src={ImageSplash184} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -120 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 30 }}
                >
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplash184} />
                  <img className="LandingCta-splashItem" src={ImageSplashJoe} />
                </m.div>
              </div>
              <div className="LandingCta-splashColumn">
                <m.div
                  initial={{ y: -100 }}
                  animate={{ y: -640 }}
                  transition={{ delay: 0.5, duration: 40 }}
                >
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashRobin}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashDennis}
                  />
                  <img
                    className="LandingCta-splashItem"
                    src={ImageSplashChris}
                  />
                  <img className="LandingCta-splashItem" src={ImageSplashJ} />
                </m.div>
              </div>
            </div>
            <div className="LandingSection-splashMask" />
            <div className="LandingSection-content">
              <m.div
                className="LandingSection-headline"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 0 }}
                transition={{ delay: 0, duration: 0.5 }}
              >
                Eth.id domains are tradable on OpenSea
              </m.div>

              <m.div
                className="LandingSection-nft"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.25, duration: 0.5 }}
              >
                <Suspense fallback={<div className="LandingDomainNftCard" />}>
                  <div className="LandingDomainNftCard">
                    <DomainNftCard
                      subDomainName={subDomainName}
                      fullDomainName={
                        subDomainName
                          ? `${domainName}.${subDomainName}`
                          : domainName
                      }
                    />
                  </div>
                </Suspense>
              </m.div>
              <m.div
                className="LandingSection-nftItems"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                <div className="LandingSection-itemTitle">
                  Domains are minted as NFT&apos;s into your wallet <br />
                  Collect and trade X domains on OpenSea
                  <br />
                </div>
              </m.div>
            </div>
          </div>
        )}
        <div className="LandingComponents">
          <div className="LandingDomain">
            <m.div
              className="LandingDomain-headline"
              onClick={(event) => event.stopPropagation()}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0, duration: 0.5 }}
            >
              {isEns
                ? 'Single domain across ENS and Web'
                : isWhale
                ? 'A name that will be left in history'
                : isWLink
                ? 'Easy-to-remember name for your wallet forever'
                : isMe
                ? 'Includes your Web 3 domain'
                : 'Your single domain across ENS and Web'}
            </m.div>
            <m.div
              className={classNames('LandingDomain-domain', {
                'LandingDomain-domain--small': fullDomainNameLength > 11,
                'LandingDomain-domain--medium':
                  fullDomainNameLength > 7 && fullDomainNameLength <= 11,
              })}
              onClick={(event) => event.stopPropagation()}
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.25, duration: 1 }}
            >
              {subDomainName
                ? `${domainName}.${subDomainName}.eth.id`
                : `${domainName}.eth.id`}
            </m.div>
            {/* <div className="LandingDomain-tagline">
            A new type of{' '}
            <span className="LandingDomain-taglineExtension">.eth</span> domain.
          </div> */}
          </div>

          <div className="LandingExplain">
            <div className="LandingExplain-boxes">
              <m.div
                className="LandingExplain-card"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 0.5 }}
              >
                <Card title="Your Web 3 domain" titleAlign="center">
                  <div className="LandingExplain-box">
                    <div
                      className={classNames('LandingExplain-address', {
                        'LandingExplain-address--small':
                          fullDomainNameLength > 11,
                        'LandingExplain-address--medium':
                          fullDomainNameLength > 7 &&
                          fullDomainNameLength <= 11,
                      })}
                    >
                      <span className="LandingExplain-prefix">ens://</span>
                      {domainName}
                      <span className="LandingExplain-suffix">
                        {subDomainName ? `.${subDomainName}.eth.id` : `.eth.id`}
                      </span>
                    </div>
                    <div className="LandingExplain-caption">
                      {isWhale
                        ? 'Use as your ENS domain'
                        : 'Use as your Ethereum (ENS) domain'}
                    </div>
                  </div>
                  {isMe && (
                    <img
                      src={ImageBearLeftPoint}
                      className="LandingExplain-bearLeft"
                    />
                  )}
                </Card>
              </m.div>

              <m.div
                className="LandingExplain-card"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.25, duration: 0.5 }}
              >
                <Card title="Your Web 2 domain" titleAlign="center">
                  <div className="LandingExplain-box">
                    <div
                      className={classNames('LandingExplain-address', {
                        'LandingExplain-address--small':
                          fullDomainNameLength > 11,
                        'LandingExplain-address--medium':
                          fullDomainNameLength > 7 &&
                          fullDomainNameLength <= 11,
                      })}
                    >
                      <span className="LandingExplain-prefix">https://</span>
                      {domainName}
                      <span className="LandingExplain-suffix">
                        {subDomainName ? `.${subDomainName}.eth.id` : `.eth.id`}
                      </span>
                    </div>
                    <div className="LandingExplain-caption">
                      {isWhale
                        ? 'Use as your web address'
                        : 'Use as your profile website'}
                    </div>
                  </div>
                  {isMe && (
                    <img
                      src={ImageBearRightPoint}
                      className="LandingExplain-bearRight"
                    />
                  )}
                </Card>
              </m.div>
              {/*!isEns && (
              <>
                <m.div
                  className="LandingExplain-card"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1.5, duration: 0.5 }}
                >
                  <Card title="Your Email address" titleAlign="center">
                    <div className="LandingExplain-box">
                      <div
                        className={classNames('LandingExplain-address', {
                          'LandingExplain-address--small':
                            fullDomainNameLength > 11,
                          'LandingExplain-address--medium':
                            fullDomainNameLength > 7 && fullDomainNameLength <= 11,
                        })}
                      >
                        {domainName}@eth.id
                      </div>
                      <div className="LandingExplain-caption">
                        Access mail through any Gmail app
                      </div>
                    </div>
                  </Card>
                </m.div>
              </>
                      )*/}
            </div>
            <div className="LandingExplain-button">
              <Suspense fallback={bottomLoginButtonContents}>
                <LoginButton onLogin={handleLogin} onError={handleClose}>
                  {bottomLoginButtonContents}
                </LoginButton>
              </Suspense>
            </div>
          </div>
        </div>
        {isWhale && (
          <div className="LandingSectionWhale">
            <video className="LandingCta-whaleInlineVideo" autoPlay muted loop>
              <source
                src="https://s3.us-east-2.amazonaws.com/static.eth.id/assets/videos/landing-poker.mp4"
                type="video/mp4"
              ></source>
            </video>
            <div className="LandingSectionWhale-overlay">
              <div className="LandingSectionWhale-headline">
                Let your name <br /> live up to your legend
              </div>
            </div>
          </div>
        )}
        {!isWhale && (
          <div className="LandingSection">
            <m.div
              className="LandingSection-headline"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              Your website with zero coding
            </m.div>

            <div className="LandingSection-profile">
              <div className="LandingSection-profileColumn">
                <m.div
                  className={classNames('LandingSection-profileHeadline', {
                    'LandingSection-profileHeadline--small':
                      fullDomainNameLength > 9,
                    'LandingSection-profileHeadline--medium':
                      fullDomainNameLength > 5 && fullDomainNameLength <= 9,
                  })}
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.75, duration: 0.5 }}
                >
                  {subDomainName
                    ? `${domainName}.${subDomainName}.eth.id`
                    : `${domainName}.eth.id`}
                </m.div>
                <m.div
                  className="LandingSection-profileCaption"
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 0.5 }}
                >
                  One page for <br />
                  all your links
                </m.div>

                <m.div
                  className="LandingSection-profileCaption"
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 0.5 }}
                >
                  Build a gallery and store for your NFT collection
                </m.div>

                <m.div
                  className="LandingSection-profileCaption"
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 0.5 }}
                >
                  Build a store for <br />
                  selling your ENS domains
                </m.div>

                <m.div
                  className="LandingSection-profileCaption"
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 0.5 }}
                >
                  Works on every browser without extensions
                </m.div>
              </div>
              <div
                className="LandingSection-profileImage"
                style={{ backgroundImage: `url(${ImageProfile})` }}
              >
                <div className="LandingSection-profileUrl">
                  {subDomainName
                    ? `https://${domainName}.${subDomainName}.eth.id`
                    : `https://${domainName}.eth.id`}
                </div>
                <div className="LandingSection-profileDomain">
                  <div>
                    {domainName}
                    <span className="LandingSection-profileDomainSmall">
                      {subDomainName ? `.${subDomainName}.eth.id` : '.eth.id'}
                    </span>
                  </div>
                  <div className="LandingSection-profileDomainTagline">
                    Founding member of Eth.id
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames('LandingComponents', 'LandingComponents-clean')}
        >
          <m.div
            className="LandingComponents-cleanHeadline"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ delay: 0, duration: 0.5 }}
          >
            Best availability of rarest names
          </m.div>
          <div className="LandingComponents-cleanBackground">Clean</div>
          <m.div
            className="LandingExplain-button"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.25, duration: 0.5 }}
          >
            <Suspense fallback={bottomLoginButtonContents}>
              <LoginButton onLogin={handleLogin} onError={handleClose}>
                {bottomLoginButtonContents}
              </LoginButton>
            </Suspense>
          </m.div>
        </div>
        <div className="LandingSection">
          <m.div
            className="LandingSection-headline"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Seamless Web 3 with ENS
          </m.div>

          <div className="LandingSection-ens">
            <div
              className="LandingSection-ensImage"
              style={{ backgroundImage: `url(${ImageEns})` }}
            >
              <div className="LandingSection-ensDomain">
                {subDomainName
                  ? `${domainName}.${subDomainName}.eth.id`
                  : `${domainName}.eth.id`}
              </div>
            </div>
            <div className="LandingSection-ensColumn">
              <m.div
                className="LandingSection-ensCaption"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.25, duration: 0.5 }}
              >
                Easy-to-remember name for sending and receiving crypto
              </m.div>
              <m.div
                className="LandingSection-ensCaption"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                No renewal fees
              </m.div>
              <m.div
                className="LandingSection-ensCaption"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.75, duration: 0.5 }}
              >
                Usable in Metamask and 500+ apps
              </m.div>
              <m.div
                className="LandingSection-ensCaption"
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 0.5 }}
              >
                Top tier Web 3 experience
              </m.div>
            </div>
          </div>
        </div>
        {isWhale && (
          <div className="LandingSectionWhale">
            <video className="LandingCta-whaleInlineVideo" autoPlay muted loop>
              <source
                src="https://s3.us-east-2.amazonaws.com/static.eth.id/assets/videos/landing-pool.mp4"
                type="video/mp4"
              ></source>
            </video>
            <div className="LandingSectionWhale-overlay">
              <div className="LandingSectionWhale-headline">
                A person&apos;s house <br />
                befits their worth
              </div>
            </div>
          </div>
        )}
        <div className="LandingComponents">
          <m.div
            className="LandingComponents-headline"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Supported on 500+ apps with ENS
          </m.div>
          <m.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <img className="LandingComponents-logos" src={ImageLogos} />
          </m.div>
          <m.div
            className="LandingExplain-button"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.75, duration: 0.5 }}
          >
            <Suspense fallback={bottomLoginButtonContents}>
              <LoginButton onLogin={handleLogin} onError={handleClose}>
                {bottomLoginButtonContents}
              </LoginButton>
            </Suspense>
          </m.div>
        </div>
        {!subDomainName && (
          <div className="LandingSection">
            <m.div
              className="LandingSection-headline"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Full power of Gmail for Web 3
            </m.div>

            <div className="LandingSection-gmailMobile">
              <div
                className="LandingSection-gmailMobilePhone"
                style={{ backgroundImage: `url(${ImageGmailMobile})` }}
              />

              <div className="LandingSection-gmailMobileColumn">
                <m.div
                  className="LandingSection-gmailMobileIntro"
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.25, duration: 0.5 }}
                >
                  Meet your new email for Web 3
                </m.div>
                <m.div
                  className={classNames('LandingSection-gmailMobileHeadline', {
                    'LandingSection-gmailMobileHeadline--small':
                      fullDomainNameLength > 11,
                    'LandingSection-gmailMobileHeadline--medium':
                      fullDomainNameLength > 7 && fullDomainNameLength <= 11,
                  })}
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                >
                  {subDomainName
                    ? `${domainName}.${subDomainName}@eth.id`
                    : `${domainName}@eth.id`}
                </m.div>
                <m.div
                  className="LandingSection-gmailMobileCaption"
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.75, duration: 0.5 }}
                >
                  Access your Eth.id Gmail on iOS, Android and Web clients you
                  already use
                </m.div>

                <m.div
                  className="LandingSection-gmailScreens"
                  initial={{ opacity: 0, y: -20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 0.5 }}
                >
                  <div
                    className="LandingSection-gmailScreen"
                    style={{ backgroundImage: `url(${ImageGmailLogin})` }}
                  >
                    <div className="LandingSection-gmailUrl">
                      https://notion.so
                    </div>
                    <div className="LandingSection-gmailEmail">
                      {subDomainName
                        ? `${domainName}.${subDomainName}@eth.id`
                        : `${domainName}@eth.id`}
                    </div>
                  </div>
                  <div className="LandingSection-gmailMobileScreenCaption">
                    One-click login for Web 2 services
                  </div>
                </m.div>
              </div>
            </div>
          </div>
        )}
        {isWhale && (
          <div className="LandingSectionWhale">
            <video className="LandingCta-whaleInlineVideo" autoPlay muted loop>
              <source
                src="https://s3.us-east-2.amazonaws.com/static.eth.id/assets/videos/landing-lambo.mp4"
                type="video/mp4"
              ></source>
            </video>
            <div className="LandingSectionWhale-overlay">
              <div className="LandingSectionWhale-headline">
                A person of quality
                <br />
                is worthy of respect
              </div>
            </div>
          </div>
        )}
        {!subDomainName && (
          <div className="LandingComponents">
            <m.div
              className="LandingComponents-headline"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              World-class engineering team
            </m.div>
            <m.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              <img className="LandingComponents-logos" src={ImageTeamLogos} />
            </m.div>
            <m.div
              className="LandingExplain-button"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.75, duration: 0.5 }}
            >
              <Suspense fallback={bottomLoginButtonContents}>
                <LoginButton onLogin={handleLogin} onError={handleClose}>
                  {bottomLoginButtonContents}
                </LoginButton>
              </Suspense>
            </m.div>
          </div>
        )}
        <div className="LandingSection">
          <m.div
            className="LandingSection-headline"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ delay: 0, duration: 0.5 }}
          >
            Eth.id domains are tradable on OpenSea
          </m.div>

          <m.div
            className="LandingSection-nft"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.25, duration: 0.5 }}
          >
            <Suspense fallback={<div className="LandingDomainNftCard" />}>
              <div className="LandingDomainNftCard">
                <DomainNftCard
                  subDomainName={subDomainName}
                  fullDomainName={
                    subDomainName
                      ? `${domainName}.${subDomainName}`
                      : domainName
                  }
                />
              </div>
            </Suspense>
          </m.div>
          <m.div
            className="LandingSection-nftItems"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <div className="LandingSection-itemTitle">
              Domains are minted as NFT&apos;s into your wallet <br />
              Collect and trade Eth.id domains on OpenSea
              <br />
            </div>
          </m.div>
        </div>
        {isWhale && (
          <div className="LandingSectionWhale">
            <video className="LandingCta-whaleInlineVideo" autoPlay muted loop>
              <source
                src="https://s3.us-east-2.amazonaws.com/static.eth.id/assets/videos/landing-girl.mp4"
                type="video/mp4"
              ></source>
            </video>
            <div className="LandingSectionWhale-overlay">
              <div className="LandingSectionWhale-headline">
                How will you
                <br />
                be remembered?
              </div>
            </div>
          </div>
        )}
        <div className="LandingComponents">
          <m.div
            className="LandingComponents-headline"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {isWhale ? (
              <>
                Questions? <br />
                Please contact concierge@eth.id
              </>
            ) : (
              <>
                Questions? <br />
                Send an email to j@eth.id!
              </>
            )}
          </m.div>
        </div>
        <div className="LandingEnd">
          <div className="LandingCta-community">
            <m.div
              className="LandingCta-headline"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.25, duration: 0.5 }}
            >
              Your Web 3 legend starts here
            </m.div>
            <m.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              <Suspense fallback={bottomLoginButtonContents}>
                <LoginButton onLogin={handleLogin} onError={handleClose}>
                  {bottomLoginButtonContents}
                </LoginButton>
              </Suspense>
            </m.div>
          </div>
        </div>
        {subDomainName && (
          <div className="LandingComponents">
            <m.div
              className="LandingComponents-headline"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              World-class engineering team
            </m.div>
            <m.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              <img className="LandingComponents-logos" src={ImageTeamLogos} />
            </m.div>
          </div>
        )}
      </LazyMotion>
      <Footer />
      {isModalVisible && (
        <SignupModal
          onClose={handleClose}
          initialDomainName={domainName}
          subDomainName={subDomainName}
          universityNanoId={universityNanoId}
        />
      )}
    </div>
  );
}
