import { useDynamicContext } from '@dynamic-labs/sdk-react';
import { hexlify } from '@ethersproject/bytes';
import { toUtf8Bytes } from '@ethersproject/strings';
import { formatEther, parseEther } from '@ethersproject/units';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  useDebounce,
  useEtherBalance,
  useEthers,
  useSendTransaction,
} from '@usedapp/core';
import ImageBearCenter from 'assets/images/landing-bear-center.png';
//import ImageEns from 'assets/images/landing-ens.png';
import ImageWhale from 'assets/images/signup-whale.png';
import classNames from 'classnames';
import Button from 'client/components/Button';
import ReverseResolutionModal from 'client/components/ReverseResolutionModal';
import SignupModalUsdFlow from 'client/components/SignupModalUsdFlow';
import SignupSubDomainButton from 'client/components/SignupSubDomainButton';
import Spinner from 'client/components/Spinner';
import css from 'client/css/SignupModal.scss';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import useStyles from 'isomorphic-style-loader/useStyles';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkValidExternalEmail } from 'shared/checkValidEmail';
import { checkValidName } from 'shared/checkValidName';
import getDomainNameFromEnsDomain from 'shared/getDomainNameFromEnsDomain';

const MIN_ETH_BALANCE_FOR_REVERSE_RESOLUTION = 0.002;
const DomainNftCard = lazy(() => import('client/components/DomainNftCard'));
const stripePromise = loadStripe(
  //'pk_test_51L8QrcF0TqqCiC4c913lIWtH1Dd3L45sVzC8QBCt01zqJ3aGIzEaeN7iowfo0jjNUglYdsFd5ATZc61RtXYNxuol00EPTDWDhK'
  'pk_live_51L8QrcF0TqqCiC4cFMruCO0xWUecsTKYpUifHzkFoHtjzFn3kyGgQXMOdsYKMqduoIjlj6LMdyMIGBHXJL07PIHi00UPTtnRPG'
);

type Props = {
  onClose?: () => void;
  initialDomainName?: string;
  subDomainName: string | null;
  universityNanoId?: string;
};

const DEFAULT_PLACEHOLDER = 'joe';
const ETH_ID_ADDRESS = '0x208e4302d41cabeecd8e7774ff22bb6e1339d0a5';
const WHALE_PRICE_LIMIT = 1;
const isEnsEnabled = false;

export default function SignupModal({
  onClose,
  initialDomainName = '',
  subDomainName: initialSubDomainName,
  universityNanoId = '',
}: Props): JSX.Element {
  useStyles(css);
  const [searchParams] = useSearchParams();
  const { user, primaryWallet } = useDynamicContext();

  const isSeason0 =
    initialSubDomainName === 'me' ||
    initialSubDomainName === 'w' ||
    initialSubDomainName === 'x';

  const isMit = initialSubDomainName === 'mit';

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const { account: walletAddressRaw } = useEthers();
  const walletAddress = (walletAddressRaw || '').toLowerCase();
  const ethBalance = useEtherBalance(walletAddress);
  const navigate = useNavigate();

  const [isEns, setEns] = useState(false);

  // Used to solve modal exiting while dragging on textbox
  const [isDragging, setDragging] = useState(false);

  const [email, setEmail] = useState('');
  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
    }
  }, [user?.email]);

  const [domainName, setDomainName] = useState(initialDomainName);
  const [price, setPrice] = useState<{
    price: number;
    originalPrice: number;
    usd: number;
    originalUsd: number;
  }>();
  const [error, setError] = useState<string>();
  const [isAgreeTerms, setAgreeTerms] = useState(false);
  const [isAvailable, setAvailable] = useState(false);
  const [isAvailableSeason, setAvailableSeason] = useState(
    isSeason0
      ? {
          me: true,
          w: true,
          x: true,
        }
      : {
          sf: true,
          la: true,
          nyc: true,
        }
  );
  const [isWaiting, setWaiting] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [params, setParams] = useState<{
    domainName: string;
    transactionValue: string;
    walletAddress: string;
  }>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const debouncedDomainName = useDebounce(domainName, 500);
  const [fetchingName, setFetchingName] = useState<string>();
  const [fetchingSubDomainName, setFetchingSubDomainName] =
    useState(initialSubDomainName);
  const [submittedDomainName, setSubmittedDomainName] = useState<string>();
  const [stage, setStage] = useState(0); // 0 - Searching, 1 - Waiting transaction mining, 2 - Waiting domain registration, 3 - Waiting for remote ENS processing, 4 - done
  const [subDomainName, setSubDomainName] = useState(initialSubDomainName);
  const [isReverseResolutionModalActive, setReverseResolutionModalActive] =
    useState(false);

  const [debugState, setDebugState] = useState<string>();
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState<string>();

  const { sendTransaction, state } = useSendTransaction({
    transactionName: 'Send Ethereum',
  });

  // If USD price is provided and we detect user has less eth in wallet than price.
  const isUsdFlow =
    price?.usd && ethBalance?.lt(parseEther(String(price.price)));

  if (error) {
    console.log('error', error);
  }

  // -- ENS DOMAINS

  // todo: Remove ENS domains
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ensDomains, setEnsDomains] = useState([]);
  /*useEffect(() => {
    fetch('/api/getEnsDomains', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        const { ensDomains } = result;
        setEnsDomains(ensDomains);

        ensDomains.map((ensDomain: any) => {
          const domainName = getDomainNameFromEnsDomain(ensDomain);
          fetch('/api/isDomainAvailable', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              domainName,
              subDomainName,
              fullDomainName: subDomainName
                ? `${domainName}.${subDomainName}`
                : domainName,
              season: isSeason0 ? '0' : '1',
            }),
          })
            .then((result) => result.json())
            .then((result) => {
              const { isAvailable } = result;
              if (isAvailable) {
                setEnsDomains(
                  ensDomains
                    .filter(
                      (ensDomain: any) =>
                        getDomainNameFromEnsDomain(ensDomain) !== domainName
                    )
                    .concat({
                      ...ensDomain,
                      isAvailable: true,
                    })
                );
              }
            });
        });
      });
  }, [walletAddress, subDomainName, isSeason0]);*/

  // -- ENS DOMAINS
  useEffect(() => {
    setPrice(undefined);
    setError(undefined);
    setPaymentIntentClientSecret(undefined);
    setErrorMessage('');
  }, [domainName, subDomainName]);

  useEffect(() => {
    setAvailableSeason({ me: true, x: true, w: true });
  }, [domainName]);

  useEffect(() => {
    if (
      debouncedDomainName &&
      domainName.length > 0 &&
      ((debouncedDomainName !== fetchingName &&
        debouncedDomainName !== DEFAULT_PLACEHOLDER) ||
        subDomainName !== fetchingSubDomainName)
    ) {
      setFetching(true);
      setFetchingName(debouncedDomainName);
      setFetchingSubDomainName(subDomainName);

      const isEns = !!ensDomains.find(
        (ensDomain) =>
          getDomainNameFromEnsDomain(ensDomain) === domainName.toLowerCase()
      );
      setEns(isEns);

      fetch('/api/checkDomain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress,
          domainName: debouncedDomainName,
          subDomainName,
          fullDomainName: subDomainName
            ? `${debouncedDomainName}.${subDomainName}`
            : domainName,
          isEns,
        }),
      })
        .then((result) => result.json())
        .then((result) => {
          const {
            domainName: resultDomainName,
            price,
            isAvailable,
            paymentIntentClientSecret,
          } = result;
          if (
            domainName.toLowerCase() === resultDomainName.toLowerCase() &&
            stage === 0
          ) {
            setFetching(false);
            setPrice(price);
            setPaymentIntentClientSecret(paymentIntentClientSecret);
            setAvailable(isAvailable);
          }
        })
        .catch((err) => {
          setDebugState(JSON.stringify(err));

          // fetch('/logout', {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // }).then(() => {
          //   window.location.reload();
          // });
        });

      fetch('/api/isDomainAvailable', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fullDomainName: subDomainName
            ? `${debouncedDomainName}.${subDomainName}`
            : debouncedDomainName,
          subDomainName,
          domainName: debouncedDomainName,
          season: isSeason0 ? '0' : '1',
        }),
      })
        .then((result) => result.json())
        .then((result) => {
          const { domainName: resultDomainName, isAvailableSeason } = result;
          if (domainName.toLowerCase() === resultDomainName.toLowerCase()) {
            setAvailableSeason(isAvailableSeason);

            if (isSeason0) {
              if (
                (subDomainName === 'w' ||
                  subDomainName === 'me' ||
                  subDomainName === 'x') &&
                !isAvailableSeason[subDomainName]
              ) {
                if (isAvailableSeason.me) {
                  setSubDomainName('me');
                } else if (isAvailableSeason.w) {
                  setSubDomainName('w');
                } else if (isAvailableSeason.x) {
                  setSubDomainName('x');
                }
              }
            } else {
              if (
                (subDomainName === 'la' ||
                  subDomainName === 'nyc' ||
                  subDomainName === 'sf') &&
                !isAvailableSeason[subDomainName]
              ) {
                if (isAvailableSeason.la) {
                  setSubDomainName('la');
                } else if (isAvailableSeason.nyc) {
                  setSubDomainName('nyc');
                } else if (isAvailableSeason.sf) {
                  setSubDomainName('sf');
                }
              }
            }
          }
        });
    }
  }, [
    debouncedDomainName,
    walletAddress,
    ensDomains,
    domainName,
    stage,
    fetchingName,
    isEns,
    subDomainName,
    fetchingSubDomainName,
    isSeason0,
  ]);

  const handleEnsDomainClick = (ensDomain: any) => {
    const domainName = getDomainNameFromEnsDomain(ensDomain);
    setDomainName(checkValidName(domainName));
  };

  const handleSubDomainName = (subDomainName: string) => {
    if (stage === 0) {
      setSubDomainName(subDomainName);
      setDomainName(checkValidName(domainName));
    }
  };

  // -- END ENS DOMAINS

  // Stage 0 -> 1: Transaction has been submitted
  useEffect(() => {
    if (
      state.status.toLowerCase().includes('mining') &&
      state.transaction &&
      stage === 0
    ) {
      setStage(1);

      // Paid transaction post transaction
      const transactionHash = state.transaction.hash;
      fetch('/api/processTransaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress,
          fullDomainName: subDomainName
            ? `${submittedDomainName}.${subDomainName}`
            : submittedDomainName,
          subDomainName,
          domainName: submittedDomainName,
          email,
          transactionHash,
          isEns,
          universityNanoId,
          ref: searchParams.get('ref'),
        }),
      }).then((result) => result.json());
    }
  }, [
    stage,
    state.status,
    state.transaction,
    walletAddress,
    submittedDomainName,
    email,
    isEns,
    subDomainName,
    searchParams,
    universityNanoId,
  ]);

  // Stage 2 -> 3: After sending Eth, contact backend for registration
  useEffect(() => {
    if (state.status === 'Exception') {
      setError(state.errorMessage);
      const errorParsed = state.errorMessage?.toLowerCase() || '';
      if (errorParsed.includes('insufficient')) {
        setErrorMessage('Unfortunately you have insufficient funds.');
      } else if (errorParsed.includes('user denied')) {
        setErrorMessage('');
      } else {
        setErrorMessage('Exception has occurred.');
      }
      setStage(0);
    } else if (state.status === 'Success') {
      if (isWaiting && state.receipt) {
        setWaiting(false);
        setStage(3);
        // As synchronous waiting isn't necessary after this part, we can push the user to profile page after registration
        setTimeout(() => {
          setStage(4);
        }, 30000);
        // fetch('/api/register', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     ...params,
        //     receipt: state.receipt,
        //     transaction: state.transaction,
        //   }),
        // })
        //   .then((results) => results.json())
        //   .then(({ isSuccess }) => {
        //     // Stage 3 -> 4: After backend registration, complete
        //     if (isSuccess) {
        //       setStage(4);
        //     } else {
        //       setStage(4);
        //       console.log('failure');
        //     }
        //   });
      }
    }
  }, [state, isWaiting, params]);

  useEffect(() => {
    if (stage === 4) {
      if (
        ethBalance &&
        Number.parseFloat(formatEther(ethBalance)) >
          MIN_ETH_BALANCE_FOR_REVERSE_RESOLUTION
      ) {
        setReverseResolutionModalActive(true);
      } else {
        setTimeout(() => {
          const fullDomainName = subDomainName
            ? `${submittedDomainName}.${subDomainName}`
            : submittedDomainName;
          window.location.assign(`https://${fullDomainName}.eth.id`);
        }, 3000);
      }
    }
  }, [stage, navigate, submittedDomainName, subDomainName, ethBalance]);

  const handleReverseResolutionModalClose = () => {
    const fullDomainName = subDomainName
      ? `${submittedDomainName}.${subDomainName}`
      : submittedDomainName;
    window.location.assign(`https://${fullDomainName}.eth.id`);
  };

  // Stage 1 -> 2: Send Eth to Eth.id wallet
  async function onReserveClick() {
    if (domainName && walletAddress) {
      const value = parseEther(String(price?.price));
      setSubmittedDomainName(domainName);
      setParams({
        domainName,
        transactionValue: value.toString(),
        walletAddress,
      });

      if (isUsdFlow) {
        setStage(1);
      } else if (price?.price === 0) {
        // IF FREE DOMAIN, WE SKIP TRANSACTION
        setStage(2);
        fetch('/api/processTransaction', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            walletAddress,
            fullDomainName: subDomainName
              ? `${domainName}.${subDomainName}`
              : domainName,
            subDomainName,
            domainName,
            email,
            isEns,
            isFree: true,
            universityNanoId,
            ref: searchParams.get('ref'),
          }),
        }).then((result) => result.json());
        setTimeout(() => {
          setStage(3);
        }, 5000);
        setTimeout(() => {
          setStage(4);
        }, 25000);
      } else {
        // OTHERWISE WE WAIT UNTIL TRANSACTION MINED THEN USE TRANSACTION SUCCESS TRIGGER

        sendTransaction({
          to: ETH_ID_ADDRESS,
          value,
          data: hexlify(
            toUtf8Bytes(
              subDomainName ? `${domainName}.${subDomainName}` : domainName
            )
          ),
        }).then(() => {
          setWaiting(true);
        });

        // Redirect to deeplink
        const deeplink = primaryWallet?.connector.getDeepLink();
        if (deeplink) {
          window.location.href = deeplink;
        }
      }
    }
  }

  const onUsdFlowSuccess = (paymentIntentId: string) => {
    setStage(2);
    fetch('/api/processTransaction', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress,
        fullDomainName: subDomainName
          ? `${domainName}.${subDomainName}`
          : domainName,
        subDomainName,
        domainName,
        email,
        isEns,
        paymentIntentId,
        universityNanoId,
        ref: searchParams.get('ref'),
      }),
    }).then((result) => result.json());
    setTimeout(() => {
      setStage(3);
    }, 5000);
    setTimeout(() => {
      setStage(4);
    }, 25000);
  };

  const isRegisterDisabled =
    !isAvailable ||
    !isAgreeTerms ||
    isFetching ||
    !price ||
    !checkValidExternalEmail(email);

  const fullDomainName = subDomainName
    ? `${submittedDomainName}.${subDomainName}`
    : submittedDomainName;

  const stripeOptions = useMemo(
    () => ({ clientSecret: paymentIntentClientSecret }),
    [paymentIntentClientSecret]
  );

  return (
    <div
      className="SignupModal"
      onClick={() => {
        if (isDragging) {
          setDragging(false);
        } else {
          if (onClose && stage === 0) {
            onClose();
          }
        }
      }}
    >
      <LazyMotion features={domAnimation}>
        <m.div
          className="SignupModal-frame"
          onClick={(event) => event.stopPropagation()}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          onMouseDown={() => {
            setDragging(true);
          }}
        >
          {stage === 0 ? (
            <>
              <div className="SignupModal-title">Reserve your name</div>
              <div className="SignupModal-content">
                {subDomainName &&
                  !isMit &&
                  (isSeason0 ? (
                    <div className="SignupModal-subDomains">
                      <div className="SignupModal-subDomainContainer">
                        <SignupSubDomainButton
                          subDomainName="w"
                          isSelected={subDomainName === 'w'}
                          isAvailable={isAvailableSeason.w}
                          isFetching={isFetching}
                          onClick={() => handleSubDomainName('w')}
                          isEmpty={domainName.length === 0}
                        />
                      </div>
                      <div className="SignupModal-subDomainContainer">
                        <SignupSubDomainButton
                          subDomainName="me"
                          isSelected={subDomainName === 'me'}
                          isAvailable={isAvailableSeason.me}
                          isFetching={isFetching}
                          onClick={() => handleSubDomainName('me')}
                          isEmpty={domainName.length === 0}
                        />
                      </div>
                      <div className="SignupModal-subDomainContainer">
                        <SignupSubDomainButton
                          subDomainName="x"
                          isSelected={subDomainName === 'x'}
                          isAvailable={isAvailableSeason.x}
                          isFetching={isFetching}
                          onClick={() => handleSubDomainName('x')}
                          isEmpty={domainName.length === 0}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="SignupModal-subDomains">
                      <div className="SignupModal-subDomainContainer">
                        <SignupSubDomainButton
                          subDomainName="sf"
                          isSelected={subDomainName === 'sf'}
                          isAvailable={isAvailableSeason.sf}
                          isFetching={isFetching}
                          onClick={() => handleSubDomainName('sf')}
                          isEmpty={domainName.length === 0}
                        />
                      </div>
                      <div className="SignupModal-subDomainContainer">
                        <SignupSubDomainButton
                          subDomainName="la"
                          isSelected={subDomainName === 'la'}
                          isAvailable={isAvailableSeason.la}
                          isFetching={isFetching}
                          onClick={() => handleSubDomainName('la')}
                          isEmpty={domainName.length === 0}
                        />
                      </div>
                      <div className="SignupModal-subDomainContainer">
                        <SignupSubDomainButton
                          subDomainName="nyc"
                          isSelected={subDomainName === 'nyc'}
                          isAvailable={isAvailableSeason.nyc}
                          isFetching={isFetching}
                          onClick={() => handleSubDomainName('nyc')}
                          isEmpty={domainName.length === 0}
                        />
                      </div>
                    </div>
                  ))}
                <div className="SignupModal-form">
                  {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    subDomainName || !isEnsEnabled ? (
                      <></>
                    ) : (
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      isEnsEnabled && (
                        <>
                          <div className="SignupModal-instructions">
                            Pick one of your ENS domains:
                          </div>
                          <div
                            className={classNames('SignupModal-ens', {
                              'SignupModal-ens--error': domainName === '',
                            })}
                          >
                            {ensDomains.map((ensDomain: any, idx: number) =>
                              ensDomain.isAvailable ? (
                                <div
                                  onClick={() =>
                                    handleEnsDomainClick(ensDomain)
                                  }
                                  key={idx}
                                  className={classNames(
                                    'SignupModal-ensDomain',
                                    {
                                      'SignupModal-ensDomain--selected':
                                        domainName ===
                                        getDomainNameFromEnsDomain(ensDomain),
                                    }
                                  )}
                                  style={{
                                    backgroundImage: `url('${ensDomain?.media[0]?.gateway}')`,
                                  }}
                                />
                              ) : null
                            )}
                          </div>
                          <div className="SignupModal-instructions">
                            Or type any name you want:
                          </div>
                        </>
                      )
                    )
                  }
                  <div className="SignupModal-name">
                    <Suspense fallback={<></>}>
                      <div className="SignupModal-nft">
                        <DomainNftCard
                          subDomainName={subDomainName}
                          fullDomainName={
                            subDomainName
                              ? `${domainName}.${subDomainName}`
                              : domainName
                          }
                        />
                      </div>
                    </Suspense>
                    <input
                      type="string"
                      className={classNames('SignupModal-nameInput', {
                        'SignupModal-nameInput--error':
                          !isAvailable && !isFetching,
                      })}
                      maxLength={16}
                      placeholder={
                        isEns ? 'Click on an ENS name' : 'Type your domain'
                      }
                      onChange={(event) =>
                        setDomainName(checkValidName(event.target.value))
                      }
                      value={domainName}
                    ></input>
                    <div className="SignupModal-nameCaption">
                      {subDomainName ? `.${subDomainName}.eth.id` : `.eth.id`}
                    </div>
                  </div>
                  <div className="SignupModal-email">
                    <div className="SignupModal-emailCaption">Email</div>
                    <input
                      type="email"
                      className={classNames('SignupModal-emailInput', {
                        'SignupModal-emailInput--error':
                          !checkValidExternalEmail(email),
                      })}
                      placeholder={
                        isMit
                          ? 'Reserve with personal email (Not MIT)'
                          : 'Reserve with verification email'
                      }
                      onChange={(event) => setEmail(event.target.value)}
                      value={email}
                    ></input>
                  </div>
                  <div className="SignupModal-terms">
                    <input
                      type="checkbox"
                      onClick={() => setAgreeTerms(!isAgreeTerms)}
                      checked={isAgreeTerms}
                      readOnly
                    ></input>
                    <div
                      className="SignupModal-termsCaption"
                      onClick={() => setAgreeTerms(!isAgreeTerms)}
                    >
                      I have read and agree to the{' '}
                      <a
                        href="/terms"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()}
                      >
                        Terms of Service
                      </a>{' '}
                      and{' '}
                      <a
                        href="/privacy"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()}
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                  <div className="SignupModal-cta">
                    {(price?.price || 0) > WHALE_PRICE_LIMIT ? (
                      <img
                        src={ImageWhale}
                        className="SignupModal-whale"
                        title="That's quite a pick! Select an ENS name for cheaper prices."
                      />
                    ) : (
                      isAvailable &&
                      price?.price === 0 && (
                        <img
                          src={ImageBearCenter}
                          className="SignupModal-bear"
                          title="FREEEEEEEEEEEEEE!"
                        />
                      )
                    )}
                    {domainName.length === 0 || !price ? (
                      <div className="SignupModal-ctaCaption" />
                    ) : price.price === 0 ? (
                      <div className="SignupModal-ctaCaption">
                        {isAvailable ? (
                          <>
                            <div className="SignupModal-adjustment">
                              <div className="SignupModal-discount">{`${Math.round(
                                ((price.originalPrice - price.price) /
                                  price.originalPrice) *
                                  100
                              )}% off`}</div>
                              <div className="SignupModal-originalPrice">
                                {`${price.originalPrice} ETH`}
                              </div>
                            </div>
                            {'FREE'}
                          </>
                        ) : (
                          <div className="SignupModal-error">
                            Sorry, this domain <br />
                            is not available.
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="SignupModal-ctaCaption">
                        {isAvailable ? (
                          isUsdFlow ? (
                            <>
                              <div className="SignupModal-adjustment">
                                <div className="SignupModal-discount">{`${Math.round(
                                  ((price.originalUsd - price.usd) /
                                    price.originalUsd) *
                                    100
                                )}% off`}</div>
                                <div className="SignupModal-originalPrice">
                                  {`$${price.originalUsd}`}
                                </div>
                              </div>
                              <div className="SignupModal-price">
                                <div className="SignupModal-priceUsd">
                                  ${price.usd}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="SignupModal-adjustment">
                                <div className="SignupModal-discount">{`${Math.round(
                                  ((price.originalPrice - price.price) /
                                    price.originalPrice) *
                                    100
                                )}% off`}</div>
                                <div className="SignupModal-originalPrice">
                                  {`${price.originalPrice} ETH`}
                                </div>
                              </div>
                              <div className="SignupModal-price">
                                <div className="SignupModal-priceEth">
                                  {price.price}
                                  <div className="SignupModal-ctaUnit">ETH</div>
                                </div>
                              </div>
                            </>
                          )
                        ) : (
                          <div className="SignupModal-error">
                            Sorry, this domain <br />
                            is not available.
                          </div>
                        )}
                      </div>
                    )}
                    <Button
                      className="SignupModal-ctaButton"
                      onClick={onReserveClick}
                      size="large"
                      disabled={isRegisterDisabled}
                      title={
                        isRegisterDisabled
                          ? 'Please read and agree to the Terms of Service and Privacy Policy'
                          : 'Reserve'
                      }
                    >
                      Reserve
                    </Button>
                  </div>
                </div>
                <div className="SignupModal-error">{errorMessage}</div>
                <div className="SignupModal-tagline">
                  {subDomainName === 'mit' ? (
                    <>Mint once, yours forever. &nbsp;Zero renewals.</>
                  ) : (
                    <>
                      Pay once, yours forever. &nbsp;Zero renewals. <br />
                    </>
                  )}
                  {/*isX || isWLink ? (
                    
                  ) : (
                    <>
                      {subDomainName ? (
                        <>
                          30 seconds to your website.
                          <br />
                        </>
                      ) : (
                        <>
                          Pay once, yours forever. &nbsp;Zero renewals.
                          <br />
                        </>
                      )}
                      Your Web 3 legend begins here.
                      <br />
                    </>
                      )*/}
                  {debugState}
                </div>
              </div>
            </>
          ) : isUsdFlow && stage === 1 ? (
            <Elements stripe={stripePromise} options={stripeOptions}>
              <SignupModalUsdFlow
                fullDomainName={fullDomainName}
                onBack={() => {
                  setStage(0);
                }}
                onSuccess={onUsdFlowSuccess}
                price={price}
                paymentIntentClientSecret={paymentIntentClientSecret}
              />
            </Elements>
          ) : (
            <>
              <div className="SignupModal-title">
                {stage < 4 ? 'Processing your registration...' : 'Success!'}
              </div>
              <div className="SignupModal-content">
                {
                  /*isWLink && stage === 4 ? (
                  <>
                    <div
                      className="SignupModal-metamask"
                      style={{ backgroundImage: `url(${ImageEns})` }}
                    >
                      <div className="SignupModal-metamaskDomain">
                        {`${domainName}.${subDomainName}.eth.id`}
                      </div>
                    </div>
                    <div className="SignupModal-tagline">
                      {`Try out your new domain by clicking `} <br />
                      {`"Send" on Metamask then typing in your domain.`}
                    </div>
                  </>
                ) : */ <>
                    <Spinner />
                    {price?.price !== 0 && (
                      <div className="SignupModal-step">
                        <div>
                          {stage === 1
                            ? '\u23F0'
                            : stage < 1
                            ? '\u23F2'
                            : '\u2705'}
                        </div>
                        <span
                          className={
                            stage === 1 ? 'SignupModal-step--active' : ''
                          }
                        >
                          {'Waiting for your transaction to be mined.'}
                        </span>
                      </div>
                    )}
                    <div className="SignupModal-step">
                      <div>
                        {stage === 2
                          ? '\u23F0'
                          : stage < 2
                          ? '\u23F2'
                          : '\u2705'}
                      </div>
                      <span
                        className={
                          stage === 2 ? 'SignupModal-step--active' : ''
                        }
                      >
                        {'Processing your registration.'}
                      </span>
                    </div>

                    <div className="SignupModal-step">
                      <div>
                        {stage === 3
                          ? '\u23F0'
                          : stage < 3
                          ? '\u23F2'
                          : '\u2705'}
                      </div>
                      <span
                        className={
                          stage === 3 ? 'SignupModal-step--active' : ''
                        }
                      >
                        {'Creating your domain.'}
                      </span>
                    </div>

                    <div className="SignupModal-step">
                      <div>
                        {stage === 3
                          ? '\u23F0'
                          : stage < 3
                          ? '\u23F2'
                          : '\u2705'}
                      </div>
                      <span
                        className={
                          stage === 3 ? 'SignupModal-step--active' : ''
                        }
                      >
                        {'Synchronizing with the Ethereum Name Service.'}
                      </span>
                    </div>
                    <div className="SignupModal-step">
                      <div>{stage < 4 ? '\u23F2' : '\u2705'}</div>
                      <span
                        className={
                          stage === 4 ? 'SignupModal-step--active' : ''
                        }
                      >
                        {'All complete!'}
                      </span>
                    </div>
                    <div className="SignupModal-tagline">
                      {errorMessage ? (
                        <div className="SignupModal-error">{errorMessage}</div>
                      ) : stage < 4 ? (
                        'Please do not close this tab.'
                      ) : (
                        'You will be redirected to your new page!'
                      )}
                    </div>
                  </>
                }
              </div>
            </>
          )}
        </m.div>
      </LazyMotion>

      {isReverseResolutionModalActive && (
        <ReverseResolutionModal
          walletAddress={walletAddress}
          onClose={handleReverseResolutionModalClose}
          onComplete={handleReverseResolutionModalClose}
          latestFullDomainName={
            subDomainName ? `${domainName}.${subDomainName}` : domainName
          }
          isBackgroundTransparent
        />
      )}
    </div>
  );
}
