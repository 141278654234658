import Footer from 'client/components/Footer';
import Topbar from 'client/components/Topbar';
import css from 'client/css/Legal.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { lazy, Suspense } from 'react';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
};

export default function Privacy({ appContext }: Props): JSX.Element {
  useStyles(css);
  const PrivacyContent = lazy(() => import('client/components/PrivacyContent'));
  return (
    <div className="Legal">
      <Topbar appContext={appContext} />
      <Suspense fallback={<div className="Legal-content" />}>
        <PrivacyContent />
      </Suspense>
      <Footer />
    </div>
  );
}
