import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';
import BaseModal from 'client/components/BaseModal';
import Button from 'client/components/Button';
import DomainNftCard from 'client/components/DomainNftCard';
import Link from 'client/components/Link';
import Spinner from 'client/components/Spinner';
import css from 'client/css/DomainExportModal.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useRef, useState } from 'react';

type Props = {
  walletAddress: string;
  onClose?: () => void;
  onComplete: () => void;
  isBackgroundTransparent?: boolean;
  latestFullDomainName?: string;
};

const DOMAIN_NFT_EXPORT_CONTRACT_ADDRESS =
  '0xd0d8c258e173c40647bea8d36d670a46cad58830';

const DOMAIN_NFT_EXPORT_CONTRACT_INTERFACE = [
  {
    inputs: [
      { internalType: 'address', name: '_collection', type: 'address' },
      {
        internalType: 'address',
        name: '_publicResolver',
        type: 'address',
      },
      { internalType: 'address', name: '_ens', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'node',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'NameMigrated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'node',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'OwnershipReturned',
    type: 'event',
  },
  {
    inputs: [],
    name: 'eip712Domain',
    outputs: [
      { internalType: 'bytes1', name: 'fields', type: 'bytes1' },
      { internalType: 'string', name: 'name', type: 'string' },
      { internalType: 'string', name: 'version', type: 'string' },
      { internalType: 'uint256', name: 'chainId', type: 'uint256' },
      {
        internalType: 'address',
        name: 'verifyingContract',
        type: 'address',
      },
      { internalType: 'bytes32', name: 'salt', type: 'bytes32' },
      { internalType: 'uint256[]', name: 'extensions', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'rootNode', type: 'bytes32' },
          { internalType: 'bytes32', name: 'label', type: 'bytes32' },
          { internalType: 'bytes32', name: 'namehash', type: 'bytes32' },
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'string', name: 'uri', type: 'string' },
        ],
        internalType: 'struct NFTMinter.ENSName',
        name: 'ensName',
        type: 'tuple',
      },
    ],
    name: 'hashStruct',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'migratedNames',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'node', type: 'bytes32' }],
    name: 'reallowENSNameMigration',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'bytes32', name: 'rootNode', type: 'bytes32' },
          { internalType: 'bytes32', name: 'label', type: 'bytes32' },
          { internalType: 'bytes32', name: 'namehash', type: 'bytes32' },
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'string', name: 'uri', type: 'string' },
        ],
        internalType: 'struct NFTMinter.ENSName',
        name: 'ensNameStruct',
        type: 'tuple',
      },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
    ],
    name: 'registerENSNameOnChain',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'node', type: 'bytes32' },
      { internalType: 'address', name: 'recipient', type: 'address' },
    ],
    name: 'returnENSNameToAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
];

export default function DomainExportModal({
  onClose,
  onComplete,
  walletAddress,
  isBackgroundTransparent,
}: Props): JSX.Element {
  useStyles(css);

  const contract = new Contract(
    DOMAIN_NFT_EXPORT_CONTRACT_ADDRESS,
    DOMAIN_NFT_EXPORT_CONTRACT_INTERFACE
  ) as any;

  const [domains, setDomains] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isProcessing, setProcessing] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [domain, setDomain] = useState<any>();
  const [tokenId, setTokenId] = useState<number>();

  const reportedTransaction = useRef<boolean>(false);
  const fetchedTokenId = useRef<boolean>(false);

  const { state, send: sendTransaction } = useContractFunction(
    contract,
    'registerENSNameOnChain',
    {
      transactionName: 'registerENSNameOnChain',
    }
  );

  useEffect(() => {
    fetch('/api/getEligibleDomainNftExport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.domains) {
          setDomains(result.domains);
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (state.status === 'Mining' && state.transaction) {
      const { hash: transactionHash } = state.transaction;

      if (!reportedTransaction.current) {
        reportedTransaction.current = true;
        fetch('/api/reportDomainNftTransaction', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transactionHash,
            fullDomainName: domain.fullDomainName,
          }),
        })
          .then((result) => result.json())
          .then((result) => {
            if (!tokenId && result.nftTokenId) {
              setTokenId(result.nftTokenId);
              setProcessing(false);
              setComplete(true);
            }
          });
      }
    } else if (state.status === 'Success') {
      setTimeout(() => {
        setProcessing(false);
        setComplete(true);
      }, 12000);

      // Failsafe for request timing out
      if (!fetchedTokenId.current) {
        fetchedTokenId.current = true;
        fetch('/api/getDomainNftTokenId', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fullDomainName: domain.fullDomainName,
          }),
        })
          .then((result) => result.json())
          .then((result) => {
            if (!tokenId) {
              setTokenId(result.nftTokenId);
            }
          });
      }
    } else if (state.status === 'Exception') {
      setProcessing(false);
      reportedTransaction.current = false;
      fetchedTokenId.current = false;
    }
  }, [state.status, state.transaction, onComplete, domain, tokenId]);

  const onDomainSelect = (domain: any) => {
    setProcessing(true);
    setDomain(domain);
    const {
      fullDomainName,
      subDomain: { subDomainName },
      domainName,
    } = domain;

    fetch('/api/exportDomainNft', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fullDomainName,
        subDomainName,
        domainName,
        walletAddress,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result) {
          const { rootNode, label, namehash, owner, signature, uri } =
            result || {};
          sendTransaction(
            {
              rootNode,
              label,
              namehash,
              owner,
              uri,
            },
            signature
          ).catch(() => {
            setProcessing(false);
            reportedTransaction.current = false;
            fetchedTokenId.current = false;
          });
        }
      })
      .catch(() => {
        setProcessing(false);
        reportedTransaction.current = false;
        fetchedTokenId.current = false;
      });
  };

  return (
    <BaseModal
      title={'Select eligible domain to export'}
      onClose={onClose}
      isBackgroundTransparent={isBackgroundTransparent}
    >
      <div className="DomainExportModal">
        {isComplete ? (
          <div className="DomainExportModal-loader">
            <div className="DomainExportModal-loaderNft">
              {domain && (
                <a
                  href={
                    tokenId
                      ? `https://opensea.io/assets/ethereum/0xea33a18755b93a5b598a01a70685e26c4484da19/${tokenId}`
                      : `https://opensea.io/assets/ethereum/0xea33a18755b93a5b598a01a70685e26c4484da19`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <DomainNftCard
                    subDomainName={domain.subDomain?.subDomainName}
                    fullDomainName={domain.fullDomainName}
                  />
                </a>
              )}
            </div>
            <div className="DomainExportModal-loaderCaption">
              Congratulations on your new NFT!
            </div>
            <div className="DomainExportModal-loaderFooter">
              <a
                href={
                  tokenId
                    ? `https://opensea.io/assets/ethereum/0xea33a18755b93a5b598a01a70685e26c4484da19/${tokenId}`
                    : `https://opensea.io/assets/ethereum/0xea33a18755b93a5b598a01a70685e26c4484da19`
                }
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className="DomainExportModal-button"
                  size="large"
                  onClick={() => {
                    setTimeout(onComplete, 500);
                  }}
                  title="View on OpenSea"
                >
                  View on OpenSea
                </Button>
              </a>
            </div>
          </div>
        ) : isProcessing ? (
          <div className="DomainExportModal-loader">
            <Spinner />
            <div className="DomainExportModal-loaderCaption">
              {state.status === 'Mining'
                ? 'Waiting for transaction to be mined'
                : 'Please approve transaction in wallet'}
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className="DomainExportModal-label">
                Destination wallet that will receive NFT
              </div>
              <div className="DomainExportModal-current">{walletAddress}</div>
            </div>
            <div>
              <div className="DomainExportModal-label">
                Select an eligible Eth.id domain to export as a tradable NFT.
              </div>
              <div className="DomainExportModal-items">
                {isLoading && <Spinner isCenter />}
                {!isLoading &&
                  domains.map((domain: any, idx: number) => (
                    <div key={idx}>
                      <div
                        className="DomainExportModal-item"
                        onClick={() => {
                          onDomainSelect(domain);
                        }}
                      >
                        {`${domain.fullDomainName}`}
                      </div>
                      <div className="DomainExportModal-divider"></div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="DomainExportModal-footer">
              <div className="DomainExportModal-message">
                If you have issues, please access the
                <br />
                <div>
                  <Link to={`https://discord.gg/pDc6Q5Jwjx`} external newTab>
                    Eth.id Discord
                  </Link>
                  {' for support.'}
                </div>
              </div>

              <Button
                className="DomainExportModal-button"
                onClick={onComplete}
                size="large"
                title="Skip"
              >
                Done
              </Button>
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
}
