import { Module } from '@prisma/client';
import classNames from 'classnames';
import ImageSelectModal from 'client/components/ImageSelectModal';
import Link from 'client/components/Link';
import ModuleCard from 'client/components/ModuleCard';
import css from 'client/css/NftCard.scss';
import { BigNumber } from 'ethers';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useState } from 'react';

type Props = {
  module: Partial<Module>;
  entityId: number | undefined;
  isOwner: boolean;
  dragHandleProps: any;
  onModuleUpdate: (moduleId: number, data: Partial<Module>) => void;
  onModuleDelete: (moduleId: number) => void;
};

export default function NftCard({
  module,
  entityId,
  isOwner,
  dragHandleProps,
  onModuleUpdate,
  onModuleDelete,
}: Props): JSX.Element {
  useStyles(css);

  const [isEdit, setEdit] = useState(false);
  const [title, setTitle] = useState(JSON.parse(module.a || '{}'));
  const [nft, setNft] = useState<any>(JSON.parse(module.b || '{}'));

  const [isImageSelectModalVisible, setImageSelectModalVisible] =
    useState(false);

  const handleImageSelectModalClose = () => {
    setImageSelectModalVisible(false);
  };

  const contractAddress = nft?.contract?.address || '';
  const tokenId = nft?.id ? BigNumber.from(nft.id.tokenId).toString() : '';

  const onChangeTitle = (title: string) => {
    setTitle({ title });
  };

  // todo: Add NFT typing
  const onChangeNft = (nft: any) => {
    setNft(nft);
    setImageSelectModalVisible(false);
    onChangeTitle(nft.title);
  };

  const onSubmit = () => {
    setEdit(false);
    if (module.id) {
      onModuleUpdate(module.id, {
        a: JSON.stringify(title),
        b: JSON.stringify(nft),
      });
    }
  };

  // todo: add submission spinner
  return (
    <ModuleCard
      title={title.title || 'NFT Showcase'}
      moduleId={module.id}
      isOwner={isOwner}
      dragHandleProps={dragHandleProps}
      onModuleDelete={onModuleDelete}
      isEdit={isEdit}
      onEdit={() => {
        if (!nft.id) {
          setImageSelectModalVisible(true);
        }
        setEdit(true);
      }}
      onCancel={() => {
        setEdit(false);
      }}
    >
      {!isEdit && (
        <Link
          external
          newTab
          to={`https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`}
        >
          <img className="NftCard-image" src={nft.url} />
        </Link>
      )}

      {isEdit && (
        <div className="ModuleCard-rows">
          <div className="ModuleCard-row">
            {'Title: '}
            <input
              className="ModuleCard-input"
              value={title.title}
              onChange={(event) => onChangeTitle(event.target.value)}
            ></input>
          </div>
          <div className="ModuleCard-row">
            {'NFT: '}
            <div
              className={classNames('Profile-nft', 'NftCard-thumbnail')}
              style={{ backgroundImage: `url(${nft.url})` }}
              onClick={() => {
                setImageSelectModalVisible(true);
              }}
            />
          </div>
        </div>
      )}

      {isEdit && (
        <div className="Card-footer">
          <div className="Card-footerButton" onClick={onSubmit}>
            Submit
          </div>
        </div>
      )}

      {isImageSelectModalVisible && (
        <ImageSelectModal
          onClose={handleImageSelectModalClose}
          title="Owned NFT's"
          onNftSelect={onChangeNft}
          entityId={entityId}
        />
      )}
    </ModuleCard>
  );
}
