import { Module } from '@prisma/client';
import ImageSelectModal from 'client/components/ImageSelectModal';
import Link from 'client/components/Link';
import ModuleCard from 'client/components/ModuleCard';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';

type Props = {
  module: Partial<Module>;
  isOwner: boolean;
  entityId: number | undefined;
  dragHandleProps: any;
  onModuleDelete: (moduleId: number) => void;
};

export default function NftsCard({
  module,
  entityId,
  isOwner,
  dragHandleProps,
  onModuleDelete,
}: Props): JSX.Element {
  const [nfts, setNfts] = useState<any[]>([]);
  const [isImageSelectModalVisible, setImageSelectModalVisible] =
    useState(false);

  const handleImageSelectModalClose = () => {
    setImageSelectModalVisible(false);
  };

  useEffect(() => {
    if (entityId) {
      fetch('/api/getNfts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entityId,
        }),
      })
        .then((result) => result.json())
        .then((result) => {
          setNfts(result.nfts || []);
        });
    }
  }, [entityId]);

  const onViewAll = () => {
    setImageSelectModalVisible(true);
  };

  return (
    <ModuleCard
      title="Owned NFT's"
      moduleId={module.id}
      isOwner={isOwner}
      dragHandleProps={dragHandleProps}
      onModuleDelete={onModuleDelete}
      hideEdit={true}
    >
      <div className="Profile-nfts">
        {nfts
          .filter((nft) => nft.media[0] && nft.media[0].gateway)
          .slice(0, 9)
          .map((nft, i) => {
            const contractAddress = nft.contract.address;
            const tokenId = BigNumber.from(nft.id.tokenId).toString();
            return (
              <Link
                key={i}
                external
                newTab
                to={`https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`}
              >
                <div
                  className="Profile-nft"
                  style={{ backgroundImage: `url(${nft.media[0].gateway})` }}
                />
              </Link>
            );
          })}
      </div>
      <div className="Card-footer">
        <div className="Card-footerButton" onClick={onViewAll}>
          View all NFT&apos;s
        </div>
      </div>

      {isImageSelectModalVisible && (
        <ImageSelectModal
          onClose={handleImageSelectModalClose}
          title="Owned NFT's"
          entityId={entityId}
          readOnly={true}
        />
      )}
    </ModuleCard>
  );
}
