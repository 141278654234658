import { sanitizeUrl } from '@braintree/sanitize-url';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  children: ReactNode;
  to: string;
  external?: boolean;
  newTab?: boolean;
};

export default function Link({
  to,
  children,
  external,
  newTab,
}: Props): JSX.Element {
  const navigate = useNavigate();
  return (
    <a
      href={sanitizeUrl(to)}
      onClick={(event) => {
        if (!external) {
          event.preventDefault();
          navigate(to);
        }
      }}
      target={newTab ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {children}
    </a>
  );
}
