import BaseModal from 'client/components/BaseModal';
import Link from 'client/components/Link';
import css from 'client/css/ResellerModal.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

type Props = {
  onClose?: () => void;
  walletAddress: string | undefined;
};

export default function ResellerModal({
  onClose,
  walletAddress,
}: Props): JSX.Element {
  useStyles(css);
  return (
    <BaseModal title={'Welcome to Eth.id'} onClose={onClose}>
      <div className="ResellerModal">
        <div>
          {'1. Access the '}
          <Link to={`https://t.me/ethdotid`} external newTab>
            {'Eth.id Telegram group here '}
          </Link>
          or
          <Link to={`https://discord.gg/pDc6Q5Jwjx`} external newTab>
            {' Eth.id Discord here'}
          </Link>
          {' for support.'}
        </div>
        <div>{'2. Eth.id NFT has been minted to your wallet.'}</div>
        <div>
          {'3. It will appear on OpenSea after you unhide it using '}
          <Link
            to="https://support.opensea.io/hc/en-us/articles/5095272825235-What-NFTs-are-in-the-hidden-tab-in-my-profile-#:~:text=Viewing%20your%20hidden%20NFTs%20on%20OpenSea&text=If%20you'd%20like%20to,select%20multiple%20items%20to%20Unhide."
            external
            newTab
          >
            these instructions.
          </Link>
        </div>
        <div>
          {'4. You can access your OpenSea '}
          <Link to={`https://opensea.io/${walletAddress}`} external newTab>
            here.
          </Link>
        </div>
        <div className="ResellerModal-message">
          👋 It&apos;s great to have you here! 👋
        </div>
      </div>
    </BaseModal>
  );
}
