import { Module } from '@prisma/client';
import ModuleCard from 'client/components/ModuleCard';
import css from 'client/css/DevIFrameCard.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useRef, useState } from 'react';

type Props = {
  module: Partial<Module>;
  isOwner: boolean;
  dragHandleProps: any;
  onModuleUpdate: (moduleId: number, data: Partial<Module>) => void;
  onModuleDelete: (moduleId: number) => void;
};

const DEFAULT_URL =
  'https://codesandbox.io/embed/silly-wildflower-vxktcz?fontsize=14&hidenavigation=1&theme=dark';
const MAX_HEIGHT = 1280;

export default function DevIFrameCard({
  module,
  isOwner,
  dragHandleProps,
  onModuleUpdate,
  onModuleDelete,
}: Props): JSX.Element {
  useStyles(css);

  const [isEdit, setEdit] = useState(false);
  const [props, setProps] = useState(JSON.parse(module.a || '{}'));
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const onChangeTitle = (title: string) => {
    setProps({ ...props, title });
  };

  const onChangeUrl = (url: string) => {
    setProps({ ...props, url });
  };

  const onChangeHeight = (height: string) => {
    setProps({
      ...props,
      height: Math.min(height ? parseInt(height) : 480, MAX_HEIGHT),
    });
  };

  const onSubmit = () => {
    setEdit(false);
    if (module.id) {
      onModuleUpdate(module.id, {
        a: JSON.stringify(props),
      });
    }
  };

  // todo: add submission spinner
  return (
    <ModuleCard
      title={props.title || 'Developer IFrame'}
      moduleId={module.id}
      isOwner={isOwner}
      dragHandleProps={dragHandleProps}
      onModuleDelete={onModuleDelete}
      isEdit={isEdit}
      onEdit={() => {
        setEdit(true);
      }}
      onCancel={() => {
        setEdit(false);
      }}
    >
      {!isEdit && (
        <iframe
          width="480"
          height={props.height || 480}
          src={props.url || DEFAULT_URL}
          title="Developer IFrame"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          ref={iFrameRef}
        ></iframe>
      )}
      {isEdit && (
        <div className="ModuleCard-rows">
          <div className="ModuleCard-row">
            {'Title: '}
            <input
              className="ModuleCard-input"
              value={props.title}
              onChange={(event) => onChangeTitle(event.target.value)}
            ></input>
          </div>
          <div className="ModuleCard-row">
            {'URL: '}
            <input
              className="ModuleCard-input"
              value={props.url}
              onChange={(event) => onChangeUrl(event.target.value)}
            ></input>
          </div>
          <div className="ModuleCard-row">
            {'Frame Height: '}
            <input
              className="ModuleCard-input"
              value={props.height}
              onChange={(event) => onChangeHeight(event.target.value)}
            ></input>
          </div>
        </div>
      )}

      {isEdit && (
        <div className="Card-footer">
          <div className="Card-footerButton" onClick={onSubmit}>
            Submit
          </div>
        </div>
      )}
    </ModuleCard>
  );
}

/** Parent
 useEffect(() => {
    // if (iFrameRef.current?.contentWindow) {
    //   iFrameRef.current.contentWindow.postMessage('hello', '*');
    // }
  }, [iFrameRef]);

  useEffect(() => {
    window.addEventListener('message', function (event) {
      const data = event.data;
      if (data?.target === 'ethid-developer-parent') {
        console.log('parent message received data:  ', event.data);
        console.log('origin', event.origin);
        if (event.source) {
          event.source.postMessage({
            target: 'ethid-developer-iframe',
            data: { name: 'hello', age: 123 },
          });
        }
      }

      if (event.source) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        //event.source.postMessage('holla backkk', event.origin);
      }
    });
  }, []);
 */

/** Child
   // useEffect(() => {
  //   // window.addEventListener('message', function (event) {
  //   //   console.log('origin is: ', event.origin);
  //   //   console.log('message received:  ' + event.data, event);
  //   //   if (event.source) {
  //   //     event.source.postMessage('holla back youngin!', event.origin);
  //   //   }
  //   // });

  //   window.addEventListener('message', function (event) {
  //     const data = event.data;
  //     if (data?.target === 'ethid-developer-iframe') {
  //       console.log('child message received data:  ', event.data);
  //       console.log('origin', event.origin);
  //     }
  //   });
  //   window.parent.postMessage(
  //     { target: 'ethid-developer-parent', data: { method: 'requestUser' } },
  //     '*'
  //   );
  // }, []);
 */
