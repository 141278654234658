import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ImageStaffSticker from 'assets/images/staff-sticker.png';
import classNames from 'classnames';
import AtelierNftUsdFlow from 'client/components/AtelierNftUsdFlow';
import Spinner from 'client/components/Spinner';
import css from 'client/css/AtelierNft.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useMemo, useState } from 'react';
import Confetti from 'react-confetti';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
};

const stripePromise = loadStripe(
  //'pk_test_51L8QrcF0TqqCiC4c913lIWtH1Dd3L45sVzC8QBCt01zqJ3aGIzEaeN7iowfo0jjNUglYdsFd5ATZc61RtXYNxuol00EPTDWDhK'
  'pk_live_51L8QrcF0TqqCiC4cFMruCO0xWUecsTKYpUifHzkFoHtjzFn3kyGgQXMOdsYKMqduoIjlj6LMdyMIGBHXJL07PIHi00UPTtnRPG'
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function AtelierNft({ appContext }: Props): JSX.Element {
  useStyles(css);

  const [atelierNft, setAtelierNft] = useState<any>();
  const {
    id,
    nanoId,
    // entity,
    title,
    nftImageUrl,
    price,
    physicalPrice,
    // caption,
    // limit,
    // count,
  } = atelierNft || {};

  const [isSuccess, setSuccess] = useState(false);
  const [isPhysical, setPhysical] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState<string>();

  const stripeOptions = useMemo(
    () => ({ clientSecret: paymentIntentClientSecret }),
    [paymentIntentClientSecret]
  );

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    const nanoId = pathname[pathname.length - 1];

    fetch('/api/getAtelierNft', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nanoId,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.atelierNft) {
          setAtelierNft(result.atelierNft);
        }
      });
  }, []);

  const handleIntent = () => {
    // is not fetching

    if (!isFetching) {
      setFetching(true);
      fetch('/api/atelierNftIntent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nanoId,
        }),
      })
        .then((result) => result.json())
        .then((result) => {
          const { paymentIntentClientSecret } = result;
          setPaymentIntentClientSecret(paymentIntentClientSecret);
        })
        .finally(() => {
          setFetching(false);
        });
    }
  };

  const handleCancel = () => {
    setPaymentIntentClientSecret(undefined);
  };

  const handleSuccess = (paymentIntentId: string, email: string) => {
    fetch('/api/atelierNftTransaction', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        atelierNftId: id,
        paymentIntentId,
        nanoId,
        email,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        const { paymentIntentClientSecret } = result;
        setPaymentIntentClientSecret(paymentIntentClientSecret);
      })
      .finally(() => {
        setFetching(false);
      });

    setSuccess(true);
  };

  return (
    <div
      className={classNames('AtelierNft', {
        'AtelierNft--payment': paymentIntentClientSecret,
      })}
    >
      <div className="AtelierNft-content">
        {isPhysical ? (
          <div className="AtelierNft-column">
            <div
              className="AtelierNft-cancelButton"
              onClick={() => setPhysical(false)}
            >
              Back
            </div>
            <div className="AtelierNft-staff">
              <img className="AtelierNft-staffImage" src={nftImageUrl}></img>
              <img
                className="AtelierNft-staffSticker"
                src={ImageStaffSticker}
              ></img>
            </div>
            <div className="AtelierNft-congrats">
              {`Please locate a staff member with the "Eth.id" sticker who will be able to direct you to the artist of ${title}.`}
              <br /> <br />
              {`We encourage you to engage directly with the artist who is present at the event today!`}
            </div>
          </div>
        ) : isSuccess ? (
          <div className="AtelierNft-column">
            <Confetti width={2000} height={1024} />
            <img className="AtelierNft-image" src={nftImageUrl}></img>
            <div className="AtelierNft-congrats">
              Congratulations on your purchase! <br /> <br /> We will be
              reaching out within the next 24 hours with instructions to claim.
            </div>
          </div>
        ) : paymentIntentClientSecret ? (
          <div className="AtelierNft-column">
            <div className="AtelierNft-cancelButton" onClick={handleCancel}>
              Cancel
            </div>
            <div className="AtelierNft-checkout">
              <div className="AtelierNft-checkoutDescription">
                <img
                  className="AtelierNft-checkoutImage"
                  src={nftImageUrl}
                ></img>
                <div className="AtelierNft-checkoutColumn">
                  <div className="AtelierNft-checkoutTitle">{title}</div>
                  <div className="AtelierNft-checkoutEdition">
                    Digital Edition
                  </div>
                  <div className="AtelierNft-checkoutPrice">${price}</div>
                </div>
              </div>
              <Elements stripe={stripePromise} options={stripeOptions}>
                <AtelierNftUsdFlow
                  paymentIntentClientSecret={paymentIntentClientSecret}
                  onSuccess={handleSuccess}
                  onBack={() => {}}
                />
              </Elements>
            </div>
          </div>
        ) : (
          <div className="AtelierNft-column">
            <img className="AtelierNft-image" src={nftImageUrl}></img>
            {isFetching ? (
              <div className="AtelierNft-loader">
                <Spinner isCenter />
              </div>
            ) : (
              <div className="AtelierNft-buttons">
                <div className="AtelierNft-button" onClick={handleIntent}>
                  <div className="AtelierNft-label">Buy digital edition</div>
                  <div className="AtelierNft-price">${price}</div>
                </div>
                <div
                  className="AtelierNft-button"
                  onClick={() => setPhysical(true)}
                >
                  <div className="AtelierNft-label">Buy physical edition</div>
                  <div className="AtelierNft-price">${physicalPrice}</div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
