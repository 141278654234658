import Footer from 'client/components/Footer';
import Link from 'client/components/Link';
import Topbar from 'client/components/Topbar';
import css from 'client/css/Essays.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
};

export default function Support({ appContext }: Props): JSX.Element {
  useStyles(css);

  return (
    <div className="Essays">
      <Topbar appContext={appContext} />
      <div className="Essays-content">
        <h3>Support</h3>
        <p>
          If you run into any difficulties, please join our following support
          options:
        </p>
        <p>
          <Link to={`https://t.me/ethdotid`} external>
            Telegram
          </Link>
          <br />
          <Link to={`https://discord.gg/pDc6Q5Jwjx`} external>
            Discord
          </Link>
          <br />
          <Link to={`https://twitter.com/ethdotid`} external>
            Twitter
          </Link>
          <br />
          <Link to={`mailto:j+support@eth.id`} external>
            Email
          </Link>
        </p>
        <p>
          As well as you can click on the blue chat icon in the lower right
          corner for the fastest option.
        </p>
      </div>
      <Footer />
    </div>
  );
}
