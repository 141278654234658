import Card from 'client/components/Card';
import CardButton from 'client/components/CardButton';
import css from 'client/css/ModuleCard.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ReactNode } from 'react';

type Props = {
  moduleId: number | undefined;
  isOwner: boolean;
  dragHandleProps?: any;
  onModuleDelete?: (moduleId: number) => void;
  children: ReactNode;
  title: string;
  hideEdit?: boolean;
  isEdit?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
};

export default function ModuleCard({
  moduleId,
  isOwner,
  dragHandleProps = {},
  onModuleDelete,
  children,
  title,
  hideEdit,
  isEdit,
  onEdit,
  onCancel,
}: Props): JSX.Element {
  useStyles(css);

  return (
    <Card
      title={title}
      padding={false}
      button={
        isOwner && (
          <div className="Card-titleButtons">
            <CardButton
              title="Delete"
              onClick={() =>
                moduleId && onModuleDelete && onModuleDelete(moduleId)
              }
            >
              {'🗑'}
            </CardButton>
            {!hideEdit && (
              <CardButton
                title="Edit"
                onClick={() => {
                  if (isEdit) {
                    if (onCancel) {
                      onCancel();
                    }
                  } else {
                    if (onEdit) {
                      onEdit();
                    }
                  }
                }}
              >
                {isEdit ? 'Cancel' : 'Edit'}
              </CardButton>
            )}
          </div>
        )
      }
      isDraggable={isOwner}
      className="ModuleCard"
      dragHandleProps={dragHandleProps}
    >
      {children}
    </Card>
  );
}
