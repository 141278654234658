import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import Button from 'client/components/Button';
import Spinner from 'client/components/Spinner';
import { useEffect, useRef, useState } from 'react';

type Props = {
  fullDomainName: string | undefined;
  price: {
    price: number;
    originalPrice: number;
    usd: number;
    originalUsd: number;
  };
  paymentIntentClientSecret: string | undefined;
  onBack: () => void;
  onSuccess: (paymentIntentId: string) => void;
};

export default function SignupModalUsdFlow({
  paymentIntentClientSecret,
  fullDomainName,
  price,
  onBack,
  onSuccess,
}: Props) {
  const isSubmitDisabled = false;

  const stripe = useStripe();
  const elements = useElements();
  const isSuccess = useRef(false);
  const [isWaiting, setWaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmitClick = async () => {
    if (!stripe || !elements) {
      return;
    }

    setWaiting(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://eth.id/order/123/complete',
      },
      redirect: 'if_required',
    });

    if (error?.message) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (!stripe || !paymentIntentClientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(paymentIntentClientSecret)
      .then(({ paymentIntent = {} }) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            console.log('Success! Payment received.');
            if (!isSuccess.current && paymentIntent.id) {
              isSuccess.current = true;
              onSuccess(paymentIntent.id);
            }
            break;

          case 'processing':
            console.log(
              "Payment processing. We'll update you when payment is received."
            );
            break;

          case 'requires_payment_method':
            console.log('Payment failed. Please try another payment method.');
            setWaiting(false);
            break;

          default:
            console.log('Something went wrong.');
            break;
        }
      });
  }, [onSuccess, paymentIntentClientSecret, stripe]);

  return (
    <>
      <div className="SignupModal-title">
        <div className="SignupModalUsdFlow-header">
          <div className="SignupModalUsdFlow-headerDomain">
            {fullDomainName}.eth.id
          </div>
          <div className="SignupModalUsdFlow-headerPrice">${price.usd}</div>
        </div>
      </div>
      <div className="SignupModal-content">
        <div className="SignupModalUsdFlow">
          {errorMessage && (
            <div className="SignupModal-error">{errorMessage}</div>
          )}

          {isWaiting && (
            <div className="SignupModalUsdFlow-waiting">
              <Spinner isCenter={true} />
              <div>Processing payment...</div>
            </div>
          )}

          {paymentIntentClientSecret ? (
            <PaymentElement />
          ) : (
            <div>Error with loading payments...</div>
          )}

          {!isWaiting && stripe && (
            <div className="SignupModalUsdFlow-buttons">
              <Button
                className="SignupModalUsdFlow-backButton"
                onClick={onBack}
                size="large"
                title="Go back to change domain"
              >
                Back
              </Button>
              <Button
                className="SignupModalUsdFlow-ctaButton"
                onClick={onSubmitClick}
                size="large"
                disabled={isSubmitDisabled}
                title={
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  isSubmitDisabled
                    ? 'Please read and agree to the Terms of Service and Privacy Policy'
                    : 'Reserve'
                }
              >
                Reserve
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
