import SamplePreview from 'assets/images/atelier-sample-preview.png';
import SampleAvatar from 'assets/images/kes.png';
import classNames from 'classnames';
import Button from 'client/components/Button';
import Footer from 'client/components/Footer';
import Topbar from 'client/components/Topbar';
import css from 'client/css/Atelier.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
};

// id          Int     @id @default(autoincrement())
// userId      Int
// entityId    Int
// nftTokenId  Int?
// entity      Entity  @relation(fields: [entityId], references: [id])
// user        User    @relation(fields: [userId], references: [id])
// title       String  @default("")
// assetUrl    String  @default("")
// nftUrl      String? @default("")
// price       Int
// count       Int
// caption     String  @default("")
// comment     String? @default("")
// isSubmitted Boolean @default(false)
// isReviewed  Boolean @default(false)
// isApproved  Boolean @default(false)

export default function Atelier({ appContext }: Props): JSX.Element {
  useStyles(css);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [draft, setDraft] = useState<{
    title: string;
    price: number;
    count: number;
    caption: number;
    assetImageUrl: string;
    signatureImageUrl: string;
    profileImageUrl: string;
    nftImageUrl: string;
  }>();

  const signatureCanvas = useRef<any>();

  const processFile = (file: any, field: 'art' | 'profile') => {
    const formData = new FormData();
    formData.append('image', file, file.name);

    // Need to update to include entityId
    fetch(
      field === 'profile' ? '/api/uploadProfileImage' : '/api/uploadArtImage',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((result) => result.json())
      .then((result) => {
        setLoading(false);
        if (result.isSuccess) {
          console.log(result.isSuccess);
        }
      });
  };

  const handleFileUpload = (event: any) => {
    setLoading(true);
    processFile(event.target.files[0], 'art');
  };

  const handleFileDrop = (event: any) => {
    event.preventDefault();
    processFile(event.dataTransfer.files[0], 'art');
  };

  const handleFileDragOver = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleSignatureClear = () => {
    signatureCanvas.current.clear();
  };

  const handleSignatureUndo = () => {
    const data = signatureCanvas.current.toData();
    data.pop();
    signatureCanvas.current.fromData(data);
  };

  const handleProfileUpload = (event: any) => {
    setLoading(true);
    processFile(event.target.files[0], 'profile');
  };

  return (
    <div className="Atelier">
      <Topbar appContext={appContext} />
      <div className="Atelier-content">
        <div className="Atelier-leftColumn">
          <div className="Atelier-header">
            <div className="Atelier-title">Eth.id Atelier</div>
            <div className="Atelier-subTitle">
              Tastefully present NFT&apos;s of your artwork
            </div>
          </div>
          <div className="Atelier-section">
            <div className="Atelier-sectionTitle">1. Upload artwork</div>
            <label htmlFor="upload-image">
              <div
                className="Atelier-upload"
                onDragOver={handleFileDragOver}
                onDrop={handleFileDrop}
              >
                <div className="Atelier-uploadCaption">
                  Drag and drop artwork
                </div>
                <Button className="Atelier-uploadButton" onClick={() => {}}>
                  Upload image
                </Button>
                <div className="Atelier-uploadFormat">
                  PNG, JPG, GIF, SVG accepted. Max size 25 MB.
                </div>
              </div>
            </label>
            <input
              className="Atelier-uploadInput"
              type="file"
              name="image"
              onChange={handleFileUpload}
              id="upload-image"
            />
          </div>
          <div className="Atelier-section">
            <div className="Atelier-sectionTitle">2. Fill in details</div>
            <div className="Atelier-sectionContent">
              <div className="Atelier-sectionItem">
                <div className="Atelier-sectionCaption">Artist photo:</div>
                <div
                  className="Atelier-avatar"
                  style={{ backgroundImage: `url('${SampleAvatar}')` }}
                ></div>
                <label htmlFor="upload-profileImage">
                  <Button
                    className="Atelier-avatarUploadButton"
                    onClick={() => {}}
                  >
                    Update photo
                  </Button>
                </label>
                <input
                  className="Atelier-uploadInput"
                  type="file"
                  name="image"
                  onChange={handleProfileUpload}
                  id="upload-profileImage"
                />
              </div>
              <div className="Atelier-sectionItem">
                <div className="Atelier-sectionCaption">Title:</div>
                <input
                  type="text"
                  className="Atelier-sectionInput"
                  placeholder={`All pieces must have a title.  Even "Untitled".`}
                />
              </div>
              <div className="Atelier-sectionItem">
                <div className="Atelier-sectionCaption">
                  Price in USD ($25-$5000):
                </div>
                <input
                  type="number"
                  className="Atelier-sectionInput"
                  placeholder="Consider price along with # of editions."
                />
              </div>
              <div className="Atelier-sectionItem">
                <div className="Atelier-sectionCaption">
                  Edition count (1-100):
                </div>
                <input
                  type="number"
                  className="Atelier-sectionInput"
                  placeholder="# of copies that are available of this piece."
                />
              </div>
              <div className="Atelier-sectionItem">
                <div className="Atelier-sectionCaption">2% allocation:</div>
                <input
                  type="text"
                  className="Atelier-sectionInput"
                  placeholder={`Eth.id tradition for humor. "2% goes to my cat"`}
                />
              </div>
            </div>
          </div>
          <div className="Atelier-section">
            <div className="Atelier-sectionTitle">3. Sign your work</div>

            <div className="Atelier-signatureControl">
              <Button
                className="Atelier-signatureControlButton"
                onClick={handleSignatureClear}
              >
                Clear all
              </Button>
              <Button
                className="Atelier-signatureControlButton"
                onClick={handleSignatureUndo}
              >
                Undo stroke
              </Button>
            </div>
            <div className="Atelier-signature">
              <SignatureCanvas
                ref={(ref) => {
                  signatureCanvas.current = ref;
                }}
                penColor="black"
                velocityFilterWeight={1}
                dotSize={3}
                minWidth={2.5}
                maxWidth={2.5}
                throttle={20}
                canvasProps={{
                  width: 635,
                  height: 400,
                  className: 'Atelier-signatureCanvas',
                }}
              />
              <div
                className={classNames(
                  'Atelier-signatureArea',
                  'Atelier-signatureAreaTop'
                )}
              >
                <div className="Atelier-signatureCaption">
                  Overlapping with artwork
                </div>
              </div>
              <div
                className={classNames(
                  'Atelier-signatureArea',
                  'Atelier-signatureAreaBottom'
                )}
              >
                <div className="Atelier-signatureCaption">
                  Bottom border cut off
                </div>
              </div>
            </div>
          </div>
          <div className="Atelier-confirm">
            <Button className="Atelier-submitButton" onClick={() => {}}>
              Submit for review
            </Button>
          </div>
        </div>
        <div className="Atelier-rightColumn">
          <div className="Atelier-preview">
            <div className="Atelier-previewCaption">
              Preview on your website
            </div>
            <img src={SamplePreview} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
