import classNames from 'classnames';
import Button from 'client/components/Button';
import css from 'client/css/SignupSubDomainButton.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

type Props = {
  subDomainName: string;
  isSelected: boolean;
  isAvailable: boolean | undefined;
  isFetching: boolean;
  onClick: () => void;
  isEmpty: boolean;
};

export default function SignupSubDomainButton({
  subDomainName,
  isSelected,
  isAvailable,
  isFetching,
  onClick,
  isEmpty,
}: Props): JSX.Element {
  useStyles(css);
  return (
    <div className="SignupSubDomainButton">
      <Button
        className={classNames('SignupSubDomainButton-button', {
          'SignupSubDomainButton-button--selected': isSelected,
          'SignupSubDomainButton-button--disabled': !isAvailable,
        })}
        onClick={onClick}
        disabled={isFetching || !isAvailable}
        title={`.${subDomainName}`}
      >
        {`.${subDomainName}`}
      </Button>
      {!isEmpty && (
        <div className="SignupModal-subDomainStatus">
          {isAvailable ? '✅' : '❌'}
        </div>
      )}
    </div>
  );
}
