import Footer from 'client/components/Footer';
import Topbar from 'client/components/Topbar';
import css from 'client/css/Essays.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { lazy, Suspense } from 'react';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
};

export default function Essays({ appContext }: Props): JSX.Element {
  useStyles(css);

  const EssaysContent = lazy(() => import('client/components/EssaysContent'));
  return (
    <div className="Essays">
      <Topbar appContext={appContext} />
      <Suspense fallback={<div className="Essays-content" />}>
        <EssaysContent appContext={appContext} />
      </Suspense>
      <Footer />
    </div>
  );
}
