import css from 'client/css/DomainNftCard.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { getNftImageData } from 'server/helpers/getNftImageData';
import { getNftPreviewData } from 'server/helpers/getNftPreviewData';

type Props = {
  fullDomainName: string;
  subDomainName: string | null;
};

export default function DomainNftCard({
  fullDomainName,
  subDomainName,
}: Props): JSX.Element {
  useStyles(css);
  const isPreview = true;
  return (
    <div className="DomainNftCard">
      <div
        className="DomainNftCard-domainName"
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          __html: isPreview
            ? getNftPreviewData(fullDomainName, subDomainName)
            : getNftImageData(fullDomainName, subDomainName),
        }}
      />
    </div>
  );
}
