import { Module } from '@prisma/client';
import ProfileCardEns from 'assets/images/profile-card-ens.png';
//import ProfileCardEnsB from 'assets/images/profile-card-ens-2.png';
// import SampleNftA from 'assets/images/nft-a.png';
// import SampleNftB from 'assets/images/nft-b.png';
// import SampleNftC from 'assets/images/nft-c.png';
// import SampleNftD from 'assets/images/nft-d.png';
// import SampleNftE from 'assets/images/nft-e.png';
// import SampleNftF from 'assets/images/nft-f.png';
import ProfileCardOtherside from 'assets/images/profile-card-otherside.png';
import ProfileCardPhiland from 'assets/images/profile-card-philand.png';
import Button from 'client/components/Button';
import Card from 'client/components/Card';
import ConfirmModal from 'client/components/ConfirmModal';
import Footer from 'client/components/Footer';
import Link from 'client/components/Link';
import ModuleModal from 'client/components/ModuleModal';
import ProfileHeader from 'client/components/ProfileHeader';
import ResellerModal from 'client/components/ResellerModal';
// import Story from 'client/components/Story';
import Topbar from 'client/components/Topbar';
import css from 'client/css/Profile.scss';
import { generateCard } from 'client/helpers/generateCard';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { AppContext } from 'shared/AppContext';
//import { ROOT_DOMAIN } from 'shared/domainConsts';

type Props = {
  appContext: AppContext;
};

export default function Profile({ appContext }: Props): JSX.Element {
  const {
    user,
    visitFullDomainName: fullDomainName,
    visitEntity,
    walletAddress,
  } = appContext;

  useStyles(css);
  const entity = visitEntity;

  const isOwner =
    user?.id === visitEntity?.user.id ||
    walletAddress === '0xc80fdc84d1a2565ebca1e1b978e38a2777616e76';

  const [modules, setModules] = useState(entity?.modules || []);

  // todo: Create moduleLayout typing
  const moduleLayout = (entity?.moduleLayout as any) || {};

  const [baseLayout, setBaseLayout] = useState(moduleLayout.base || []);
  const [leftLayout, setLeftLayout] = useState(moduleLayout.left || []);
  const [rightLayout, setRightLayout] = useState(moduleLayout.right || []);

  const [isModuleModalVisible, setModuleModalVisible] = useState(false);
  const [moduleMode, setModuleMode] = useState('base');
  const handleModuleModalClose = () => {
    setModuleModalVisible(false);
  };

  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [moduleIdToBeDeleted, setModuleIdToBeDeleted] = useState(-1);

  const [isResellerModalVisible, setResellerModalVisible] = useState(
    isOwner && (!entity || (entity.viewCount && entity.viewCount < 3))
  );

  const handleConfirmModalClose = () => {
    setConfirmModalVisible(false);
  };

  const handleModuleDeleteClick = (moduleId: number) => {
    setModuleIdToBeDeleted(moduleId);
    setConfirmModalVisible(true);
  };

  const handleModuleDelete = () => {
    fetch('/api/deleteModule', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress,
        moduleId: moduleIdToBeDeleted,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        const { isSuccess } = result;
        if (isSuccess) {
          removeModule(moduleIdToBeDeleted);
        }
        setConfirmModalVisible(false);
      });
  };

  const handleModuleUpdate = (moduleId: number, data: Partial<Module>) => {
    fetch('/api/updateModule', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress,
        moduleId,
        data,
      }),
    }).then((result) => result.json());

    const oldModule = modules.find((module) => module.id === moduleId);
    const newModules = [
      ...modules.filter((module) => module.id !== moduleId),
      {
        ...oldModule,
        ...data,
      },
    ];
    setModules(newModules);
  };

  const handleModuleSelect = (moduleTypeId: number) => {
    if (entity) {
      fetch('/api/addModule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress,
          entityId: entity.id,
          moduleTypeId,
        }),
      })
        .then((result) => result.json())
        .then((result) => {
          const { moduleId, isSuccess } = result;
          if (isSuccess) {
            insertModule(
              moduleId,
              0,
              moduleMode,
              leftLayout,
              rightLayout,
              baseLayout
            );

            // todo: This is module filler locally
            setModules([
              ...modules,
              {
                id: moduleId,
                entityId: entity.id,
                moduleTypeId: moduleTypeId,
                isPrivate: false,
                a: null,
                b: null,
                c: null,
                d: null,
                e: null,
                f: null,
                g: null,
                h: null,
                i: null,
                j: null,
                k: null,
                l: null,
                m: null,
                n: null,
                o: null,
                p: null,
                q: null,
                r: null,
                s: null,
                t: null,
                u: null,
                v: null,
                w: null,
                x: null,
                y: null,
                z: null,
              },
            ]);

            setModuleModalVisible(false);
          }
        });
    }
  };

  useEffect(() => {
    if (entity) {
      setBaseLayout(((entity.moduleLayout as any) || {}).base || []);
      setLeftLayout(((entity.moduleLayout as any) || {}).left || []);
      setRightLayout(((entity.moduleLayout as any) || {}).right || []);
      setModules(entity.modules);
    }
  }, [entity]);

  // todo: Debounce onSubmit
  const onSubmit = (
    baseLayout: number[],
    leftLayout: number[],
    rightLayout: number[]
  ) => {
    if (entity) {
      fetch('/api/updateModuleLayout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress,
          entityId: entity.id,
          data: {
            base: baseLayout,
            left: leftLayout,
            right: rightLayout,
          },
        }),
      }).then((result) => result.json());
    }
  };

  const removeModule = (moduleId: number) => {
    const newBaseLayout = baseLayout.filter((id: number) => id !== moduleId);
    const newLeftLayout = leftLayout.filter((id: number) => id !== moduleId);
    const newRightLayout = rightLayout.filter((id: number) => id !== moduleId);
    setBaseLayout(newBaseLayout);
    setLeftLayout(newLeftLayout);
    setRightLayout(newRightLayout);

    onSubmit(newBaseLayout, newLeftLayout, newRightLayout);
  };

  const insertModule = (
    moduleId: number,
    index: number,
    moduleMode: string,
    oldLeftLayout: number[],
    oldRightLayout: number[],
    oldBaseLayout: number[]
  ) => {
    let newLeftLayout: number[] = oldLeftLayout;
    let newRightLayout: number[] = oldRightLayout;
    let newBaseLayout: number[] = oldBaseLayout;
    if (moduleMode === 'left' || moduleMode === 'right') {
      if (moduleMode === 'left') {
        newLeftLayout = [
          ...oldLeftLayout.slice(0, index),
          moduleId,
          ...oldLeftLayout.slice(index),
        ];
      } else {
        newRightLayout = [
          ...oldRightLayout.slice(0, index),
          moduleId,
          ...oldRightLayout.slice(index),
        ];
      }
      newBaseLayout = [];
      if (newLeftLayout.length >= oldRightLayout.length) {
        newLeftLayout.forEach((leftModuleId: number, idx: number) => {
          newBaseLayout.push(leftModuleId);
          const rightModuleId = newRightLayout[idx];
          if (rightModuleId) {
            newBaseLayout.push(rightModuleId);
          }
        });
      } else {
        newRightLayout.forEach((rightModuleId: number, idx: number) => {
          const leftModuleId = newLeftLayout[idx];
          if (leftModuleId) {
            newBaseLayout.push(leftModuleId);
          }
          newBaseLayout.push(rightModuleId);
        });
      }
    } else {
      newBaseLayout = [
        ...oldBaseLayout.slice(0, index),
        moduleId,
        ...oldBaseLayout.slice(index),
      ];

      newLeftLayout = newBaseLayout.filter(
        (moduleId: number, idx: number) => idx % 2 === 0
      );
      newRightLayout = newBaseLayout.filter(
        (moduleId: number, idx: number) => idx % 2 === 1
      );
    }

    setBaseLayout(newBaseLayout);
    setLeftLayout(newLeftLayout);
    setRightLayout(newRightLayout);

    onSubmit(newBaseLayout, newLeftLayout, newRightLayout);
  };

  const handleDragEnd = (result: DropResult) => {
    const { reason, destination, draggableId } = result;
    const moduleId = Number.parseInt(draggableId);
    if (reason === 'DROP' && destination) {
      const moduleMode = destination.droppableId;
      const index = destination.index;

      if (moduleMode === 'left' || moduleMode === 'right') {
        const newLeftLayout = leftLayout.filter(
          (id: number) => id !== moduleId
        );
        const newRightLayout = rightLayout.filter(
          (id: number) => id !== moduleId
        );
        insertModule(
          moduleId,
          index,
          moduleMode,
          newLeftLayout,
          newRightLayout,
          baseLayout
        );
      } else {
        const newBaseLayout = baseLayout.filter(
          (id: number) => id !== moduleId
        );
        insertModule(
          moduleId,
          index,
          moduleMode,
          leftLayout,
          rightLayout,
          newBaseLayout
        );
      }
    }
  };

  const renderCard = (moduleId: number, idx: number) => {
    const module = modules.find((module) => module.id === moduleId);

    if (!module) {
      return;
    }

    return (
      <Draggable
        draggableId={moduleId.toString()}
        index={idx}
        key={moduleId}
        isDragDisabled={!isOwner}
      >
        {(provided) => (
          <div
            ref={provided.innerRef} // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
          >
            {generateCard({
              entity,
              entityId: entity?.id,
              module,
              isOwner,
              dragHandleProps: provided.dragHandleProps,
              handleModuleUpdate,
              handleModuleDeleteClick,
            })}
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div className="Profile">
      <Topbar appContext={appContext} />
      <div className="Profile-content">
        <ProfileHeader
          appContext={appContext}
          entity={entity}
          isOwner={isOwner}
        />
        <div className="Profile-columns">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="base">
              {(provided: any) => (
                <div
                  className="Profile-baseContent"
                  ref={provided.innerRef}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided.droppableProps}
                >
                  {isOwner && (
                    <Button
                      size="medium"
                      onClick={() => {
                        setModuleMode('base');
                        setModuleModalVisible(true);
                      }}
                      className="Profile-moduleButton"
                    >
                      Add app
                    </Button>
                  )}
                  {baseLayout.map(renderCard)}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="left">
              {(provided: any) => (
                <div
                  className="Profile-leftContent"
                  ref={provided.innerRef}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided.droppableProps}
                >
                  {isOwner && (
                    <Button
                      size="medium"
                      onClick={() => {
                        setModuleMode('left');
                        setModuleModalVisible(true);
                      }}
                      className="Profile-moduleButton"
                    >
                      Add app
                    </Button>
                  )}
                  {leftLayout.map(renderCard)}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId="right">
              {(provided: any) => (
                <div
                  className="Profile-rightContent"
                  ref={provided.innerRef}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided.droppableProps}
                >
                  {isOwner && (
                    <Button
                      size="medium"
                      onClick={() => {
                        setModuleMode('right');
                        setModuleModalVisible(true);
                      }}
                      className="Profile-moduleButton"
                    >
                      Add app
                    </Button>
                  )}
                  {rightLayout.map(renderCard)}
                  {provided.placeholder}

                  {fullDomainName === 'joe' && (
                    <>
                      <Link
                        external
                        to="https://opensea.io/assets/ethereum/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85/69199108047672573454603519250120572918758813699318183641839630914865688493028"
                        newTab
                      >
                        <Card
                          title="ENS Domain Sale"
                          className="Card"
                          padding={false}
                          isDraggable={isOwner}
                        >
                          <img src={ProfileCardEns} />
                        </Card>
                      </Link>

                      <Link
                        external
                        to="https://land.philand.xyz/buterbrod.eth"
                        newTab
                      >
                        <Card
                          title="Philand"
                          className="Card"
                          padding={false}
                          isDraggable={isOwner}
                        >
                          <img src={ProfileCardPhiland} />
                        </Card>
                      </Link>

                      <Link
                        external
                        to="https://opensea.io/assets/ethereum/0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258/81743"
                        newTab
                      >
                        <Card
                          title="Otherdeed for Otherside #81743"
                          className="Card"
                          padding={false}
                          isDraggable={isOwner}
                        >
                          <img src={ProfileCardOtherside} />
                        </Card>
                      </Link>
                    </>
                  )}
                  {/*
                  <Card title="Post">Write something interesting here</Card>
                  <Story entity={entity} fullDomainName={fullDomainName}>
                    <div
                      className="Profile-nft"
                      style={{ backgroundImage: `url(${SampleNftA})` }}
                    />
                  </Story>
                  <Story entity={entity} fullDomainName={fullDomainName}>
                    <div
                      className="Profile-nft"
                      style={{ backgroundImage: `url(${SampleNftB})` }}
                    />
                  </Story>
                  <Story entity={entity} fullDomainName={fullDomainName}>
                    <div
                      className="Profile-nft"
                      style={{ backgroundImage: `url(${SampleNftC})` }}
                    />
                  </Story>
                  <Story entity={entity} fullDomainName={fullDomainName}>
                    <div
                      className="Profile-nft"
                      style={{ backgroundImage: `url(${SampleNftD})` }}
                    />
                  </Story>
                  <Story entity={entity} fullDomainName={fullDomainName}>
                    <div
                      className="Profile-nft"
                      style={{ backgroundImage: `url(${SampleNftE})` }}
                    />
                  </Story>
                  <Story entity={entity} fullDomainName={fullDomainName}>
                    <div
                      className="Profile-nft"
                      style={{ backgroundImage: `url(${SampleNftF})` }}
                    />
              </Story>*/}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <Footer />
      {isModuleModalVisible && (
        <ModuleModal
          onClose={handleModuleModalClose}
          onModuleSelect={handleModuleSelect}
        />
      )}
      {isConfirmModalVisible && (
        <ConfirmModal
          onClose={handleConfirmModalClose}
          title="Confirmation"
          content="Delete this app?"
          onConfirm={handleModuleDelete}
        />
      )}
      {isResellerModalVisible && (
        <ResellerModal
          onClose={() => {
            setResellerModalVisible(false);
          }}
          walletAddress={entity?.user.walletAddress}
        />
      )}
    </div>
  );
}
