import classNames from 'classnames';
import Button from 'client/components/Button';
import Footer from 'client/components/Footer';
import Link from 'client/components/Link';
import Topbar from 'client/components/Topbar';
import css from 'client/css/Enterprise.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
};

export default function Enterprise({ appContext }: Props): JSX.Element {
  useStyles(css);
  return (
    <div className={classNames('Enterprise')}>
      <Topbar appContext={appContext} isLanding={true} />
      <div className="Enterprise-content">
        <div className="Enterprise-hero">
          <div className="Enterprise-heroHeadline">
            Enterprise Web 3 <br /> Identity Management
            <Button
              onClick={() => {
                window.open('https://oppleuydnoj.typeform.com/to/btbqllCV');
              }}
              size="large"
              className="Enterprise-heroButton"
            >
              <Link to="https://oppleuydnoj.typeform.com/to/btbqllCV" external>
                Book a demo
              </Link>
            </Button>
          </div>
          <div className="Enterprise-heroCarousel">
            <div className="Enterprise-heroCarouselItem">
              <div className="Enterprise-heroCarouselItemTitle">
                Issue Institutional <br />
                Web 3 Domains
              </div>
              <div className="Enterprise-heroCarouselItemContent">
                Allocate Ethereum Name Service (ENS) domains under
                [institution].eth.id.
              </div>
            </div>
            <div className="Enterprise-heroCarouselItem">
              <div className="Enterprise-heroCarouselItemTitle">
                Authoritative Identity
              </div>
              <div className="Enterprise-heroCarouselItemContent">
                Assert genuine identity for wallets under [institution].eth.id.
              </div>
            </div>
            <div className="Enterprise-heroCarouselItem">
              <div className="Enterprise-heroCarouselItemTitle">
                Customized <br />
                Proof-of-Identity
              </div>
              <div className="Enterprise-heroCarouselItemContent">
                Utilize email, Enterprise SSO, a NFT, a Web 3 token for
                authenticating issuance.
              </div>
            </div>
            <div className="Enterprise-heroCarouselItem">
              <div className="Enterprise-heroCarouselItemTitle">
                Dynamic <br />
                Reassignment
              </div>
              <div className="Enterprise-heroCarouselItemContent">
                Modify assignments in real-time as your institutional needs
                change.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
