import { Module } from '@prisma/client';
import ModuleCard from 'client/components/ModuleCard';
import css from 'client/css/YouTubeCard.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useState } from 'react';

type Props = {
  module: Partial<Module>;
  isOwner: boolean;
  dragHandleProps: any;
  onModuleUpdate: (moduleId: number, data: Partial<Module>) => void;
  onModuleDelete: (moduleId: number) => void;
};

export default function YouTubeCard({
  module,
  isOwner,
  dragHandleProps,
  onModuleUpdate,
  onModuleDelete,
}: Props): JSX.Element {
  useStyles(css);

  const [isEdit, setEdit] = useState(false);
  const [title, setTitle] = useState(JSON.parse(module.a || '{}'));
  const [video, setVideo] = useState<any>(JSON.parse(module.b || '{}'));
  const [youTubeId, setYouTubeId] = useState<any>(JSON.parse(module.c || '{}'));
  const [videoUrl, setVideoUrl] = useState(video.url);

  const onChangeTitle = (title: string) => {
    setTitle({ title });
  };

  const onChangeYouTubeId = (youTubeId: string) => {
    setYouTubeId({ youTubeId });
  };

  // todo: Add NFT typing
  const onChangeVideoUrl = (url: any) => {
    setVideoUrl(url);
    fetch(`https://noembed.com/embed?dataType=json&url=${url}`)
      .then((res) => res.json())
      .then((data) => {
        // Currently restrict embeds to youtube only for this module
        if (!data.error && data.provider_name === 'YouTube') {
          setVideo({
            authorName: data.author_name,
            authorUrl: data.author_url,
            height: data.height,
            html: data.html,
            providerName: data.provider_name,
            providerUrl: data.provider_url,
            thumbnailHeight: data.thumbnail_height,
            thumbnailUrl: data.thumbnail_url,
            thumbnailWidth: data.thumbnail_width,
            title: data.title,
            type: data.type,
            url: data.url,
            version: data.version,
            width: data.width,
          });
          onChangeTitle(data.title);

          // Parse out youtube id from given html link
          if (data.html.indexOf('/embed/') > -1) {
            const embed = data.html.slice(
              data.html.indexOf('/embed/') + '/embed/'.length
            );
            onChangeYouTubeId(embed.substring(0, embed.indexOf('?')));
          }
        }
      });
  };

  const onSubmit = () => {
    setEdit(false);
    if (module.id) {
      onModuleUpdate(module.id, {
        a: JSON.stringify(title),
        b: JSON.stringify(video),
        c: JSON.stringify(youTubeId),
      });
    }
  };

  // todo: add submission spinner
  return (
    <ModuleCard
      title={title.title || 'YouTube Video'}
      moduleId={module.id}
      isOwner={isOwner}
      dragHandleProps={dragHandleProps}
      onModuleDelete={onModuleDelete}
      isEdit={isEdit}
      onEdit={() => {
        setEdit(true);
      }}
      onCancel={() => {
        setEdit(false);
      }}
    >
      {!isEdit && (
        <iframe
          width="480"
          height="270"
          src={`https://www.youtube.com/embed/${youTubeId.youTubeId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      )}
      {isEdit && (
        <div className="ModuleCard-rows">
          <div className="ModuleCard-row">
            {'YouTube URL: '}
            <input
              className="ModuleCard-input"
              value={videoUrl}
              onChange={(event) => onChangeVideoUrl(event.target.value)}
            ></input>
          </div>
          <div className="ModuleCard-row">
            {'Title: '}
            <input
              className="ModuleCard-input"
              value={title.title}
              onChange={(event) => onChangeTitle(event.target.value)}
            ></input>
          </div>
          <div className="ModuleCard-row">
            {'Thumbnail: '}
            <div
              className={'YouTubeCard-thumbnail'}
              style={{ backgroundImage: `url(${video.thumbnailUrl})` }}
            />
          </div>
        </div>
      )}

      {isEdit && (
        <div className="Card-footer">
          <div className="Card-footerButton" onClick={onSubmit}>
            Submit
          </div>
        </div>
      )}
    </ModuleCard>
  );
}
