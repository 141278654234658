import { useDynamicContext } from '@dynamic-labs/sdk-react';
import { useEthers } from '@usedapp/core';
import css from 'client/css/LoginModal.scss';
import { getAuthCookie } from 'client/helpers/getAuthCookie';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ReactNode, useEffect, useRef, useState } from 'react';

type Props = {
  children: ReactNode;
  onLogin: () => void;
  onError?: () => void;
  isDisabled?: boolean;
};

export default function LoginButton({ children, onLogin, isDisabled }: Props) {
  useStyles(css);

  const [isAuth, setAuth] = useState(false);
  const { authToken, setShowAuthFlow } = useDynamicContext();
  const isWaitingAuth = useRef(false);
  const waitingInterval = useRef<any>(null);

  const ethersWallet = useRef<any>();
  const { account: ethersWalletAddress } = useEthers();

  const handleClick = () => {
    if (authToken) {
      onLogin();
    } else {
      setShowAuthFlow(true);
      isWaitingAuth.current = true;
    }
  };

  useEffect(() => {
    if (authToken && isWaitingAuth.current) {
      isWaitingAuth.current = false;

      waitingInterval.current = setInterval(() => {
        const { walletAddress: authCookieWalletAddress } = getAuthCookie();

        if (
          authCookieWalletAddress &&
          ethersWallet.current &&
          waitingInterval.current
        ) {
          clearInterval(waitingInterval.current);
          onLogin();
          waitingInterval.current = null;
        }
      }, 100);
    }
  }, [authToken, onLogin]);

  useEffect(() => {
    ethersWallet.current = ethersWalletAddress;
  }, [ethersWalletAddress]);

  return (
    <div onClick={isDisabled ? () => {} : handleClick}>
      {children}
      {isAuth && <div className="LoginModal" onClick={() => setAuth(false)} />}
    </div>
  );
}
