import ImageLogo from 'assets/images/topbar-logo.png';
import Link from 'client/components/Link';
import css from 'client/css/Footer.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export default function Footer(): JSX.Element {
  useStyles(css);

  return (
    <div className="Footer">
      <div className="Footer-content">
        <div className="Footer-bar">
          <div className="Footer-mast">
            <Link external to="https://eth.id">
              <img className="Footer-logo" src={ImageLogo} />
            </Link>
            <div className="Footer-logoCaption">
              <Link external to="https://eth.id">
                Eth.id
              </Link>
            </div>
            <div className="Footer-logoMission">Your Web 3 profile</div>
          </div>
          <div className="Footer-columns">
            <div className="Footer-column">
              <div className="Footer-columnTitle">Products</div>
              <div className="Footer-columnItem Footer-columnItem">
                <a href="https://eth.id">Web 3 profile (.me)</a>
              </div>
              <div className="Footer-columnItem Footer-columnItem">
                <a href="https://season1.eth.id">Season 1 (.la .nyc .sf)</a>
              </div>
              <div className="Footer-columnItem Footer-columnItem">
                <a href="https://season0.eth.id">Season 0 (.me .w .x)</a>
              </div>
              <div className="Footer-columnItem Footer-columnItem">
                <a href="https://whale.eth.id">Luxury Domains (base)</a>
              </div>
              <div className="Footer-columnItem Footer-columnItem">
                <a href="https://enterprise.eth.id">Enterprise</a>
              </div>
            </div>
            <div className="Footer-column">
              <div className="Footer-columnTitle">Resources</div>
              <div className="Footer-columnItem Footer-columnItem">
                <a href="https://support.eth.id">Support</a>
              </div>
              <div className="Footer-columnItem Footer-columnItem">
                <a href="https://essays.eth.id">Essays</a>
              </div>
              <div className="Footer-columnItem">
                <a
                  href="https://opensea.io/collection/ethid"
                  target="_blank"
                  rel="noreferrer"
                >
                  OpenSea
                </a>
              </div>
              <div className="Footer-columnItem">
                <a href="https://j.eth.id">j.eth.id</a>
              </div>
              <div className="Footer-columnItem">
                <a href="https://ethid.notion.site/Eth-id-Hackathon-Developer-Guide-5479a307d64e44beaabc99531bc612aa">
                  Docs
                </a>
              </div>
            </div>
            <div className="Footer-column">
              <div className="Footer-columnTitle">Company</div>
              <div className="Footer-columnItem Footer-columnItem">
                <a
                  href="https://essays.eth.id/time-to-blank-route"
                  title="Yarrrrrrrgh!"
                >
                  🏴‍☠️ Careers 🏴‍☠️
                </a>
              </div>

              <div className="Footer-columnItem Footer-columnItem">
                <a
                  href="https://ethid.notion.site/Eth-id-Media-Kit-53da8aab238043c48746b0fd666ff413"
                  target="_blank"
                  rel="noreferrer"
                >
                  Media Kit
                </a>
              </div>
              <div className="Footer-columnItem Footer-columnItem--inactive">
                <a href="">About</a>
              </div>
              <div className="Footer-columnItem Footer-columnItem--inactive">
                <a href="">Blog</a>
              </div>
              <div className="Footer-columnItem Footer-columnItem--inactive">
                <a href="">Partnerships</a>
              </div>
            </div>
            <div className="Footer-column">
              <div className="Footer-columnTitle">Connect</div>
              <div className="Footer-columnItem">
                <a
                  href="https://t.me/ethdotid"
                  target="_blank"
                  rel="noreferrer"
                >
                  Telegram
                </a>
              </div>
              <div className="Footer-columnItem Footer-columnItem">
                <a href="mailto:j@eth.id">Email j@eth.id</a>
              </div>
              <div className="Footer-columnItem">
                <a
                  href="https://twitter.com/ethdotid"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </div>
              <div className="Footer-columnItem">
                <a
                  href="https://discord.gg/pDc6Q5Jwjx"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>
              </div>
              <div className="Footer-columnItem Footer-columnItem--inactive">
                <a href="">Medium</a>
              </div>
            </div>
          </div>
        </div>
        <div className="Footer-copyright">
          © 2022-2023 Eth Id, Inc.
          <div className="Footer-copyrightLinks">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
