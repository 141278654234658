import 'react-hot-loader/patch';

import App from 'client/components/App';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';

(async () => {
  const insertCss = (...styles: IsomorphicCSSImport[]) => {
    const removeCss = styles.map((style) => style._insertCss());
    return () => removeCss.forEach((dispose) => dispose());
  };
  let initialRendering = true;

  // todo: evaluate security issues using appContext as static pass
  const w: any = window;
  const appContext = w.appContext;
  // --

  const renderApp = () =>
    ReactDOM.hydrate(
      <StyleContext.Provider value={{ insertCss }}>
        <AppContainer>
          <BrowserRouter>
            <App appContext={appContext} />
          </BrowserRouter>
        </AppContainer>
      </StyleContext.Provider>,
      document.getElementById('react-root'),
      () => {
        if (initialRendering) {
          const node = document.getElementById('static-css');
          node?.parentNode?.removeChild(node);
          initialRendering = false;
        }
      }
    );

  renderApp();

  if (module.hot) {
    module.hot.accept('./components/App.tsx', () => renderApp());
  }
})();
