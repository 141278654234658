import { Module } from '@prisma/client';
import classNames from 'classnames';
import Button from 'client/components/Button';
import ImageSelectModal from 'client/components/ImageSelectModal';
import ModuleCard from 'client/components/ModuleCard';
import css from 'client/css/AtelierCard.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useState } from 'react';

type Props = {
  module: Partial<Module>;
  entityId: number | undefined;
  isOwner: boolean;
  dragHandleProps: any;
  onModuleUpdate: (moduleId: number, data: Partial<Module>) => void;
  onModuleDelete: (moduleId: number) => void;
};

export default function AtelierCard({
  module,
  entityId,
  isOwner,
  dragHandleProps,
  onModuleUpdate,
  onModuleDelete,
}: Props): JSX.Element {
  useStyles(css);

  const [isEdit, setEdit] = useState(false);
  const [atelierNftId, setAtelierNftId] = useState(module.a);
  const [properties, setProperties] = useState(JSON.parse(module.b || '{}'));

  const [isImageSelectModalVisible, setImageSelectModalVisible] =
    useState(false);

  const handleImageSelectModalClose = () => {
    setImageSelectModalVisible(false);
  };

  const onChangeTitle = (title: string) => {
    setProperties({ ...properties, title });
  };

  // todo: Add NFT typing
  const onChangeNft = (nft: any) => {
    setAtelierNftId(nft.id);
    setImageSelectModalVisible(false);
    onChangeTitle(nft.title);
  };

  const onSubmit = () => {
    setEdit(false);
    if (module.id) {
      onModuleUpdate(module.id, {
        a: atelierNftId,
        b: JSON.stringify(properties),
      });
    }
  };

  // todo: add submission spinner
  return (
    <ModuleCard
      title={properties.title || 'Atelier NFT'}
      moduleId={module.id}
      isOwner={isOwner}
      dragHandleProps={dragHandleProps}
      onModuleDelete={onModuleDelete}
      isEdit={isEdit}
      onEdit={() => {
        if (!atelierNftId) {
          setImageSelectModalVisible(true);
        }
        setEdit(true);
      }}
      onCancel={() => {
        setEdit(false);
      }}
    >
      {!isEdit && (
        <>
          <img className="AtelierCard-image" src={properties.imageUrl} />
          <div className="AtelierCard-footer">
            <div className="AtelierCard-footerLeft">{`1 of ${properties.limit}`}</div>
            <div className="AtelierCard-footerRight">
              <div className="AtelierCard-price">
                <div className="AtelierCard-priceValue">
                  {`$${properties.price}`}
                </div>
                <div className="AtelierCard-priceCaption">
                  {`${properties.caption}`}
                </div>
              </div>
              <Button className="AtelierCard-purchaseButton" onClick={() => {}}>
                {`Support ${properties.fullDomainName}`}
              </Button>
            </div>
          </div>
        </>
      )}

      {isEdit && (
        <div className="ModuleCard-rows">
          <div className="ModuleCard-row">
            {'Title: '}
            <input
              className="ModuleCard-input"
              value={properties.title}
              onChange={(event) => onChangeTitle(event.target.value)}
            ></input>
          </div>
          <div className="ModuleCard-row">
            {'NFT: '}
            <div
              className={classNames('Profile-nft', 'AtelierCard-thumbnail')}
              style={{ backgroundImage: `url(${properties.imageUrl})` }}
              onClick={() => {
                setImageSelectModalVisible(true);
              }}
            />
          </div>
        </div>
      )}

      {isEdit && (
        <div className="Card-footer">
          <div className="Card-footerButton" onClick={onSubmit}>
            Submit
          </div>
        </div>
      )}

      {isImageSelectModalVisible && (
        <ImageSelectModal
          onClose={handleImageSelectModalClose}
          title="Owned NFT's"
          onNftSelect={onChangeNft}
          entityId={entityId}
        />
      )}
    </ModuleCard>
  );
}
