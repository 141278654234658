import { Contract } from '@ethersproject/contracts';
import { useContractFunction, useEthers } from '@usedapp/core';
import BaseModal from 'client/components/BaseModal';
import Button from 'client/components/Button';
import Link from 'client/components/Link';
import Spinner from 'client/components/Spinner';
import css from 'client/css/ReverseResolutionModal.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useState } from 'react';

type Props = {
  walletAddress: string;
  onClose?: () => void;
  onComplete: () => void;
  isBackgroundTransparent?: boolean;
  latestFullDomainName?: string;
};

const ENS_REVERSE_REGISTRAR_CONTRACT_ADDRESS =
  '0x084b1c3C81545d370f3634392De611CaaBFf8148';

const ENS_REVERSE_REGISTRAR_CONTRACT_INTERFACE = [
  {
    inputs: [
      { internalType: 'contract ENS', name: 'ensAddr', type: 'address' },
      {
        internalType: 'contract Resolver',
        name: 'resolverAddr',
        type: 'address',
      },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    constant: true,
    inputs: [],
    name: 'ADDR_REVERSE_NODE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'claim',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'resolver', type: 'address' },
    ],
    name: 'claimWithResolver',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'defaultResolver',
    outputs: [{ internalType: 'contract Resolver', name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'ens',
    outputs: [{ internalType: 'contract ENS', name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ internalType: 'address', name: 'addr', type: 'address' }],
    name: 'node',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'pure',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ internalType: 'string', name: 'name', type: 'string' }],
    name: 'setName',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export default function ReverseResolutionModal({
  onClose,
  onComplete,
  walletAddress,
  isBackgroundTransparent,
  latestFullDomainName,
}: Props): JSX.Element {
  useStyles(css);

  const contract = new Contract(
    ENS_REVERSE_REGISTRAR_CONTRACT_ADDRESS,
    ENS_REVERSE_REGISTRAR_CONTRACT_INTERFACE
  ) as any;

  const [domains, setDomains] = useState([]);
  const { library } = useEthers();
  const [ens, setEns] = useState('');
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!ens) {
      library?.lookupAddress(walletAddress).then((name: any) => {
        setLoading(false);
        setEns(name);
      });
    }
  }, [library, walletAddress, ens]);
  const { state, send: sendTransaction } = useContractFunction(
    contract,
    'setName',
    {
      transactionName: 'setName',
    }
  );

  useEffect(() => {
    fetch('/api/getDomains', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.domains) {
          setDomains(result.domains);
        }
      });
  }, []);

  useEffect(() => {
    if (state.status.toLowerCase().includes('mining') && state.transaction) {
      onComplete();
    }
  }, [state, onComplete]);

  return (
    <BaseModal
      title={'Update your cosmetic username'}
      onClose={onClose}
      isBackgroundTransparent={isBackgroundTransparent}
    >
      <div className="ReverseResolutionModal">
        <div>
          <div className="ReverseResolutionModal-label">
            Your current Web 3 (ENS) username
          </div>
          <div className="ReverseResolutionModal-current">
            {isLoading ? 'Loading...' : ens || walletAddress}
          </div>
        </div>
        <div>
          <div className="ReverseResolutionModal-label">
            Select a Web 3 username. This is only for appearances. You&apos;ll
            keep all your other names and this change is easily reversible.
          </div>
          <div className="ReverseResolutionModal-items">
            <div className="ReverseResolutionModal-item" onClick={onComplete}>
              {ens} (No change)
            </div>
            <div className="ReverseResolutionModal-divider"></div>

            {latestFullDomainName && (
              <>
                <div
                  className="ReverseResolutionModal-item"
                  onClick={() => {
                    sendTransaction(`${latestFullDomainName}.eth.id`);
                  }}
                >
                  {`${latestFullDomainName}.eth.id`}
                </div>
                <div className="ReverseResolutionModal-divider"></div>
              </>
            )}
            {isLoading && <Spinner isCenter />}
            {!isLoading &&
              domains
                .filter(
                  (domain: any) =>
                    `${domain.fullDomainName}.eth.id` !== ens &&
                    domain.fullDomainName !== latestFullDomainName
                )
                .map((domain: any, idx: number) => (
                  <div key={idx}>
                    <div
                      className="ReverseResolutionModal-item"
                      onClick={() => {
                        sendTransaction(`${domain.fullDomainName}.eth.id`);
                      }}
                    >
                      {`${domain.fullDomainName}.eth.id`}
                    </div>
                    <div className="ReverseResolutionModal-divider"></div>
                  </div>
                ))}
          </div>
        </div>
        <div className="ReverseResolutionModal-footer">
          <div className="ReverseResolutionModal-message">
            Select a main domain for your wallet. <br />
            <div>
              {'Access the '}
              <Link to={`https://discord.gg/pDc6Q5Jwjx`} external newTab>
                Eth.id Discord
              </Link>
              {' for support.'}
            </div>
          </div>

          <Button
            className="ReverseResolutionModal-button"
            onClick={onComplete}
            size="large"
            title="Skip"
          >
            Skip
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
