import { hexlify } from '@ethersproject/bytes';
import { toUtf8Bytes } from '@ethersproject/strings';
import {
  formatEther,
  formatUnits,
  parseEther,
  parseUnits,
} from '@ethersproject/units';
import { useEtherBalance, useEthers, useSendTransaction } from '@usedapp/core';
import IconAssetEth from 'assets/images/icon-asset-eth.png';
import classNames from 'classnames';
import BaseModal from 'client/components/BaseModal';
import Button from 'client/components/Button';
import Spinner from 'client/components/Spinner';
import css from 'client/css/PayMeModal.scss';
import { Contract, utils } from 'ethers';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useRef, useState } from 'react';

const FALLBACK_ETH_PRICE = 1800;
const PRECISION_COUNT = 4;
const ABI = [
  {
    constant: false,
    inputs: [
      { name: '_to', type: 'address' },
      { name: '_value', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: 'success', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

type Props = {
  initialAmount: number;
  fullDomainName: string;
  walletAddress: string;
  onClose?: () => void;
  onComplete: () => void;
};

export default function PayMeModal({
  initialAmount,
  fullDomainName,
  walletAddress,
  onClose,
  onComplete,
}: Props): JSX.Element {
  useStyles(css);

  const [amount, setAmount] = useState(initialAmount);
  const [isSuccess, setSuccess] = useState(false);
  const [isWaiting, setWaiting] = useState(true);
  const [isSigning, setSigning] = useState(false);
  const [balances, setBalances] = useState<any>({});
  const [ethUsd, setEthUsd] = useState(FALLBACK_ETH_PRICE);
  const isFetching = useRef(false);

  // Below only used for success display screen
  const [transactionAmount, setTransactionAmount] = useState('0');
  const [transactionSymbol, setTransactionSymbol] = useState('ETH');
  // ----

  const { library, account } = useEthers();
  const ethBalance: any = useEtherBalance(account);

  useEffect(() => {
    fetch(
      `https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=ethereum`
    )
      .then((result) => result.json())
      .then((result) => {
        if (result['ethereum'] && result['ethereum']['usd']) {
          setEthUsd(result['ethereum']['usd']);
        }
      });
  }, []);

  useEffect(() => {
    if (!isFetching.current) {
      isFetching.current = true;
      fetch('/api/getTokenBalances', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((result) => result.json())
        .then((result) => {
          const balances = result.balances;
          setBalances(balances);
          const contractAddresses = Object.keys(balances);
          fetch(
            `https://api.coingecko.com/api/v3/simple/token_price/ethereum?vs_currencies=usd&contract_addresses=${contractAddresses.join(
              ','
            )}`
          )
            .then((result) => result.json())
            .then((geckoData) => {
              const balancesWithPrice: any = {};
              contractAddresses.forEach((contractAddress) => {
                balancesWithPrice[contractAddress] = {
                  ...balances[contractAddress],
                  ...geckoData[contractAddress],
                };
              });
              setBalances(balancesWithPrice);
              setWaiting(false);
            });
        });
    }
  }, [balances]);

  const { sendTransaction, state } = useSendTransaction({
    transactionName: 'Send Ethereum',
  });

  useEffect(() => {
    if (state.status.toLowerCase().includes('mining') && state.transaction) {
      setSuccess(true);
    }
  }, [state, onComplete]);

  const ethPrice = FALLBACK_ETH_PRICE;
  const ethAmount = Number(amount / ethPrice).toPrecision(PRECISION_COUNT);
  const handleSendEth = (ethAmount: string) => {
    const value = parseEther(ethAmount);
    sendTransaction({
      to: walletAddress,
      value,
      data: hexlify(toUtf8Bytes(`${fullDomainName}.eth.id`)),
    });
    setTransactionAmount(ethAmount);
    setTransactionSymbol('ETH');
  };

  const handleSendToken = async (
    contractAddress: string,
    value: any,
    decimals: any,
    symbol: string
  ) => {
    const contractInterface = new utils.Interface(ABI);
    const contract = new Contract(contractAddress, contractInterface);
    const signer: any = await library?.getSigner();

    if (signer) {
      try {
        setSigning(true);
        const result = await contract
          .connect(signer)
          ['transfer'](walletAddress, parseUnits(value, decimals));
        if (result.hash) {
          setTransactionAmount(value);
          setTransactionSymbol(symbol);
          setSuccess(true);
        }
      } catch (e) {
        setSigning(false);
        console.log('Captured exception', e);
      }
    } else {
      console.error('Signer is null');
    }
  };

  const contractAddresses = Object.keys(balances);
  contractAddresses.sort(
    (contractAddressA: string, contractAddressB: string) => {
      const {
        metadata: metadataA,
        token: tokenA,
        usd: usdA,
      } = balances[contractAddressA];
      const {
        metadata: metadataB,
        token: tokenB,
        usd: usdB,
      } = balances[contractAddressB];

      if (!usdA || !usdB) {
        return 0;
      }

      const balanceA = formatUnits(tokenA.tokenBalance, metadataA.decimals);
      const balanceB = formatUnits(tokenB.tokenBalance, metadataB.decimals);

      return (
        Number.parseFloat(balanceB) * usdB - Number.parseFloat(balanceA) * usdA
      );
    }
  );

  return (
    <BaseModal title={`Pay ${fullDomainName}`} onClose={onClose}>
      <div className="PayMeModal">
        {isSuccess ? (
          <div className="PayMeModal-message">
            <div className="PayMeModal-emoji">✅</div>
            {`${transactionAmount} ${transactionSymbol} is on the way to ${fullDomainName}!`}
            <Button
              className="PayMeModal-button"
              onClick={onComplete}
              size="large"
              title="Done"
            >
              Hooray!
            </Button>
          </div>
        ) : isSigning ? (
          <div className="PayMeModal-message">
            <div className="PayMeModal-emoji">✍️</div>
            {`Complete the transaction using your wallet`}
            <Button
              className="PayMeModal-button"
              onClick={() => setSigning(false)}
              size="large"
              title="Done"
            >
              Cancel sending
            </Button>
          </div>
        ) : isWaiting ? (
          <Spinner isCenter={true} />
        ) : (
          <>
            <div className="PayMeModal-inputWrapper">
              <div className="PayMeModal-unit">$</div>
              <input
                className="PayMeModal-input"
                type="number"
                onChange={(event) =>
                  setAmount(Number.parseFloat(event.target.value))
                }
                value={amount}
              />
            </div>
            <div className="PayMeModal-items">
              <div
                className={classNames('PayMeModal-item', {
                  'PayMeModal-item--disabled':
                    !amount ||
                    Number.parseFloat(ethAmount) === 0 ||
                    (ethBalance &&
                      Number.parseFloat(ethAmount) >
                        Number.parseFloat(formatEther(ethBalance))),
                })}
                onClick={() => handleSendEth(ethAmount)}
              >
                <div className="PayMeModal-itemLeft">
                  <div
                    className="PayMeModal-itemIcon"
                    style={{ backgroundImage: `url('${IconAssetEth}')` }}
                  ></div>
                  <div className="PayMeModal-itemColumn">
                    <div className="PayMeModal-itemCaption">
                      {ethAmount === 'NaN' || Number.parseFloat(ethAmount) === 0
                        ? `Send ETH`
                        : `Send ${ethAmount} ETH`}
                    </div>
                    {ethAmount !== 'NaN' &&
                      Number.parseFloat(ethAmount) !== 0 && (
                        <div className="PayMeModal-itemUsd">{`Worth $${(
                          Number.parseFloat(ethAmount) * ethUsd
                        ).toPrecision(PRECISION_COUNT)} USD`}</div>
                      )}
                  </div>
                </div>

                <div className="PayMeModal-itemRight">
                  <div className="PayMeModal-itemBalance">
                    {ethBalance &&
                      `You have ${Number.parseFloat(
                        formatEther(ethBalance)
                      ).toPrecision(PRECISION_COUNT)} ETH`}
                  </div>
                  <div className="PayMeModal-itemUsd">{`$${ethUsd.toPrecision(
                    PRECISION_COUNT
                  )} per ETH`}</div>
                </div>
              </div>
              <div className="PayMeModal-divider" />

              {contractAddresses.map((contractAddress: string, idx: number) => {
                const { metadata, token, usd } = balances[contractAddress];
                const balance = formatUnits(
                  token.tokenBalance,
                  metadata.decimals
                );
                const tokenAmount = amount
                  ? (amount / usd).toPrecision(PRECISION_COUNT)
                  : 0;

                const isSufficientBalance =
                  tokenAmount !== 0 &&
                  Number.parseFloat(tokenAmount) < Number.parseFloat(balance);
                return (
                  <>
                    <div
                      className={classNames('PayMeModal-item', {
                        'PayMeModal-item--disabled': !isSufficientBalance,
                      })}
                      onClick={() =>
                        isSufficientBalance &&
                        handleSendToken(
                          contractAddress,
                          tokenAmount,
                          metadata.decimals,
                          metadata.symbol
                        )
                      }
                      title={
                        isSufficientBalance
                          ? `Send ${metadata.symbol}`
                          : `Insufficient ${metadata.symbol}`
                      }
                    >
                      <div className="PayMeModal-itemLeft">
                        <div
                          className="PayMeModal-itemIcon"
                          style={{ backgroundImage: `url('${metadata.logo}')` }}
                        ></div>

                        <div className="PayMeModal-itemColumn">
                          <div className="PayMeModal-itemCaption">
                            {tokenAmount === 0
                              ? `Send ${metadata.symbol}`
                              : `Send ${tokenAmount} ${metadata.symbol}`}
                          </div>
                          {usd && tokenAmount !== 0 && (
                            <div className="PayMeModal-itemUsd">{`Worth $${(
                              usd * Number.parseFloat(tokenAmount)
                            ).toPrecision(PRECISION_COUNT)} USD`}</div>
                          )}
                        </div>
                      </div>
                      <div className="PayMeModal-itemRight">
                        <div className="PayMeModal-itemBalance">
                          {`You have ${Number.parseFloat(balance).toPrecision(
                            PRECISION_COUNT
                          )} ${metadata.symbol}`}
                        </div>
                        {usd && (
                          <div className="PayMeModal-itemUsd">{`$${usd.toPrecision(
                            PRECISION_COUNT
                          )} per ${metadata.symbol}`}</div>
                        )}
                      </div>
                    </div>
                    {idx < contractAddresses.length - 1 && (
                      <div className="PayMeModal-divider" />
                    )}
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
}
