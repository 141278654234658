import { Module } from '@prisma/client';
import classNames from 'classnames';
import ModuleCard from 'client/components/ModuleCard';
import { useState } from 'react';

type Props = {
  module: Partial<Module>;
  isOwner: boolean;
  dragHandleProps: any;
  onModuleUpdate: (moduleId: number, data: Partial<Module>) => void;
  onModuleDelete: (moduleId: number) => void;
};

export default function BioCard({
  module,
  isOwner,
  dragHandleProps,
  onModuleUpdate,
  onModuleDelete,
}: Props): JSX.Element {
  const [isEdit, setEdit] = useState(false);
  const [entries, setEntries] = useState(JSON.parse(module.a || '[]'));

  const onDeleteRow = (idx: number) => {
    setEntries([...entries.slice(0, idx), ...entries.slice(idx + 1)]);
  };

  const onAddRow = () => {
    setEntries([...entries, { title: '', value: '' }]);
  };

  const onChangeTitle = (idx: number, title: string) => {
    const entry = entries[idx];
    setEntries([
      ...entries.slice(0, idx),
      { title, value: entry.value },
      ...entries.slice(idx + 1),
    ]);
  };

  const onChangeValue = (idx: number, value: string) => {
    const entry = entries[idx];
    setEntries([
      ...entries.slice(0, idx),
      { title: entry.title, value },
      ...entries.slice(idx + 1),
    ]);
  };

  const onSubmit = () => {
    setEdit(false);
    if (module.id) {
      onModuleUpdate(module.id, {
        a: JSON.stringify(entries),
      });
    }
  };

  // todo: add submission spinner
  return (
    <ModuleCard
      title="About"
      moduleId={module.id}
      isOwner={isOwner}
      dragHandleProps={dragHandleProps}
      onModuleDelete={onModuleDelete}
      isEdit={isEdit}
      onEdit={() => {
        setEdit(true);
      }}
      onCancel={() => {
        setEdit(false);
      }}
    >
      <div className="ModuleCard-rows">
        {entries.map((entry: any, idx: number) => (
          <div className="ModuleCard-row" key={idx}>
            {isEdit ? (
              <>
                <div
                  className="ModuleCard-delete"
                  onClick={() => onDeleteRow(idx)}
                >
                  {'\u274C'}
                </div>
                <input
                  className={classNames(
                    'ModuleCard-input',
                    'ModuleCard-inputTitle'
                  )}
                  value={entry.title}
                  onChange={(event) => onChangeTitle(idx, event.target.value)}
                ></input>
                {': '}
                <input
                  className="ModuleCard-input"
                  value={entry.value}
                  onChange={(event) => onChangeValue(idx, event.target.value)}
                ></input>
              </>
            ) : (
              <>
                {`${entry.title}: `}
                {entry.value}
              </>
            )}
          </div>
        ))}
      </div>
      {isEdit && (
        <div className="Card-footer">
          <div className="Card-footerButton" onClick={onAddRow}>
            Add row
          </div>
          <div className="Card-footerDivider" />
          <div className="Card-footerButton" onClick={onSubmit}>
            Submit
          </div>
        </div>
      )}
    </ModuleCard>
  );
}
