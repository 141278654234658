import ImageDragIndicator from 'assets/images/icon-drag-indicator.png';
import classNames from 'classnames';
import css from 'client/css/Card.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  titleAlign?: 'start' | 'center' | 'space-between';
  button?: ReactNode;
  children?: ReactNode;
  padding?: boolean;
  className?: string;
  isDraggable?: boolean;
  dragHandleProps?: any;
};

export default function Card({
  title,
  titleAlign = 'space-between',
  button,
  children,
  padding = true,
  className,
  isDraggable,
  dragHandleProps = {},
}: Props): JSX.Element {
  useStyles(css);

  return (
    <div className={classNames('Card', className)}>
      {title && (
        <div
          className={classNames('Card-title', {
            'Card-titleDraggable': isDraggable,
            'Card-title--center': titleAlign === 'center',
          })}
          style={{ justifyContent: titleAlign }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div className="Card-titleDragHandle" {...dragHandleProps}>
            {isDraggable && (
              <img
                className="Card-titleDragHandleIcon"
                src={ImageDragIndicator}
              />
            )}
            <div className="Card-titleCaption">{title}</div>
          </div>
          <div>{button}</div>
        </div>
      )}
      <div className="Card-content" style={!padding ? { padding: 0 } : {}}>
        {children}
      </div>
    </div>
  );
}
