import { Module } from '@prisma/client';
import AtelierCard from 'client/components/AtelierCard';
import BioCard from 'client/components/BioCard';
import DevIFrameCard from 'client/components/DevIFrameCard';
import EnsCard from 'client/components/EnsCard';
import EthDenverCard from 'client/components/EthDenverCard';
import LinkCard from 'client/components/LinkCard';
import NftCard from 'client/components/NftCard';
import NftsCard from 'client/components/NftsCard';
import PayMeCard from 'client/components/PayMeCard';
import YouTubeCard from 'client/components/YouTubeCard';

export const generateCard = ({
  entity,
  entityId,
  module,
  isOwner,
  dragHandleProps = {},
  handleModuleUpdate,
  handleModuleDeleteClick,
}: {
  entity: any; // todo: Update entity typing
  entityId: number | undefined;
  module: Partial<Module>;
  isOwner: boolean;
  dragHandleProps: any;
  handleModuleUpdate: (
    moduleId: number,
    data: Partial<Module>
  ) => void | undefined;
  handleModuleDeleteClick: (moduleId: number) => void | undefined;
}) => {
  const moduleId = module.id;
  const moduleTypeId = module.moduleTypeId;
  if (moduleTypeId === 1) {
    return (
      <LinkCard
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleUpdate={handleModuleUpdate}
        onModuleDelete={handleModuleDeleteClick}
      />
    );
  } else if (moduleTypeId === 2) {
    return (
      <NftsCard
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleDelete={handleModuleDeleteClick}
        entityId={entityId}
      />
    );
  } else if (moduleTypeId === 3) {
    return (
      <NftCard
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleUpdate={handleModuleUpdate}
        onModuleDelete={handleModuleDeleteClick}
        entityId={entityId}
      />
    );
  } else if (moduleTypeId === 4) {
    return (
      <BioCard
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleUpdate={handleModuleUpdate}
        onModuleDelete={handleModuleDeleteClick}
      />
    );
  } else if (moduleTypeId === 5) {
    return (
      <EnsCard
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleUpdate={handleModuleUpdate}
        onModuleDelete={handleModuleDeleteClick}
        entityId={entityId}
      />
    );
  } else if (moduleTypeId === 6) {
    return (
      <EthDenverCard
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleDelete={handleModuleDeleteClick}
      />
    );
  } else if (moduleTypeId === 7) {
    return (
      <YouTubeCard
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleUpdate={handleModuleUpdate}
        onModuleDelete={handleModuleDeleteClick}
      />
    );
  } else if (moduleTypeId === 8) {
    return (
      <PayMeCard
        entity={entity}
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleUpdate={handleModuleUpdate}
        onModuleDelete={handleModuleDeleteClick}
      />
    );
  } else if (moduleTypeId === 9) {
    return (
      <AtelierCard
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleUpdate={handleModuleUpdate}
        onModuleDelete={handleModuleDeleteClick}
        entityId={entityId}
      />
    );
  } else if (moduleTypeId === 11) {
    return (
      <DevIFrameCard
        key={moduleId}
        module={module}
        isOwner={isOwner}
        dragHandleProps={dragHandleProps}
        onModuleUpdate={handleModuleUpdate}
        onModuleDelete={handleModuleDeleteClick}
      />
    );
  } else {
    return <></>;
  }
};
