import classNames from 'classnames';
import Spinner from 'client/components/Spinner';
import css from 'client/css/AtelierNft.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function AtelierNft({ appContext }: Props): JSX.Element {
  useStyles(css);

  const [atelierNft, setAtelierNft] = useState<any>();
  const {
    //id,
    //nanoId,
    // entity,
    //title,
    nftImageUrl,
    // price,
    // physicalPrice,
    // caption,
    // limit,
    // count,
  } = atelierNft || {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSuccess, setSuccess] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    const nanoId = pathname[pathname.length - 1];

    fetch('/api/getAtelierNft', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nanoId,
      }),
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.atelierNft) {
          setAtelierNft(result.atelierNft);
        }
      });
  }, []);

  return (
    <div className={classNames('AtelierNft')}>
      <div className="AtelierNft-content">
        {isSuccess ? (
          <div className="AtelierNft-column">
            <Confetti width={2000} height={1024} />
            <img className="AtelierNft-image" src={nftImageUrl}></img>
            <div className="AtelierNft-congrats">
              Congratulations on your purchase! <br /> <br /> Check your email
              for information to claim.
            </div>
          </div>
        ) : (
          <div className="AtelierNft-column">
            <img className="AtelierNft-image" src={nftImageUrl}></img>
            {isFetching ? (
              <div className="AtelierNft-loader">
                <Spinner isCenter />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
