import Footer from 'client/components/Footer';
import Post from 'client/components/Post';
import Topbar from 'client/components/Topbar';
import css from 'client/css/Feed.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
};

export default function Feed({ appContext }: Props): JSX.Element {
  useStyles(css);

  const posts = appContext.posts || [];
  const filteredPosts: any[] = [];
  let lastPost: any;

  posts.forEach((post) => {
    if (
      !lastPost ||
      post.entityId !== lastPost.entityId ||
      post.postTypeId !== lastPost.postTypeId ||
      post.a !== lastPost.a
    ) {
      filteredPosts.push(post);
      lastPost = post;
    }
  });

  return (
    <div className="Feed">
      <Topbar appContext={appContext} />
      <div className="Feed-content">
        <div className="Feed-columnLeft"></div>
        <div className="Feed-columnCenter">
          <div className="Feed-posts">
            {filteredPosts.map((post, idx) => (
              <Post key={idx} post={post} />
            ))}
          </div>
        </div>
        <div className="Feed-columnRight"></div>
      </div>
      <Footer />
    </div>
  );
}
