import Footer from 'client/components/Footer';
import Link from 'client/components/Link';
import Spinner from 'client/components/Spinner';
import Topbar from 'client/components/Topbar';
import css from 'client/css/Directory.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useState } from 'react';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
};

export default function Directory({ appContext }: Props): JSX.Element {
  const [domains, setDomains] = useState([]);
  useStyles(css);

  // Deprecated endpoint
  useEffect(() => {
    fetch('/api/getDomains', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => result.json())
      .then((result) => {
        setDomains(result.domains);
      });
  }, []);

  return (
    <div className="Directory">
      <Topbar appContext={appContext} />
      <div className="Directory-content">
        <div className="Directory-listing">
          {domains.length === 0 && <Spinner isCenter={true} />}
          {domains.map((domain: { domainName: string }, i) => (
            <p key={i}>
              <Link to={`https://${domain.domainName}.eth.id`} external>
                {domain.domainName}
              </Link>
            </p>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
