import { useDynamicContext } from '@dynamic-labs/sdk-react';
import { formatEther } from '@ethersproject/units';
import { useEtherBalance } from '@usedapp/core';
import SampleProfile from 'assets/images/j.png';
import ImageLogo from 'assets/images/topbar-logo.png';
import classNames from 'classnames';
import Button from 'client/components/Button';
import DomainExportModal from 'client/components/DomainExportModal';
import Link from 'client/components/Link';
import ReverseResolutionModal from 'client/components/ReverseResolutionModal';
import SignupModal from 'client/components/SignupModal';
import css from 'client/css/Topbar.scss';
import { getAuthCookie } from 'client/helpers/getAuthCookie';
import { getAuthUser } from 'client/helpers/getAuthUser';
import { truncateAddress } from 'client/helpers/truncateAddress';
import useStyles from 'isomorphic-style-loader/useStyles';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { AppContext } from 'shared/AppContext';

type Props = {
  appContext: AppContext;
  isLanding?: boolean;
  subDomainName?: string | null;
};

const LoginButton = lazy(() => import('client/components/LoginButton'));

export default function Topbar({
  appContext,
  isLanding,
  subDomainName,
}: Props): JSX.Element {
  useStyles(css);
  const { handleLogOut: dynamicLogout, primaryWallet } = useDynamicContext();

  const {
    walletAddress: appContextWalletAddress,
    user: appContextUser,
    entity: appContextEntity,
    fullDomainName,
  } = appContext;
  const [user, setUser] = useState<any | undefined>(appContextUser);
  const [entity, setEntity] = useState<any | undefined>(appContextEntity);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isReverseResolutionModalVisible, setReverseResolutionModalVisible] =
    useState(false);
  const [isDomainExportModalVisible, setDomainExportModalVisible] =
    useState(false);
  const displayFullDomainName =
    entity?.domain[0]?.fullDomainName ||
    user?.entity[0]?.domain[0]?.fullDomainName ||
    fullDomainName;

  const walletAddress =
    primaryWallet?.address || appContextWalletAddress || user?.walletAddress;
  const ethBalance = useEtherBalance(walletAddress);

  const [isProfileOpen, setProfileOpen] = useState(false);
  const waitingInterval = useRef<any>(null);

  useEffect(() => {
    if (primaryWallet?.address && !waitingInterval.current) {
      waitingInterval.current = setInterval(() => {
        const { walletAddress: authCookieWalletAddress } = getAuthCookie();

        if (
          authCookieWalletAddress &&
          authCookieWalletAddress.toLowerCase() ===
            primaryWallet.address.toLowerCase()
        ) {
          clearInterval(waitingInterval.current);
          getAuthUser(setUser, setEntity);
          waitingInterval.current = null;
        }
      }, 100);
    }
  }, [primaryWallet?.address]);

  const { profileImageUrl } = entity || user?.entity[0] || {};
  const handleLogout = () => {
    dynamicLogout();
    fetch('/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      window.location.reload();
    });
  };

  const handleReserve = () => {
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleLogin = () => {
    //window.location.reload();
  };

  const handleSetAuthEntity = (entity: any) => {
    const { id, domain } = entity;
    if (id) {
      fetch('/api/setAuthEntity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entityId: id,
        }),
      }).then(() => {
        if (domain[0]) {
          window.location.assign(`https://${domain[0].fullDomainName}.eth.id`);
        }
      });
    }
  };

  const loginButtonContents = (
    <Button onClick={() => {}} size="medium" className="Topbar-loginButton">
      Login
    </Button>
  );

  const reserveButtonContents = (
    <Button onClick={() => {}} size="medium" className="Topbar-loginButton">
      Reserve name
    </Button>
  );

  const handleDomainExportModalClose = () => {
    setDomainExportModalVisible(false);
  };

  const handleReverseResolutionModalClose = () => {
    setReverseResolutionModalVisible(false);
  };

  return (
    <div className="Topbar">
      <div className="Topbar-content">
        <div className="Topbar-leftContent">
          <Link to="https://eth.id" external>
            <img className="Topbar-logo" src={ImageLogo} />
          </Link>
          <div className="Topbar-logoCaption">
            <Link to="https://eth.id" external>
              Eth.id
            </Link>
          </div>
          <div className="Topbar-nav">
            {user && (
              <>
                <div className="Topbar-navItem">
                  <Link to={`https://eth.id/feed`} external>
                    Feed
                  </Link>
                </div>
                <div className="Topbar-navItem">
                  <Link to={`https://${displayFullDomainName}.eth.id`} external>
                    My Profile
                  </Link>
                </div>
                {/*<div className="Topbar-navItem">
                  <Link to={`https://atelier.eth.id`} external>
                    Create NFT
                  </Link>
            </div>*/}
              </>
            )}
            {!isLanding && (
              <div className="Topbar-navItem Topbar-reserveButton">
                <Suspense fallback={reserveButtonContents}>
                  <LoginButton onLogin={handleReserve}>
                    {reserveButtonContents}
                  </LoginButton>
                </Suspense>
              </div>
            )}
          </div>
        </div>
        <div className="Topbar-rightContent">
          <div className="Topbar-navItem">
            <Link to={`https://t.me/ethdotid`} external newTab>
              Telegram
            </Link>
          </div>
          <div className="Topbar-navItem">
            <Link to={`https://discord.gg/pDc6Q5Jwjx`} external newTab>
              Discord
            </Link>
          </div>
          {primaryWallet || user ? (
            <div className="Topbar-profile">
              <div
                className="Topbar-profileBadge"
                onClick={() => setProfileOpen(!isProfileOpen)}
              >
                <img
                  className="Topbar-profileImage"
                  src={profileImageUrl || SampleProfile}
                />
                <div className="Topbar-profileName">
                  {user
                    ? displayFullDomainName
                    : truncateAddress(primaryWallet?.address)}
                  <div
                    className={classNames('Topbar-profileArrow', {
                      'Topbar-profileArrowActivate': isProfileOpen,
                    })}
                  >
                    ▾
                  </div>
                </div>
              </div>
              <div
                className={classNames('Topbar-profileMenu', {
                  'Topbar-profileMenu--open': isProfileOpen,
                })}
              >
                <div className="Topbar-profileMenuAddress">
                  <div className="Topbar-profileMenuAddressCaption">
                    My wallet address
                  </div>
                  <input
                    className="Topbar-profileMenuAddressInput"
                    readOnly
                    value={walletAddress}
                  />
                  {ethBalance && (
                    <div className="Topbar-profileMenuAddressBalance">
                      <div>Balance: </div>
                      {`${parseFloat(formatEther(ethBalance)).toPrecision(
                        7
                      )} ETH`}
                    </div>
                  )}
                </div>
                <Link to={`https://${displayFullDomainName}.eth.id`} external>
                  <div className="Topbar-profileMenuItem">My Profile</div>
                </Link>
                <div className="Topbar-profileMenuDivider" />
                <Suspense
                  fallback={
                    <div className="Topbar-profileMenuItem">
                      Update Web 3 Username
                    </div>
                  }
                >
                  <LoginButton
                    onLogin={() => setReverseResolutionModalVisible(true)}
                    onError={() => {
                      console.log('error');
                    }}
                  >
                    <div className="Topbar-profileMenuItem">
                      Update Web 3 Username
                    </div>
                  </LoginButton>
                </Suspense>
                <div className="Topbar-profileMenuDivider" />
                <Suspense
                  fallback={
                    <div className="Topbar-profileMenuItem">
                      Export Domain NFT
                    </div>
                  }
                >
                  <LoginButton
                    onLogin={() => setDomainExportModalVisible(true)}
                    onError={() => {
                      console.log('error');
                    }}
                  >
                    <div className="Topbar-profileMenuItem">
                      Export Domain NFT
                    </div>
                  </LoginButton>
                </Suspense>
                <div className="Topbar-profileMenuDivider" />
                <div className="Topbar-profileMenuItem" onClick={handleLogout}>
                  Logout
                </div>
                {user?.entity && user?.entity.length > 1 && (
                  <>
                    <div className="Topbar-profileMenuSection">
                      My other accounts
                    </div>
                    <div className="Topbar-profileMenuDivider" />
                    {user.entity.map((entity: any, idx: any) => (
                      <div key={idx}>
                        <div
                          className="Topbar-profileMenuItem"
                          onClick={() => handleSetAuthEntity(entity)}
                        >
                          {entity.domain[0]?.fullDomainName}
                        </div>
                        <div className="Topbar-profileMenuDivider" />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          ) : (
            <Suspense fallback={loginButtonContents}>
              <LoginButton onLogin={handleLogin}>
                {loginButtonContents}
              </LoginButton>
            </Suspense>
          )}
        </div>
      </div>
      {isModalVisible && (
        <SignupModal
          onClose={handleClose}
          subDomainName={subDomainName || 'me'}
        />
      )}
      {isReverseResolutionModalVisible && walletAddress && (
        <ReverseResolutionModal
          walletAddress={walletAddress}
          onClose={handleReverseResolutionModalClose}
          onComplete={handleReverseResolutionModalClose}
        />
      )}
      {isDomainExportModalVisible && walletAddress && (
        <DomainExportModal
          walletAddress={walletAddress}
          onClose={handleDomainExportModalClose}
          onComplete={handleDomainExportModalClose}
        />
      )}
    </div>
  );
}
