import { Module } from '@prisma/client';
import LoginButton from 'client/components/LoginButton';
import ModuleCard from 'client/components/ModuleCard';
import PayMeModal from 'client/components/PayMeModal';
import css from 'client/css/PayMeCard.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useState } from 'react';

type Props = {
  entity: any;
  module: Partial<Module>;
  isOwner: boolean;
  dragHandleProps: any;
  onModuleUpdate: (moduleId: number, data: Partial<Module>) => void;
  onModuleDelete: (moduleId: number) => void;
};

export default function PayMeCard({
  entity,
  module,
  isOwner,
  dragHandleProps,
  onModuleUpdate,
  onModuleDelete,
}: Props): JSX.Element {
  useStyles(css);

  const [isEdit, setEdit] = useState(false);
  const [accounts, setAccounts] = useState(JSON.parse(module.a || '{}'));
  const [amount, setAmount] = useState(5);
  const fullDomainName = entity.domain[0]?.fullDomainName;
  const [isModalVisible, setModalVisible] = useState(false);

  const onChangeAccount = (account: string, value: string) => {
    setAccounts({
      ...accounts,
      [account]: value,
    });
  };

  const onSubmit = () => {
    setEdit(false);
    if (module.id) {
      onModuleUpdate(module.id, {
        a: JSON.stringify(accounts),
      });
    }
  };

  // todo: add submission spinner
  return (
    <ModuleCard
      title={`Pay ${fullDomainName}`}
      moduleId={module.id}
      isOwner={isOwner}
      dragHandleProps={dragHandleProps}
      onModuleDelete={onModuleDelete}
      isEdit={isEdit}
      onEdit={() => {
        setEdit(true);
      }}
      onCancel={() => {
        setEdit(false);
      }}
    >
      <div className="PayMeCard">
        {!isEdit && (
          <div className="PayMeCard-content">
            <div className="PayMeCard-inputWrapper">
              <div className="PayMeCard-unit">$</div>
              <input
                className="PayMeCard-input"
                type="number"
                onChange={(event) =>
                  setAmount(Number.parseFloat(event.target.value))
                }
                value={amount}
              />
            </div>
            <LoginButton
              onLogin={() => {
                setModalVisible(true);
              }}
              onError={() => {}}
            >
              <div className="PayMeCard-button PayMeCard-button--crypto">
                Pay with Crypto
              </div>
            </LoginButton>
            {accounts.venmo && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://venmo.com/${accounts.venmo}?txn=pay&note=Payment%20via%20${fullDomainName}.eth.id&amount=${amount}`}
              >
                <div className="PayMeCard-button PayMeCard-button--venmo">
                  Pay with Venmo
                </div>
              </a>
            )}
            {accounts.cash && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://cash.app/$${accounts.cash}/${amount}`}
              >
                <div className="PayMeCard-button PayMeCard-button--cash">
                  Pay with Cash App
                </div>
              </a>
            )}
            {/*<a target="_blank" rel="noreferrer" href={`https://paypal.com/webscr?cmd=_xclick&item_name=Payment%20via%20${fullDomainName}.eth.id&amount=${amount}&currency_code=USD&business=${accounts.paypal}`}>Pay with Paypal</a><br />*/}
            {accounts.paypal && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://paypal.me/${accounts.paypal}/${amount}`}
              >
                <div className="PayMeCard-button PayMeCard-button--paypal">
                  Pay with PayPal
                </div>
              </a>
            )}
          </div>
        )}

        {isEdit && (
          <div className="ModuleCard-rows">
            <div className="ModuleCard-row">
              {'Your Venmo username: '}
              <input
                className="ModuleCard-input"
                value={accounts.venmo}
                placeholder="Just username (ex: jethid)"
                onChange={(event) =>
                  onChangeAccount('venmo', event.target.value)
                }
              ></input>
            </div>
            <div className="ModuleCard-row">
              {'Your Cash App username: '}
              <input
                className="ModuleCard-input"
                value={accounts.cash}
                placeholder="Without $ sign (ex: jethid)"
                onChange={(event) =>
                  onChangeAccount('cash', event.target.value)
                }
              ></input>
            </div>
            <div className="ModuleCard-row">
              {'Your Paypal.me username: '}
              <input
                className="ModuleCard-input"
                value={accounts.paypal}
                placeholder="NOT email (ex: jethid)"
                onChange={(event) =>
                  onChangeAccount('paypal', event.target.value)
                }
              ></input>
            </div>
          </div>
        )}

        {isEdit && (
          <div className="Card-footer">
            <div className="Card-footerButton" onClick={onSubmit}>
              Submit
            </div>
          </div>
        )}
      </div>
      {isModalVisible && (
        <PayMeModal
          initialAmount={amount}
          fullDomainName={fullDomainName}
          walletAddress={entity.user.walletAddress}
          onClose={() => {
            setModalVisible(false);
          }}
          onComplete={() => {
            setModalVisible(false);
          }}
        />
      )}
    </ModuleCard>
  );
}
