import { getNftBackground } from 'server/helpers/getNftBackground';

// Duplicate of getNftImageData without font to reduce size
const CITIES = ['la', 'nyc', 'sf', 'paris'];
const UNIVERSITIES = ['mit'];

const multiplier = 1.5;
const FONT_SIZE_MAPPING: { [key: number]: number } = {
  1: 120,
  2: 97 * 1.1,
  3: 65 * 1.2,
  4: 49 * 1.3,
  5: 39 * 1.4,
  6: 32 * multiplier,
  7: 28 * multiplier,
  8: 24.5 * multiplier,
  9: 21.5 * multiplier,
  10: 19.5 * multiplier,
  11: 18 * multiplier,
  12: 16 * multiplier,
  13: 15.2 * multiplier,
  14: 14 * multiplier,
  15: 13 * multiplier,
  16: 12.2 * multiplier,
};

export function getNftPreviewData(
  domainName: string,
  subDomainName: string | null
) {
  const length = domainName.length;
  const fontSize = FONT_SIZE_MAPPING[length] || 10 * multiplier;
  const isCity = CITIES.indexOf(subDomainName || '') > -1;
  const isUniversity = UNIVERSITIES.indexOf(subDomainName || '') > -1;
  const series = !subDomainName
    ? 'Legendary'
    : subDomainName === 'x'
    ? 'Luxury'
    : isCity
    ? 'Cities'
    : isUniversity
    ? 'University'
    : 'Classic';

  const bottomRightColor =
    subDomainName === 'fren'
      ? '#00c834'
      : subDomainName === 'me'
      ? '#0084ff'
      : subDomainName === 'w'
      ? '#4d5caa'
      : subDomainName === 'x'
      ? '#222222'
      : !subDomainName
      ? '#ffde00'
      : '#2E599D';
  const topLeftColor =
    subDomainName === 'fren'
      ? '#054300'
      : subDomainName === 'me'
      ? '#171D3F'
      : subDomainName === 'w'
      ? '#151930'
      : subDomainName === 'x'
      ? '#000000'
      : !subDomainName
      ? '#957105'
      : '#171D3F';

  const backgroundImage =
    (isCity || isUniversity) && getNftBackground(subDomainName);
  return `<svg xmlns="http://www.w3.org/2000/svg" width="270" height="270" viewBox="0 0 270 270" fill="none">
      ${
        backgroundImage &&
        `
        <pattern xmlns="http://www.w3.org/2000/svg" id="backImg_${subDomainName}" patternUnits="userSpaceOnUse" x="0" y="0" width="270" height="270">
          <image href="${backgroundImage}" width="270" height="270"/>
        </pattern>
        <rect width="270" height="270" fill="url(#backImg_${subDomainName})"/>
      `
      }
  <rect width="270" height="270" fill="url(#paint0_linear_${subDomainName})"/>
  <defs>
    <filter id="dropShadow" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse" height="300" width="300">
      <feDropShadow dx="0" dy="1" stdDeviation="2" flood-opacity="0.225" width="200%" height="200%"/>
    </filter>
  </defs>
  <g filter="url(#dropShadow)">
    <g transform="translate(32,76.6) scale(0.0022,-0.0022)"  stroke="none" >
      <path d="M6674 18619 c-770 -49 -1506 -344 -2111 -843 -242 -201 -510 -499
      -688 -766 -105 -158 -3404 -5877 -3496 -6060 -197 -393 -317 -807 -364 -1254
      -20 -188 -20 -575 0 -761 51 -483 204 -980 422 -1370 266 -477 3367 -5842
      3439 -5950 51 -77 132 -189 180 -250 108 -136 415 -444 547 -549 601 -478
      1284 -748 2037 -806 214 -16 7082 -9 7200 8 500 71 861 180 1252 379 560 284
      1044 713 1383 1227 84 128 3389 5846 3474 6011 193 374 316 773 376 1225 22
      159 30 561 16 735 -41 496 -168 952 -381 1370 -110 216 -3383 5883 -3495 6050
      -126 189 -242 332 -410 504 -300 308 -608 532 -995 725 -400 198 -766 305
      -1260 368 -110 13 -6917 21 -7126 7z m227 -4345 c274 -47 517 -191 708 -420
      194 -232 268 -453 258 -764 -8 -242 -61 -408 -194 -609 -66 -99 -218 -256
      -314 -324 -340 -240 -805 -275 -1177 -88 -277 139 -531 447 -611 741 -51 190
      -54 433 -7 613 109 422 492 773 928 852 103 19 296 18 409 -1z m4749 -222 c91
      -12 219 -31 285 -42 1814 -317 3302 -1638 3835 -3405 205 -680 257 -1429 149
      -2155 -146 -981 -613 -1922 -1314 -2649 -727 -754 -1691 -1260 -2715 -1426
      -378 -61 -317 -59 -1882 -62 l-1438 -4 0 971 0 970 1288 0 c708 0 1346 5 1417
      10 197 14 343 38 530 85 397 100 764 280 1095 535 123 95 395 366 498 497 204
      258 370 562 479 878 42 121 101 367 125 516 19 122 22 186 22 419 1 175 -4
      308 -12 365 -67 464 -223 888 -458 1246 -160 243 -405 514 -624 689 -363 290
      -813 501 -1255 589 -284 57 -217 55 -1717 58 l-1388 4 0 970 0 970 1458 -4
      c1419 -3 1461 -4 1622 -25z m-3980 -6282 l0 -3460 -965 0 -965 0 0 3460 0
      3460 965 0 965 0 0 -3460z"  fill="#ffffff"/>
    </g>
  </g>
  <text x="51" y="21" font-size="9px" fill="white" letter-spacing="4" opacity="0.6">COLLECTOR'S EDITION</text>
  <text x="85" y="63" font-size="18px" fill="white" filter="url(#dropShadow)">${series}</text>
  <text x="32" y="231" font-size="${fontSize}px" fill="white" filter="url(#dropShadow)">${domainName}</text>
  <defs>
    <style>
      text {
        font-family: "Poppins", DejaVu Sans, Noto Color Emoji, Apple Color Emoji, sans-serif;
        font-style: normal;
        font-variant-numeric: tabular-nums;
        font-weight: 600;
        font-variant-ligatures: none;
        font-family: 'Poppins', sans-serif;
        font-feature-settings: "ss01" on, "ss03" on;
        -moz-font-feature-settings: "ss01" on, "ss03" on;
        line-height: 34px;
      }
    </style>
    ${
      isCity || isUniversity
        ? `
    <linearGradient id="paint0_linear_${subDomainName}" gradientTransform="rotate(80)" >
      <stop offset="0%" stop-color="#06101aee" />
      <stop offset="35%" stop-color="#06101a55" />
      <stop offset="50%" stop-color="#06101a00" />
      <stop offset="65%" stop-color="#06101a55" />
      <stop offset="100%" stop-color="#06101aee" />
    </linearGradient>
    `
        : `
    <linearGradient id="paint0_linear_${subDomainName}" x1="190.5" y1="302" x2="-64" y2="-172.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="${bottomRightColor}"/>
        <stop offset="1" stop-color="${topLeftColor}"/>
    </linearGradient>
    `
    }
    <linearGradient id="paint1_linear" x1="0" y1="0" x2="269.553" y2="285.527" gradientUnits="userSpaceOnUse">
      <stop stop-color="#EB9E9E"/>
      <stop offset="1" stop-color="#992222"/>
    </linearGradient>
  </defs>
</svg>`;
}
