import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import Button from 'client/components/Button';
import Spinner from 'client/components/Spinner';
import { useState } from 'react';
import { checkValidExternalEmail } from 'shared/checkValidEmail';

type Props = {
  paymentIntentClientSecret: string | undefined;
  onBack: () => void;
  onSuccess: (paymentIntentId: string, email: string) => void;
};

export default function AtelierNftUsdFlow({ onSuccess }: Props): JSX.Element {
  const [isWaiting, setWaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const elements = useElements();
  const stripe = useStripe();
  const [email, setEmail] = useState('');
  const [isAgreeTerms, setAgreeTerms] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setWaiting(true);

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://eth.id/order/123/complete',
      },
      redirect: 'if_required',
    });

    console.log(result);

    setWaiting(false);

    if (result.error?.message) {
      setErrorMessage(result.error.message);
    }

    if (result.paymentIntent?.status === 'succeeded') {
      onSuccess(result.paymentIntent.id, email);
    }
  };

  return (
    <>
      {isWaiting && <Spinner />}

      <div className="AtelierNft-email">
        <div className="AtelierNft-emailCaption">
          Email (Required for NFT delivery)
        </div>
        <input
          type="email"
          className={classNames('AtelierNft-emailInput', {
            'AtelierNft-emailInput--error': !checkValidExternalEmail(email),
          })}
          placeholder="Enter email for NFT delivery"
          onChange={(event) => setEmail(event.target.value)}
          value={email}
        ></input>
      </div>
      <div className="AtelierNft-error">{errorMessage}</div>
      <PaymentElement />
      <div className="AtelierNft-terms">
        <input
          type="checkbox"
          onClick={() => setAgreeTerms(!isAgreeTerms)}
          checked={isAgreeTerms}
          readOnly
        ></input>
        <div
          className="AtelierNft-termsCaption"
          onClick={() => setAgreeTerms(!isAgreeTerms)}
        >
          I have read and agree to the{' '}
          <a
            href="/terms"
            target="_blank"
            onClick={(event) => event.stopPropagation()}
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="/privacy"
            target="_blank"
            onClick={(event) => event.stopPropagation()}
          >
            Privacy Policy
          </a>
          .
        </div>
      </div>
      {!isWaiting && (
        <Button
          onClick={handleSubmit}
          className="AtelierNft-submitButton"
          disabled={!isAgreeTerms || !checkValidExternalEmail(email)}
        >
          Submit
        </Button>
      )}
    </>
  );
}
